import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      // zIndex:"-1",
      background: theme.palette.background.offwhite,
      color: theme.palette.secondary.defaultText,
      borderTop: "1px solid #eee",
      "& a": {
        cursor: "pointer",
      },
      "& .logoWhite": {
        width: 170,
        height: 40,
        marginBottom: 20,
      },
      "& a": {
        color: theme.palette.primary.gainsboro,
        fontWeight: 300,
        padding: "5px 0px",
        textTransform: "none",
        fontSize: 14,
      },
      "& ul": {
        listStyle: "none",
        display: "grid",
        paddingRight: 20,
      },
      "& .copyright-grid": {
        // alignSelf: "end",
        // padding: "50px 0 0 0",
        "& img": {
          width: 700,
          height: 40,
        },
      },
      "& .copyright-text": {
        fontSize: 9,
        marginTop: 30,
        padding: 5,
        backgroundColor: "#ef6614",
        borderRadius: 5,
        "& img": {
          height: 10,
          margin: "0 0 0 5px",
        },
      },
    },
    headText: {
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: 500,
      margin: "2rem 0 1.3rem 0",
    },
    effects: {
      width: "fit-content",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      "&:after": {
        content: '""',
        position: "absolute",
        left: 0,
        display: "inline-block",
        height: "1em",
        width: "100%",
        borderBottom: "1px solid",
        marginTop: 10,
        opacity: 0,
        transition: "opacity 0.35s, transform 0.35s",
        transform: "scale(0, 1)",
      },
      "&:hover:after": {
        opacity: 1,
        transform: "scale(1)",
      },
    },
    socialMedia: {
      display: "flex",
    },
    socialMediaIcon: {
      height: 28,
      marginRight: 10,
    },
    contact: {
      fontSize: 16,
      fontWeight: 500,
    },
    paymentImg: {
      height: 36,
      marginRight: 20,
    },
    storeImg: {
      height: 45,
      marginRight: 10,
    },
    headTextCenter: {
      fontWeight: 500,
      margin: "2rem 0 1.3rem 0",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        textAlign: "start",
      },
    },
    memberImg: {
      height: 50,
      marginRight: 10,
    },
  };
});

export default useStyles;

import React, { useEffect, useState } from "react";
import useStyles from "components/Navbars/PostLoginNavBar.DoneTrip/NavbarStyles";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { MenuOutlined } from "@material-ui/icons";
import { Link, NavLink, withRouter } from "react-router-dom";
import LOGO_IMG from "assets/doneTrip/images/logo-white.png";
import { localforageGetItem } from "oautils/oaForageUtils";
import { localforageClear } from "oautils/oaForageUtils";
import WebApi from "api/ApiConstants";
import { localforageSetItem } from "oautils/oaForageUtils";
import { AppStateContext } from "layouts/AppStateProvider";
import OaTimer from "oahoc/OaTimer";

const PostLoginNavBar = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElCurrency, setAnchorElCurrency] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [corpName, setCorpName] = useState("");
  const [showServices, setShowServices] = useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corpIdentity, setCorpIdentity] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const classes = useStyles();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenCurrencyMenu = (event) => {
    setAnchorElCurrency(event.currentTarget);
  };
  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseCurrencyMenu = () => {
    setAnchorElCurrency(null);
  };
  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  const handlePopMenu = (page) => {
    if(page === "Hotels"){
      props.history.push("/hotels");
    }
    if(page === "Flights"){
      props.history.push("/flights");
    }
    if(page === "Get E-Ticket"){
 props.history.push("/b2c/printeticket");
    }
    if(page === "Sign-in"){
      props.history.push("/b2c/signin");
    }
    if(page === "Register"){
      props.history.push("/b2c/register");
    }
    if(page === "Sign-out"){
      logOutB2C()
    }
    if(page === "DashBoard"){
            props.history.push("/b2c/mybookings");
    }

    setAnchorElNav(null);
  };

  const pages = [
    "Flights",
    "Hotels",
    "Get E-Ticket",
    "Sign-in",
    "Register",
  ];

  const loggedInPages = [
    "Flights",
    "Hotels",
    "Get E-Ticket",
    "DashBoard",
    "Sign-out",
  ]

  const currency = ["INR", "USD", "EUR"];
  const languages = ["English", "Russian", "French"];

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function(err, value) {
      if (value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );
        setCorpName(value.corpname);
        setCorpIdentity(value.userIdentity);
        // setAccountBalance(value.cashBalance);
        setServiceOptions(value.serviceOption);

        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        // setCorporateType(value.corporateType);
      }
    });
  };

  const logOutB2C = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                props.history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);

  return (
    <>
      <AppBar className={classes.root}>
        <Container maxWidth="lg" className={classes.navBarContainer}>
          <Toolbar disableGutters>
            <Link to="/">
              {!props.hidelogo && (
                <img
                  src={LOGO_IMG}
                  alt=""
                  style={{ height: 65, marginTop: 2 }}
                />
              )}
            </Link>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: { xs: "flex-end", md: "none" },
              }}
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuOutlined />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {!isLoggedIn &&  pages.map((page) => (
                  <MenuItem key={page} onClick={() => handlePopMenu(page)}>
                    <Typography style={{ textAlign: "center" }}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
                {isLoggedIn &&  loggedInPages.map((page) => (
                  <MenuItem key={page} onClick={() => handlePopMenu(page)}>
                    <Typography style={{ textAlign: "center" }}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <ul className={classes.navItem}>
                <li>
                  <NavLink to="/flights" className={classes.navEffect}>
                    Flights
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/hotels" className={classes.navEffect}>
                    Hotels
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/buses" className={classes.navEffect}>
                    Bus
                  </NavLink>
                </li> */}
              </ul>
              {isLoggedIn && userType === "b2c_user" ? (
                <>
                  <ul>
                    <li>
                      <NavLink
                        to="/b2c/mybookings"
                        className={classes.navEffect}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>{corpName}</li>
                    <li>
                      <Button
                        onClick={(event) => logOutB2C(event)}
                        className={classes.logoutBtn}
                      >
                        Logout
                      </Button>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <ul>
                    <li>
                      <NavLink
                        to="/b2c/printeticket"
                        className={classes.navEffect}
                      >
                        Get E-ticket
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/b2c/signin" className={classes.navEffect}>
                        Sign-in
                      </NavLink>
                    </li>
                    <div style={{ alignSelf: "center" }}>/</div>
                    <li>
                      <NavLink to="/b2c/register" className={classes.navEffect}>
                        Register
                      </NavLink>
                    </li>
                  </ul>
                </>
              )}
            </Box>
          </Toolbar>
          <OaTimer isTimerShow={props.isTimerShow} />
        </Container>
      </AppBar>
    </>
  );
};

export default withRouter(PostLoginNavBar);

const getServicePath = (serviceName) => {
  let servicePath = "";
  switch (serviceName) {
    case "flights": {
      servicePath = "flights";
      break;
    }

    // case "buses": {
    //   servicePath = "buses";
    //   break;
    // }

    case "hotels": {
      servicePath = "hotels";
      break;
    }

    case "recharge": {
      servicePath = "recharges";
      break;
    }

    case "insurance": {
      servicePath = "insurances";
      break;
    }

    case "moneytransfer": {
      servicePath = "moneytransfer";
      break;
    }

    case "pan": {
      servicePath = "pancard";
      break;
    }

    default: {
      servicePath = "flights";
      break;
    }
  }
  return servicePath;
};

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ArrowUpward } from "@material-ui/icons";
import { Button } from "react-bootstrap";

const ScrollToTopComp = (props) => {
    const isMounted = React.useRef(false);

    const scrollToTopView = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        // console.log("scrolly", window.scrollY);
    }
    
    const classes = useStyles();
    const [showSTTB, setShowSTTB] = React.useState(false);

    React.useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    React.useEffect(() => {
        if(isMounted.current) {
            document.body.onscroll = () => {
                if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                    setShowSTTB(true);
                } else {
                    setShowSTTB(false);
                }
            }
        }
    }, [isMounted.current]);
    
    return (
        <div className={classes.scrollTopRoot}>
            <Button className="scroll-to-top-button" 
                style={{display: showSTTB == true ? "block" : "none"}}
                onClick={() => scrollToTopView()}
            >
                <ArrowUpward />
            </Button>
        </div>
    )
}

export default ScrollToTopComp;

const useStyles = makeStyles(theme => ({
    scrollTopRoot: {
        "& .scroll-to-top-button": {
            padding: 10,
            // background: COLORS.BTN_BG_OCT,
            background: `linear-gradient(to bottom, #f9f0e3 0%,#f99e27 50%,#f9930e 51%,#f9b26b 100%)`,
            cursor: "pointer",
            position: "fixed",
            right: 10,
            bottom: 10,
            boxShadow: "0px 0px 56px -4px rgba(226,190,105,1)",
            border: "none",
            borderRadius: 50,
            width: 50,
            height: 50,
            "& .MuiSvgIcon-root": {
                color: theme.palette.primary.contrastText,
            },
            "&:hover": {
                background: `linear-gradient(to bottom, #f9f0e3 0%,#d08606 50%,#ce8b04 51%,#f9b26b 100%)`,
            },
        }
    }
}));

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.DoneTrip";
import PageFooter from "components/Footer/PageFooter.DoneTrip";
import { Route, Switch } from "react-router-dom";
import TermsAndConditions from "components/LandingPage/DoneTrip/siteinfo/TermsAndConditions";
import CancellationAndRefund from "components/LandingPage/DoneTrip/siteinfo/CancellationAndRefund";
import PrivacyPolicy from "components/LandingPage/DoneTrip/siteinfo/PrivacyPolicy";
import PricingPolicy from "components/LandingPage/DoneTrip/siteinfo/PricingPolicy";
import LandingPageBase from "components/LandingPage/DoneTrip/LandingPageBase";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <PostLoginNavBar {...props} isSearchPage={false} showServices={true} />
      <Switch>
        <Route path="/terms_and_conditions" component={TermsAndConditions} />
        <Route
          path="/cancellation_and_refund"
          component={CancellationAndRefund}
        />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/pricing_policy" component={PricingPolicy} />
        <Route component={LandingPageBase} />
      </Switch>
      <PageFooter />
    </div>
  );
}

// style={{minHeight: "67vh"}}

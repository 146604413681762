import React, { useEffect } from "react";
import AirlineSeatReclineExtraRoundedIcon from "@material-ui/icons/AirlineSeatReclineExtraRounded";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import RotateLeftRoundedIcon from "@material-ui/icons/RotateLeftRounded";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import clsx from "clsx";
import { seatTypes } from "data/seatTypes.js"
import LazyLoad from "react-lazyload";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
  Tooltip,
  Modal,
  IconButton,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";
import {
  AirlineSeatReclineNormalRounded,
  UnsubscribeTwoTone,
  CloseOutlined,
} from "@material-ui/icons";
import { useHistory, withRouter } from "react-router-dom";
import { FlightShareDetailView } from "./view/FlightShareDetailsView";
import { FlighCardMoreDetailsVew } from "./view/FlighCardMoreDetailsVew";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import { AppConstant } from "appConstant";
import OaNetFare from "pages/components/OaNetFare";
import OaAlert from "oahoc/OaAlert";
import StrikeThroughLocalMall from "oahoc/StrikeThroughLocalMall";

// import demoResponse from "pages/module/flight/JSON_SAMPLE_RESPONSES/domestic/dom_oneway_repricing_with_additional.json";

function NonMUIFlightCard(props) {
  const [moreDetailsOpen, setMoreDetailsOpen] = React.useState(false);
  const [showMoreFares, setShowMoreFares] = React.useState(false);
  const [selectedFareId, setSelectedFareId] = React.useState(null);
  const [selectedFareDetail, setSelectedFareDetail] = React.useState(
    props.flightData.fares[0]
  );
  const [fareRules, setFareRules] = React.useState(
    props.flightData.fares[0].rule
  );

  // const boxRef = useRef(null);




  const nmfcRoot = React.useRef(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  const useStyles = getStyles(showMoreFares, props.showNetFare);

  const classes = useStyles();
  const theme = useTheme();
  const isMdSmDevice = useMediaQuery(theme.breakpoints.down(960));
  const history = useHistory();

  const moreActionDiv = React.useRef();
  const moreActionDivContainer = React.useRef();
  
  const carrierDetailsDiv = React.useRef();

  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  const sessionId = props?.sessionid;

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [flightData, setFlightData] = React.useState({
    departDay: "",
    departDate: "",
    departTime: "",
    departAirport: "",

    arrivalDay: "",
    arrivalDate: "",
    arrivalTime: "",
    arrivalAirport: "",

    stops: 0,
    stopAirports: 0,

    fares: [],

    journeyHours: 0,
    journeyMins: 0,
    segments: [],

    airlineCode: "",
    airlineName: "",
    flightCode: "",
  });

  // const handleClickOutside = (event) => {
  //   if (moreActionDivContainer.current && !moreActionDivContainer.current.contains(event.target)) {
  //     // setMoreDetailsOpen(false)
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  // if(props.modifiedFilter === true && moreDetailsOpen === true){
  //         //  setMoreDetailsOpen(false)
  // }




  const width = window.innerWidth;
  const breakpoint = 980;

  const toggleShowMoreFares = (showState) => {
    setShowMoreFares(showState);
  };

  const toggleMoreDetails = (event) => {
    // setMoreDetailsOpen(moreDetailsOpenState);
    // setSelectedFlight(event, selectedFareDetail);
    if (!moreDetailsOpen) {
      getFlightFareDetails(selectedFareDetail.fareId);
      isMdSmDevice && setMoreDetailsOpen(isMdSmDevice);
    } else {
      setMoreDetailsOpen(false);
    }
  };

  const getFlightFareDetails = (fareId) => {
    setIsLoading(true);
    props.isloading(true);
    const params = {
      onwardFareId: props.isdepart ? fareId : "",
      returnFareId: props.tripMode === "ROUND" && !props.isdepart ? fareId : "",
      sessionId: sessionId,
    };
    WebApi.getFlightFareRules(
      params,
      (response) => {
        if (response.isSuccess) {
          if (props.isdepart)
            setFareRules(response?.onwardResp[0]?.fares[0].rule);
          else setFareRules(response?.returnResp[0]?.fares[0].rule);
          !isMdSmDevice && setMoreDetailsOpen(true);
          props.isloading(false);
          setIsLoading(false);
        } else {
          props.isloading(false);
          setIsLoading(false);
        }
      },
      (error) => {
        console.log("error", error);
        props.isloading(false);
      }
    );
  };

  const setSelectedFlight = (fareDetail) => {
    const fareId = fareDetail.fareId;
    setFareRules(fareDetail.rule);
    setSelectedFareDetail(fareDetail);
    setSelectedFareId(fareId);
    props.getSelectedFlight(fareId, props.flightData);

    setInfoText("");
    setIsError(false);
  };

  const onClickFare = (event, fareDetail) => {
    if (event.target.checked) {
      setSelectedFlight(fareDetail);
      getFlightFareDetails(fareDetail.fareId);
    }
  };

  // console.log(props, "PROPS DATA")
  // console.log(flightData, "FLIGHTDATA")

  // useEffect(() => {
  //   const fareId = props.flightData.fares[0].fareId;
  //   setFareRules(props.flightData.fares[0].rule);
  //   setSelectedFareDetail(props.flightData.fares[0]);
  //   setSelectedFareId(props.flightData.fares[0].fareId);
  //   props.getSelectedFlight(fareId, props.flightData);

  //   setInfoText("");
  //   setIsError(false);
  // }, []);

  const getReqDate = (daySpan) => {
    const reqDate = new Date();
    if (daySpan > 365 * 4) {
      daySpan += Math.floor(daySpan / 365 / 4);
    } else {
      if (daySpan < 0) {
        var tempDs = daySpan * -1;
        if (tempDs > 365 * 4) {
          tempDs += Math.floor(tempDs / 365 / 4);
        }
        daySpan = tempDs * -1;
      }
    }
    reqDate.setTime(reqDate.valueOf() + daySpan * 24 * 3600 * 1000);
    return reqDate;
  };

  const bookOnwardFlight = (repricingResponse) => {
    // console.log(repricingResponse)
    const paxInfo = {
      adultPax: [],
      childPax: [],
      infantPax: [],
      country_dial_code: "+91",
      phoneno: "",
      emailUser: "",
      gstCompany: "",
      gstCompanyAddress: "",
      gstCompanyNumber: "",
      gstEmail: "",
      gstNumber: "",
      discontCoupon: "",
      discontCouponAmount: 0,
      hasGst: false,
      isDomestic: props?.request?.isDomestic,
      adultCount: props?.request?.adultPax,
      childCount: props?.request?.childPax,
      infantCount: props?.request?.infantPax,
      seatStatus: [],
      mealStatus: [],
      baggStatus: [],
      seatStatusRt: [],
      mealStatusRt: [],
      baggStatusRt: [],
      additionalFare: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
      additionalFareRt: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
    };

    repricingResponse.onwardResp[0].segment.map((val, idx) => {
      paxInfo.seatStatus.push({ seatsFull: false });
      paxInfo.mealStatus.push({ mealsFull: false });
      idx == 0 && paxInfo.baggStatus.push({ baggsFull: false });
    });

    for (var i = 0; i < props.request.adultPax; i++) {
      const additionalOne = [];
      repricingResponse.onwardResp[0].segment &&
        repricingResponse.onwardResp[0].segment.map((val, idx) => {
          additionalOne.push({
            frequentAirline: val.carrierDetails.carrierCode,
            frequentAirlineName: val.carrierDetails.carrierName,
            airlineNo: val.carrierDetails.flightNumber,
            frequentFlyerNo: "",
            meal: null,
            baggage: null,
            seat: null,
          });
        });

      paxInfo.adultPax.push({
        initial: null,
        firstname: "",
        surname: "",
        visatype: "",
        passportno: "",
        passportidate: null,
        passportexpire: null,
        passporticountry: "",
        passporticobj: { name: "India", dial_code: "+91", code: "IN" },
        // dob: dateFnsFormat(new Date(getReqDate(-(365 * 13))), "yyyy-MM-dd"),
        // dobDisplay: getReqDate(-(365 * 13)),
        dob: "",
        dobDisplay: null,
        paxSave: false,
        additionalOnward: additionalOne,
        additionalReturn: [],
      });
    }

    if (props.request.childPax > 0) {
      for (var i = 0; i < props.request.childPax; i++) {
        const additionalOne = [];
        repricingResponse.onwardResp[0].segment &&
          repricingResponse.onwardResp[0].segment.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.childPax.push({
          initial: null,
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: [],
        });
      }
    }

    if (props.request.infantPax > 0) {
      for (var i = 0; i < props.request.infantPax; i++) {
        const additionalOne = [];
        repricingResponse.onwardResp[0].segment &&
          repricingResponse.onwardResp[0].segment.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.infantPax.push({
          initial: null,
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: [],
        });
      }
    }

    history.push({
      pathname: "/flight/booking",
      state: {
        request: repricingResponse?.request,
        requestObj: props?.history?.location?.state?.requestObj,
        onwardResp: repricingResponse?.onwardResp[0],
        returnResp: null,
        oneSelectedFareId: repricingResponse?.onwardResp[0]?.fares[0]?.fareId,
        retSelectedFareId: null,
        // sessionId: repricingResponse?.sessionId,
        sessionId: sessionId,
        availSessionId: sessionId,
        isPriceChanged: false,
        paxInfo: paxInfo,
        couponObj: { voucher_code: "", voucher_value: 0, description: "" },
        totalPayable: 0,
      },
    });
  };

  const rePrice = (event) => {
    // console.log(event.currentTarget.id);
    let defaultSelectedFareId = selectedFareId
      ? selectedFareId
      : props.flightData.fares[0].fareId;
    // console.log(selectedFareId);
    // console.log(props.flightData.fares[0].fareId);
    // console.log(selectedFareIdd);
    // return;
    if (defaultSelectedFareId != null) {
      const params = {
        onwardFareId: defaultSelectedFareId,
        returnFareId: "",
        sessionId: sessionId,
      };

      props.setIsFlightBookLoading(true);
      // return bookOnwardFlight(demoResponse);
      WebApi.getFlightRepricing(
        params,
        (response) => {
          setIsLoading(false);
          // console.log(seatTypes)
          // console.log("FIRST FIRST _-----------------")
          const responseData =  JSON.parse(JSON.stringify(response))
                    /* eslint-disable no-unused-expressions */
                    responseData?.onwardResp[0]?.additionalServices?.seatMapping?.map((seatObj) => seatObj.seat.forEach(obj1 => {
                      // console.log(obj1)
                      let match = seatTypes.find(obj2 => obj2.seatTypeValue === +obj1.seatType);
                      if (match) {
                        Object.assign(obj1, match);
                      }
                    }))
          
                    // console.log("REACHED 1")
          
                    responseData?.onwardResp[0]?.additionalServices?.seatMapping?.map((seatObj) => {
                      let count = 0;
                      seatObj.seat.forEach((obj1,ind) => {
                      // console.log(obj1)
                    //  console.log(obj1.seatTypeLabel.toLowerCase().includes("aisle"))
                     if(obj1.seatTypeLabel.toLowerCase().includes("aisle")&& ind !==0){
                      count++
                      obj1.aisleCount = count;
                     } else {
                      obj1.aisleCount = 0;
                     }
                    })})
                    // console.log("REACHED 2")

                    if(props.tripMode === "ROUND"){
                      responseData?.returnResp[0]?.additionalServices?.seatMapping?.map((seatObj) => seatObj.seat.forEach(obj1 => {
                        // console.log(obj1)
                        let match = seatTypes.find(obj2 => obj2.seatTypeValue === +obj1.seatType);
                        if (match) {
                          Object.assign(obj1, match);
                        }
                      }))
                      // console.log("REACHED 3")
            
                      responseData?.returnResp[0]?.additionalServices?.seatMapping?.map((seatObj) => {
                        let count = 0;
                        seatObj.seat.forEach((obj1,ind) => {
                        // console.log(obj1)
                      //  console.log(obj1.seatTypeLabel.toLowerCase().includes("aisle"))
                       if(obj1.seatTypeLabel.toLowerCase().includes("aisle")&& ind !==0){
                        count++
                        obj1.aisleCount = count;
                       } else {
                        obj1.aisleCount = 0;
                       }
                      })})
                    }
                
    

   
          props.isloading(false);
          props.setIsFlightBookLoading(false);
          if (response.isSuccess == true) {
            if (response.error == "Fare difference") {
              window.confirm("fares have changed, do you want to continue?") &&
                bookOnwardFlight(responseData);
            } else {
              bookOnwardFlight(responseData);
            }
          } else {
            // setIsError(true);
            // setInfoText("Error on repricing! please retry booking.");
            // alert("Error on repricing! please choose another flight.");
            setIsError(true);

            setPrimaryButtonText("OK");
            setIsAlertPrompt(true);
            setAlertMsg(
              "This fare is not available. Please choose another flight!"
            );
            setAlertTitle("Fare not available");
          }
        },
        (error) => console.log("error", error)
      );
    } else {
      //setInfoText("Please select fare");
      //setIsError(true);

      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
      setAlertMsg("Please select fare!");
      setAlertTitle("Select Fare");
    }
  };

  React.useEffect(() => {
    if (props.flightData) {
      let segments = props.flightData.segment;
      let stops = segments.length - 1;

      let stopAirports = "";
      let totalJourneyTime = props.flightData.journeyTime;

      segments.map((value, index) => {
        if (index > 0) {
          let sep = segments.length - 1 === index ? "" : ", ";
          stopAirports += value.departDetails.airportCode + sep;
        }
      });

      let flightCode = segments[0].carrierDetails.carrierCode;
      let depDateRaw = new Date(segments[0].departDetails.dateTime);

      let ls = segments.length - 1;
      let arrDateRaw = new Date(segments[ls].arriveDetails.dateTime);

      setFlightData({
        departDay: days[depDateRaw.getDay()],
        departDate: dateFnsFormat(depDateRaw, "dd MMM"),
        departTime: dateFnsFormat(depDateRaw, "HH:mm"),
        departAirport: segments[0].departDetails.airportCode,

        arrivalDay: days[arrDateRaw.getDay()],
        arrivalDate: dateFnsFormat(arrDateRaw, "dd MMM"),
        arrivalTime: dateFnsFormat(arrDateRaw, "HH:mm"),
        arrivalAirport: segments[ls].arriveDetails.airportCode,

        stops: stops,
        stopAirports: stopAirports,

        fares: props.flightData.fares,

        journeyHours: Math.floor(totalJourneyTime / 60),
        journeyMins: Math.floor(totalJourneyTime % 60),
        segments: segments,

        airlineCode: flightCode + " " + segments[0].carrierDetails.flightNumber,
        airlineName: segments[0].carrierDetails.carrierName,
        flightCode: flightCode,
      });

      setSelectedFlight(props.flightData.fares[0]);
    }
  }, [props.flightData]);

  // console.log(flightData, "FLIGHTDATA")

  return (
    <div className={classes.NonMUIFlightCard} ref={moreActionDivContainer} >
      {/* <OaFormAlerts
        isLoading={isLoading}
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        style={{ width: "100%" }}
      /> */}

      {isError && (
        <div item md={12} xs={12}>
          <OaAlert
            //callback={transactionHelper}
            isPrompt={isAlertPrompt}
            //msg={isError}
            //title={infoText}
            msg={alertMsg}
            title={alertTitle}
            isError={isError}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            togglePrompt={() => {
              setIsAlertPrompt(!isAlertPrompt);
            }}
          ></OaAlert>
        </div>
      )}

      <div className={classes.cardContent}>
        {/* 1 ------------------------ */}
        <div className={classes.airlineDetails}>
          <div className="carrier-details-div" ref={carrierDetailsDiv}>
            <div className={classes.airline_image}>
              <LazyLoad throttle={200} height={40}>
                <img
                  src={
                    image_base +
                    "?fileName=" +
                    flightData.flightCode +
                    ".png&type=AIRLINE_IMAGES"
                  }
                  alt=""
                />
              </LazyLoad>
            </div>
            <div className={classes.airline_title}>
              <div className={classes.f14b}>{flightData.airlineName}</div>
              {/* <div className={classes.f12r}> */}
              <div className={classes.f12r}>
                {flightData.airlineCode} -{" "}
                <span className={classes.seatsLeft}>
                  ({flightData?.fares?.[0]?.bookingClass})
                </span>
              </div>
            </div>
          </div>
          <div className="more-action-div" ref={moreActionDiv}>
            <div
              className={classes.airline_detailButton}
              onClick={toggleMoreDetails}
            >
              <div className={classes.f8r}>
                More Details {moreDetailsOpen ? "-" : "+"}
              </div>
            </div>
            {AppConstant.name === true && (
              <div className={classes.flightDetails_Share}>
                <FlightShareDetailView
                  segment={props.flightData.segment}
                  journeyTime={props.flightData.journeyTime}
                ></FlightShareDetailView>
              </div>
            )}
          </div>
        </div>

        {/* 2 ------------------------ */}
        <div className={classes.flightDetails}>
          <div className={classes.flightDetails_Timings}>
            <div className={classes.departure}>
              <div className={clsx(classes.f24b, classes.leftText)}>
                {flightData.departTime}
              </div>
              <div
                className={clsx(classes.f12r) + " " + clsx(classes.centerText)}
              >
                {flightData.departAirport} &nbsp;&nbsp;
                {flightData.departDay}, {flightData.departDate}
              </div>
            </div>
            <div className={classes.duration}>
              <div className={clsx(classes.f12r)}>
                {flightData.journeyHours} hr {flightData.journeyMins} min
              </div>
              <div className={clsx(classes.f8r)}>
                {flightData.stops > 0
                  ? "Via " + flightData.stopAirports
                  : "Non-Stop"}
              </div>
              <div className={clsx(classes.f8r, classes.seatsLeft)}>
                {flightData?.fares?.[0]?.availableSeats} Seats Left
              </div>
              {/* {flightData.fares.map((s, i) => (
                <div className={clsx(classes.f8r)}>
                  ({s.availableSeats} Seats Left)
                </div>
              ))} */}
            </div>
            <div className={classes.arrival}>
              <div className={clsx(classes.f24b, classes.rightText)}>
                {flightData.arrivalTime}
              </div>
              <div
                className={clsx(classes.f12r) + " " + clsx(classes.centerText)}
              >
                {flightData.arrivalAirport}&nbsp;&nbsp;
                {flightData.arrivalDay}, {flightData.arrivalDate}
              </div>
            </div>
          </div>
          {props.tripMode === "ROUND" && (
            <div className={classes.flightDetails_Itinaries}>
              <div className="add-service-item">
                <AirlineSeatReclineExtraRoundedIcon />
                <span
                  className={clsx(
                    classes.FlightAmmenitiesDetailsDesc,
                    classes.f12r
                  )}
                >
                  {selectedFareDetail?.availableSeats} Seats left
                </span>
              </div>
              <div className="add-service-item">
                <RotateLeftRoundedIcon />
                <span
                  className={clsx(
                    classes.FlightAmmenitiesDetailsDesc,
                    classes.f12r
                  )}
                >
                  {selectedFareDetail?.isRefundable == "true"
                    ? "Refundable"
                    : "Non Refundable"}
                </span>
              </div>
              <div className="add-service-item">
                <LocalMallRoundedIcon />
                <span
                  className={clsx(
                    classes.FlightAmmenitiesDetailsDesc,
                    classes.f12r
                  )}
                >
                  {selectedFareDetail?.baggage}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* 3 ------------------------ */}
        <div className={classes.priceDetails}>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedFareId}
              aria-label="flight stops"
              name="flight-stop-radios"
            >
              {flightData.fares &&
                showMoreFares &&
                flightData.fares.map((s, i) => (
                  <FormControlLabel
                    className={classes.FlightPricesLabel}
                    value={s.fareId}
                    control={<FilterRadio />}
                    label={
                      <>
                        <p>
                          {"₹"} {s.baseFare + s.tax + s.tmarkup}
                          {props.tripMode !== "ROUND" && (
                            <>
                              <Tooltip
                                title={s.availableSeats + " seats available"}
                              >
                                <span className="price-seat">
                                  <AirlineSeatReclineNormalRounded />
                                </span>
                              </Tooltip>
                              {s.isRefundable == "true" ? (
                                <Tooltip title="Refundable">
                                  <span className="price-refundable">R</span>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Non-Refundable">
                                  <span
                                    className="price-refundable"
                                    style={{
                                      textDecoration:
                                        "line-through solid red 2px",
                                      marginBottom: 5,
                                    }}
                                  >
                                    R
                                  </span>
                                </Tooltip>
                              )}
                              <Tooltip title={s.baggage}>
                                <span className="price-baggage">
                                  <LocalMallRoundedIcon />{" "}
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </p>
                        <span className="fare-type">
                          {s.fareType && s.fareType != ""
                            ? s.fareType
                            : "Published Fare"}
                        </span>
                        <OaNetFare
                          fareData={s}
                          showNetFare={props.showNetFare}
                        />
                      </>
                    }
                    onClick={(event) => onClickFare(event, s)}
                    checked={selectedFareId === s.fareId}
                    key={i}
                  />
                ))}
              {flightData.fares &&
                !showMoreFares &&
                flightData.fares.slice(0, 2).map((s, i) => (
                  <FormControlLabel
                    className={classes.FlightPricesLabel}
                    value={s.fareId}
                    control={<FilterRadio />}
                    label={
                      <>
                        <p>
                          {"₹"} {s.baseFare + s.tax + s.tmarkup}
                          {props.tripMode !== "ROUND" && (
                            <>
                              <Tooltip
                                title={s.availableSeats + " seats available"}
                              >
                                <span className="price-seat">
                                  <AirlineSeatReclineNormalRounded />
                                </span>
                              </Tooltip>
                              {s.isRefundable == "true" ? (
                                <Tooltip title="Refundable">
                                  <span className="price-refundable">R</span>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Non-Refundable">
                                  <span
                                    className="price-refundable"
                                    style={{
                                      textDecoration:
                                        "line-through solid red 2px",
                                      marginBottom: 5,
                                    }}
                                  >
                                    R
                                  </span>
                                </Tooltip>
                              )}
                              <Tooltip title={s.baggage}>
                                <span className="price-baggage">
                                  {selectedFareDetail.baggage !== "" &&
                                  selectedFareDetail.baggage != null ? (
                                    <LocalMallRoundedIcon />
                                  ) : (
                                    <StrikeThroughLocalMall />
                                  )}
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </p>
                        <span className="fare-type">
                          {s.fareType && s.fareType != ""
                            ? s.fareType
                            : "Published Fare"}
                        </span>
                        <OaNetFare
                          fareData={s}
                          showNetFare={props.showNetFare}
                        />
                      </>
                    }
                    onClick={(event) => onClickFare(event, s)}
                    checked={selectedFareId === s.fareId}
                    key={i}
                  />
                ))}
            </RadioGroup>
          </FormControl>
          {flightData.fares && flightData.fares.length > 2 ? (
            <div
              className={classes.showMoreButton}
              onClick={() => toggleShowMoreFares(!showMoreFares)}
            >
              <span>show {showMoreFares ? "less" : "more"}</span>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* 4 ------------------------ */}
        {props.tripMode && props.tripMode == "ONE" && (
          <div className={classes.onwardAction}>
            <Box
              className={classes.onwardBookNowAction}
              id={`button_${props.buttonIndex}`}
              value-id={props.flightData.fares[0]}
              onClick={rePrice}
            >
              <Typography>Book Now</Typography>
            </Box>
          </div>
        )}
      </div>

      {moreDetailsOpen && width > breakpoint && (
        <FlighCardMoreDetailsVew
          segments={props.flightData.segment}
          selectedFareDetail={selectedFareDetail}
          fareRules={fareRules}
          setMoreDetailsOpen={setMoreDetailsOpen}
          flightData={flightData}
          {...props}
        ></FlighCardMoreDetailsVew>
        // <FlightMoreDetails flightData={flightData} {...props} />
      )}

      {/* </div> */}
      {width < breakpoint && (
        /*<Modal
          open={moreDetailsOpen}
          onClose={() => setMoreDetailsOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {" "}
              <IconButton onClick={() => setMoreDetailsOpen(false)}>
                <CloseOutlined />
              </IconButton>
            </Box>

            <FlighCardMoreDetailsVew
              segments={props.flightData.segment}
              selectedFareDetail={selectedFareDetail}
              fareRules={fareRules}
            ></FlighCardMoreDetailsVew>
          </Box>
        </Modal> */
        <Dialog
          open={moreDetailsOpen}
          onClose={() => setMoreDetailsOpen(false)}
          fullScreen
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {" "}
              <IconButton onClick={() => setMoreDetailsOpen(false)}>
                <CloseOutlined />
              </IconButton>
            </Box>

            <FlighCardMoreDetailsVew
              segments={props.flightData.segment}
              selectedFareDetail={selectedFareDetail}
              fareRules={fareRules}
              flightData={flightData}
              {...props}
            ></FlighCardMoreDetailsVew>
          </Box>
        </Dialog>
      )}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 10,
  boxShadow: 24,
  p: 2,
};

export default withRouter(NonMUIFlightCard);

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      ref={props.inref}
      {...props}
    />
  );
}

const radioStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      /*backgroundColor: "transparent",*/
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: `${theme.palette.primary.main}40`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: `${theme.palette.primary.main}10`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
  },
}));

const getStyles = (showMoreFares, showNetFare) => {
  return makeStyles((theme) => ({
    root: {
      boxSizing: "border-box",
      width: "100%",
      boxShadow: "0 0 7px rgba(0,0,0,0.25)",
      "& .flex-dir-col": {
        flexDirection: "column !important",
        alignItems: "flex-start !important",
      },
    },
    NonMUIFlightCard: {
      minHeight: "106px",
      maxWidth: "100%",
      width: "100%",
      position: "relative",
      boxSizing: "border-box",
      // overflowX:'scroll',
      backgroundColor: theme.palette.background.light,
      boxShadow: `0px 0px 5px 0px rgba(145,143,145,0.6)`,
      padding: "10px",
      margin: "6px",
      borderRadius: "6px",
      [theme.breakpoints.down(1080)]: {},
      "&.selected-card": {
        background: theme.palette.background.lightFadedGray,
      },
    },
    cardContent: {
      // position: "relative",
      // display: "grid",
      // alignItems: "center",
      // gap: "6px",
      // justifyContent: "space-between",
      // [theme.breakpoints.down(1080)]: {
      //   gridTemplateColumns: "repeat(2,auto)",
      //   gridairlineDetailsTemplateRows: "repeat(2,auto)",
      // },
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "3px 16px 0 0 ",
      [theme.breakpoints.down(786)]: {
        padding: 0,
      },
      [theme.breakpoints.down(550)]: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
      },
    },
    /// 1 --------------------
    airlineDetails: {
      width: "20%",
      padding: "20px 0 0 0",
      "& .carrier-details-div": {
        display: "flex",
        alignItems: "center",
      },
      "& .more-action-div": {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down(960)]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
        [theme.breakpoints.down(550)]: {
          flexDirection: "row",
          alignItems: "center",
        },
      },
      [theme.breakpoints.down(550)]: {
        width: "100%",
      },
    },

    airline_image: {
      width: "fit-content",
      marginRight: 10,
      "& img": {
        height: "30px",
        width: "30px",
        [theme.breakpoints.down(1080)]: {
          height: "24px",
          width: "24px",
        },
      },
    },

    airline_title: {
      width: "fit-content",
      margin: "4px 0",
    },
    airline_detailButton: {
      cursor: "pointer",
      marginRight: 10,
      background:theme.palette.text.secondary,
      borderRadius: 6,
      "& div": {
        //border: `2px solid ${theme.palette.primary.main}`,
        //borderRadius: "20px",
        padding: "4px 8px",
        fontSize: "10px",
        color: "#fff"

      },
    },

    /// 2 --------------------
    flightDetails: {
      display: "flex",
      flexDirection: "column",
      padding: "20px 0 0",
      justifyContent: "center",
      width: "35%",
      [theme.breakpoints.down(550)]: {
        width: "100%",
      },
    },
    flightDetails_Timings: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      [theme.breakpoints.down(1240)]: {
        gap: "10px",
        textAlign: "center",
        [theme.breakpoints.down(900)]: {
          marginLeft: "-50px",
        },
      },
    },
    departure: {
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        textAlign: "left",
      },
    },
    duration: {
      minWidth: 50,
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        textAlign: "left",
      },
    },
    arrival: {
      textAlign: "right",
    },
    flightDetails_Itinaries: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateRows: "repeat(1,1fr)",
      "& svg": {
        height: "13px",
        [theme.breakpoints.down(1080)]: {
          height: "10px",
        },
      },
      "& .add-service-item": {
        display: "flex",
        alignItems: "center",
        "& span": {
          whiteSpace: "nowrap",
          overflowX: "hidden",
        },
      },
    },
    flightDetails_Share: {
      [theme.breakpoints.down(650)]: {
        marginLeft: 5,
      },
      marginTop: 0,
      display: "flex",
      justifyContent: "flex-start",
      "& .share-dropdown-toggle-button": {
        cursor: "pointer",
        borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
      },
      "& .share-div": {
        marginTop: 5,
        position: "relative",
      },
      "& .share-dropdown": {
        position: "absolute",
        top: -36,
        left: -40,
        borderRadius: 3,
        padding: "2px 15px",
        backgroundColor: theme.palette.primary.disabled,
        "& i": {
          color: theme.palette.primary.main,
        },
      },
      "& .share-button": {
        margin: "2px 8px",
        cursor: "pointer",
        "& i": {
          fontSize: 18,
        },
      },
    },

    /// 3 --------------------
    priceDetails: {
      width: "30%",
      display: "flex",
      alignItems: "flex-start",
      position: "relative",
      padding: "0 0 0 5px",
      [theme.breakpoints.down(550)]: {
        width: showNetFare ? 100 : "100%",
        marginTop: 15,
        minWidth: 100,
        // height: "60px !important",
      },
    },
    FlightPricesLabel: {
      marginBottom: "6px",
      alignItems: "flex-start",
      "& .MuiTypography-root.MuiTypography-body1": {
        maxHeight: 62,
        // overflowY: "hidden",
        overflowX: "visible",
        lineHeight: 1.25,
        width: 130,
      },
      "& .MuiFormControlLabel-label": {
        "& p": {
          margin: 0,
          padding: 0,
          fontSize: "18px",
          fontWeight: "700",
          color: theme.palette.primary.darkText,
          position: "relative",
          "& span": {
            fontSize: 16,
            marginLeft: 15,
            [theme.breakpoints.down(550)]: {
              marginLeft: 0,
              // height: "60px !important",
            },
            position: "absolute",
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
            "&.price-seat": {
              top: 5,
              left: 55,
              [theme.breakpoints.down(550)]: {
                // height: "60px !important",
                left: 57,
              },
            },
            "&.price-refundable": {
              top: 3,
              left: 75,
              [theme.breakpoints.down(550)]: {
                // height: "60px !important",
                left: 75,
              },
            },
            "&.price-baggage": {
              top: 4,
              left: 93,
              [theme.breakpoints.down(550)]: {
                // height: "60px !important",
                left: 89,
              },
            },
          },
          [theme.breakpoints.down(1320)]: {
            fontSize: "12px",
          },
        },
        "& span.fare-type": {
          margin: 0,
          padding: 0,
          fontSize: "10px",
          fontWeight: 600,
          color: theme.palette.text.secondary,
          // [theme.breakpoints.down(1320)]: {
          //   fontSize: "6px",
          // },
        },
      },
      "& .nf-root": {
        marginLeft: 5,
      },
      "& .nf-span": {
        fontSize: "0.7rem",
      },
      "& .add-sign": {
        margin: "0 4px",
      },
      "& .rupee-sign": {
        marginLeft: 6,
        marginRight: 3,
      },
      "& .base-fare": {
        color: theme.palette.primary.danger,
      },
      "& .earning": {
        color: theme.palette.primary.success,
      },
    },

    // -----------------------------
    f24b: {
      fontSize: "20px",
      fontWeight: 700,
      [theme.breakpoints.down(1240)]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down(283)]: {
        fontSize: "13px",
      },
    },
    leftText: { textAlign: "left" },
    rightText: { textAlign: "right" },
    f20b: {
      fontSize: "20px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "16px",
      },
    },

    f16b: {
      fontSize: "16px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
    f14b: {
      fontSize: "14px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
    f12b: {
      fontSize: "12px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      },
    },
    f12r: {
      fontSize: "12px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "11px",
      },
    },
    f10r: {
      fontSize: "10.5px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "6px",
      },
    },
    f8r: {
      fontSize: "10px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      },
    },
    centerText: {
      textAlign: "center",
      display: "flex",
    },
    showMoreButton: {
      cursor: "pointer",
      position: "absolute",
      top: showMoreFares === true ? "unset" : "unset",
      bottom: showMoreFares === false ? "unset" : "5px",
      left: "100px",
      bottom: "-8px",
      [theme.breakpoints.down(550)]: {
        top: "unset",
        left: "unset",
        bottom: -10,
        right: 30,
      },
      "& span": {
        fontSize: 11.3,
        [theme.breakpoints.down(363)]: {
          fontSize: 8,
        },
        fontWeight: 500,
        minWidth: 45,
        float: "right",
        textDecoration: "underline",
      },
    },
    onwardAction: {
      width: "10%",
      minWidth: 100,
      [theme.breakpoints.down(1565)]: {
        width: "14%",
        // height: "60px !important",
      },
      [theme.breakpoints.down(1170)]: {
        width: "10%",
        // height: "60px !important",
      },
      [theme.breakpoints.down(550)]: {
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        marginLeft: 50,
        width: "40%",
      },
    },
    seatsLeft: {
      color: "#DF3049",
      fontWeight: 500,
    },
    onwardBookNowAction: {
      boxShadow: "0 10px 10px -7px rgba(0,0,0,0.40)",
      width: "100%",
      // backgroundColor: theme.palette.secondary.darkOrange,
      // backgroundColor: theme.palette.primary.sharkGray,
      background: theme.palette.buttons.bookNowBtn,
      // cursor: "pointer",
      color: theme.palette.primary.lightText,
      cursor: "not-allowed",
      //padding: "12px 24px",
      padding: "10px 10px",
      borderRadius: "6px",
      cursor: "pointer",
      "& p": {
        textAlign: "center",
        fontWeight: "700",
        [theme.breakpoints.down(1120)]: {
          fontSize: 13,
        },
      },
      [theme.breakpoints.down(1161)]: {
        padding: "8px 10px",
      },
    },
  }));
};

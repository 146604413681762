import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "fixed",
      backgroundColor: `${theme.palette.background.lightFadedGray} !important`,
      // background: "linear-gradient(120deg, #fff 30%, #f26b34 20%)",
      background: "linear-gradient(to right, #f26b34 50%, #323467)",
      color: theme.palette.primary.defaultText,
      // borderRadius: 60,
      // top: 70,
      top: 40,
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: "95%",
      width: "100%",

      "& .logoContainer": {
        backgroundColor: "#fff",
        position: "absolute",
        height: 80,
        width: "30%",
        // borderTopLeftRadius: 60,
        // borderBottomLeftRadius: 60,
        left: 0,
        clipPath: "polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0% 100%)",
      },
      // marginLeft:
      // marginBottom: 15,
      "& .MuiAppBar-colorPrimary": {
        backgroundColor: theme.palette.background.lightFadedGray,
      },
      "& a": {
        color: theme.palette.primary.lightText,
        fontWeight: 500,
        position: "relative",
      },
      "& ul": {
        listStyle: "none",
        display: "flex",
      },
      "& li": {
        padding: "0 10px",
        alignSelf: "center",
      },
    },
    mobileNavItems: {
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all ease-in-out 0.3s",
      flexDirection: "column",
      "& ul": { padding: "0 10px" },
      "& a": {
        color: "#000",
        display: "block",
        textAlign: "left",
        width: 100,
        padding: "10px 0px",
      },
    },
    navBarContainer: {
      height: 80,
      display: "flex",
      alignItems: "center",
      position: "relative",
      maxWidth: "98%",
      "& .MuiToolbar-regular": {
        justifyContent: "space-between",
        width: "100%",
      },
    },
    navItem: {
      margin: "auto",
      "& .active": {
        paddingBottom: 5,
        // textDecoration: "underline",
        borderBottom: "1px solid #fff",
      },
    },

    mobileNavItem: {},
    navDropDown: {
      cursor: "pointer",
      position: "relative",
      color: theme.palette.primary.doveGray,
      transition: "all ease-in-out 0.3s",
      background: theme.palette.primary.gainsboro,
      width: "1.5rem",
      height: "1.5rem",
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      textAlign: "center",
    },
    "& .active": {
      color: "green",
    },
    navEffect: {
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&:after": {
        content: '""',
        position: "absolute",
        left: 0,
        display: "inline-block",
        height: "1em",
        width: "100%",
        borderBottom: "2px solid",
        marginTop: 10,
        opacity: 0,
        transition: "opacity 0.35s, transform 0.35s",
        transform: "scale(0, 1)",
      },
      "&:hover:after": {
        opacity: 1,
        transform: "scale(1)",
      },
    },
    logoutBtn: {
      color: theme.palette.primary.lightText,
      width: "100%",
      borderRadius: "0.5rem",
      fontSize: 12,
      border: "none",
      transition: "all 0.2s",
      // position: "relative",
      overflow: "hidden",
      // zIndex: 1,
      background: theme.palette.background.darkDefault,
      marginTop: 2,

      [theme.breakpoints.down("sm")]: {
        width: 200,
        marginTop: 20,
      },

      "&:hover": {
        background: theme.palette.background.primary,
      },
    },
  };
});

export default useStyles;

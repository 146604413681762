import React, { useState } from "react";
import { Grid, Typography, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const PrivacyPolicy = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomepage = () => {
    history.push("/");
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          marginBottom: 60,
          paddingLeft: isMobile ? 10 : "6%",
          paddingRight: isMobile && 10,
        }}
      >
        <Grid
          container
          spacing={isMobile ? 0 : 3}
          style={{ width: "100%" }}
          justifyContent="center"
        >
          <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
            <a onClick={goToHomepage} className={classes.breadcrumbLink}>
              <i className="fa fa-home"></i> Home
            </a>
          </Grid>

          <Grid item xs={12} md={10}>
            <Grid
              container
              spacing={1}
              className={`${classes.firstCard} ${classes.Grid}`}
            >
              <Grid item md={12}>
                <Typography
                  variant="h3"
                  className={classes.sectionHeader}
                  gutterBottom
                >
                  PRIVACY POLICY:
                </Typography>
                <hr style={{ width: "100%" }} />
              </Grid>

              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Midas International (hereinafter “Mydonetrip”) understands its
                  valuable customer’s concerns for privacy and undertakes
                  several measures of protecting and maintaining their personal
                  information that is being shared with us. The applicability of
                  this Privacy Policy extends to the User who inquires or means
                  to purchase or purchases the product(s) or service(s) offered
                  by Mydonetrip through any of its online channels including its
                  website (“Mydonetrip.com”), mobile site, mobile app & offline
                  channels including call centers and offices (collectively
                  referred herein as "Sales Channels"). For the purpose of this
                  Privacy Policy, Website means the website(s), mobile site(s)
                  and mobile app(s) being operated by Mydonetrip.com. By using
                  or accessing the Website or other Sales Channels, the User
                  hereby agrees with the terms of this Privacy Policy and the
                  contents herein. If you disagree with this Privacy Policy
                  please do not use or access our Website or other Sales
                  Channels. This Privacy Policy does not apply to any
                  website(s), mobile sites and mobile apps of third parties,
                  even if their websites/products are linked to our Website.
                  User should take note that information and privacy practices
                  of Mydonetrip’s business partners, advertisers, sponsors or
                  other sites to which Mydonetrip provides hyperlink(s), may be
                  significantly different from this Privacy Policy. Accordingly,
                  it is recommended that the User reviews the privacy statements
                  and policies of any such third parties with whom they
                  interact.
                </Typography>
              </Grid>

              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  WHAT PERSONAL DETAILS MYDONETRIP.COM COLLECTS & HOW THESE ARE
                  USED:-
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Mydonetrip collects information, as outlined below, to be able
                  to offer and deliver the product(s) and services(s) chosen by
                  the User and also to fulfill our legal obligations as well as
                  our obligations towards third parties as per our User
                  Agreement.
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  "Personal Information" of User shall include the information
                  shared by the User and collected by Mydonetrip for the
                  following purposes:
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Registration on the Website: Information which the User
                  provides while subscribing to or registering on the Website,
                  including but not limited to information about the User’s
                  personal identity such as name, gender, marital status,
                  religion, age etc., contact details such as the email address,
                  postal addresses, frequent flyer number, telephone (mobile or
                  otherwise) and/or fax numbers. The information may also
                  include information such as the banking details (including
                  credit/debit card) and any other information relating to the
                  User’s income and/or lifestyle, billing information, payment
                  history etc. (as shared by the User).
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  "Other information" of the User which Mydonetrip collects
                  includes but is not limited to:
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Transactional history (other than banking details) about
                  User’s e-commerce activities, buying behavior etc.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  User’s usernames, passwords, email addresses and other
                  security-related information used by the User in relation to
                  Mydonetrip’s Services.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Data either created by the User or by a third party which the
                  User wishes to store on Mydonetrip’s servers such as image
                  files, documents etc.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Data available in public domain or received from any third
                  party including social media channels, including but not
                  limited to personal or non-personal information from the
                  User’s linked social media channels (like name, email address,
                  friend list, profile pictures or any other information that is
                  permitted to be received as per the User’s account settings)
                  as a part of the User’s account information.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Information pertaining to any other traveler(s) for whom the
                  User makes a booking through the User’s registered Mydonetrip
                  account. In such case, the User must confirm and represent
                  that each of the other traveler(s) for whom a booking has been
                  made, has agreed to have the information shared by the User
                  disclosed to Mydonetrip and further be shared by Mydonetrip
                  with the concerned service provider(s).Information pertaining
                  to any other traveler(s) for whom the User makes a booking
                  through the User’s registered Mydonetrip account. In such
                  case, the User must confirm and represent that each of the
                  other traveler(s) for whom a booking has been made, has agreed
                  to have the information shared by the User disclosed to
                  Mydonetrip and further be shared by Mydonetrip with the
                  concerned service provider(s).
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  In case of visa related services being provided by Mydonetrip,
                  copies of the User’s passport, bank statements, originals of
                  the filled in application forms, photographs, and any other
                  information which may be required by the respective embassy to
                  process the User’s visa application.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  For international bookings, User, in compliance with the
                  Liberalized Remittance Scheme (LRS) of RBI or any other law
                  may be required to provide details such as their PAN
                  information or passport details number or any such information
                  required by Service Provider. Such information shall be
                  strictly used as per the aforesaid requirements only. In case
                  a User does not wish to provide this information, Mydonetrip
                  may not be able to process the booking. Mydonetrip will never
                  share User’s PAN details without their prior consent unless
                  otherwise such action is required by any law enforcement
                  authority for investigation, by court order or in reference to
                  any legal process.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  In case the User opts for contactless check-in at Hotels,
                  copies of the User’s government identification like aadhar,
                  driving license, election card etc., Self-declaration and any
                  other information like date of birth, destination/origin of
                  travel and place of residence that may be required by the
                  concerned Hotel to honour the User’s hotel booking. For
                  international bookings, the User, in compliance with the
                  Liberalized Remittance Scheme (LRS) of RBI or any other law
                  may be required to provide details such as their PAN
                  information or passport details or any such information that
                  may be required by the Service Provider.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  The User’s Covid-19 Vaccination status and certificate, in
                  case the User wishes to avail any service for the provision of
                  which, such Covid-19 vaccination related information is
                  required or to be accessed at a later stage for travel or any
                  other related purpose. Mydonetrip will never process the
                  beneficiary id and other id details contained in the
                  vaccination certificate
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Such information shall be strictly used for the aforesaid
                  specified & lawful purpose only. User further understands that
                  Mydonetrip may share this information with the end service
                  provider or any other third party for the provision and
                  facilitation of the desired booking. Mydonetrip will always
                  redact all/any sensitive & confidential information contained
                  in the vaccination certificate, passbook, bank statement or
                  any other identity card submitted for the purpose of availing
                  a service, promotional offer or booking a product on the
                  Website. In case the User does not wish to provide this
                  information or opts for deletion of the information already
                  provided, Mydonetrip may not be able to process the desired
                  booking request. Mydonetrip will never share any of the above
                  information collected including PAN card details, Vaccination
                  status & certificate, Passport details and Aadhar Card details
                  without the User’s prior consent unless otherwise such action
                  is required by any law enforcement authority for
                  investigation, by court order or in reference to any legal
                  process.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Mydonetrip uses the personal information collected from the
                  User for the following purposes
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  To Book a Service:-
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Names, addresses, phone numbers and age details are shared
                  with related service providers, including airlines, hotels, or
                  bus services to provide reservation and booking to the
                  customers or travelers.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  To Send Promotional Offers :
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Mydonetrip uses details like mobile numbers and e-mail Id for
                  sending information about any promotional offers. Mydonetrip
                  often sponsors promotions and lucky draws to give Users an
                  opportunity to win discounts on travel or other prizes. This
                  service is optional and the User can always unsubscribe this
                  service to avoid receipt of such emails.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Member :
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  If the User opts to be a registered member of the Website,
                  information like name, address, telephone number, e-mail
                  address, a unique login name and password are asked. This
                  information is collected on the registration form for various
                  purposes such as:
                </Typography>

                <ul className={classes.listText}>
                  <li>User recognition</li>
                  <li>To make the travel reservations</li>
                  <li>
                    To let Mydonetrip connect with the User for customer service
                    purposes, if necessary
                  </li>
                  <li>
                    To confirm the new member registration and consequent
                    booking
                  </li>
                  <li>
                    To contact the User in order to meet any specific needs
                  </li>
                  <li>To improve Mydonetrip’s products and services.</li>
                </ul>
              </Grid>

              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  Surveys:
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Mydonetrip identifies the importance of its valuable
                  customers’ opinion. Mydonetrip often conducts surveys and uses
                  personal identification information to invite its regular
                  customers for taking part in the surveys. Taking part in these
                  surveys shall always be the Customer’s discretion. Typically,
                  Mydonetrip conducts the surveys to know about the customer’s
                  experiences with Mydonetrip.com and to make the Website,
                  mobile site and mobile app more user-friendly for its members.
                  Identity of the survey participants is anonymous unless
                  otherwise stated in the survey
                </Typography>
              </Grid>

              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  Safeguard Sensitive Information:-
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  At Mydonetrip, we don’t store or save sensitive user
                  information regarding Credit/Debit cards and Net Banking
                  details.
                </Typography>
              </Grid>

              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  COOKIES & AUTOMATIC LOGGING OF SESSION DATA COOKIES
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Mydonetrip uses cookies to personalize the User’s experience
                  on the Website and the advertisements that may be displayed.
                  Mydonetrip’s use of cookies is similar to that of any other
                  reputable online company.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Cookies are small pieces of information that are stored by the
                  User’s browser on User’s device's hard drive. Cookies allow
                  Mydonetrip to deliver better services in an efficient manner.
                  Cookies also allow ease of access, by allowing to User to
                  log-in without having to type the User’s login name each time
                  (only the password is needed); Mydonetrip may also use such
                  cookies to display any advertisement(s) to the Users while
                  they are on the Website or to send the Users offers (or
                  similar emails – provided the Users have not opted out of
                  receiving such emails) focusing on destinations which may be
                  of the User’s interest.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  A cookie may also be placed by Mydonetrip’s advertising
                  servers or third party advertising companies. Such cookies are
                  used for the purpose of tracking the effectiveness of the
                  advertisement advertising by Mydonetrip on any website, and
                  also to use aggregated statistics about the User’s visits to
                  the Website in order to provide advertisements in the Website
                  or any other website about services that may be of potential
                  interest to the User. The third party advertising companies or
                  advertisement providers may also employ technology that is
                  used to measure the effectiveness of the advertisements. All
                  such information is anonymous. This anonymous information is
                  collected through the use of a pixel tag, which is an industry
                  standard technology and is used by all major websites. They
                  may use this anonymous information about the User’s visits to
                  the Website in order to provide advertisements about goods and
                  services of potential interest to the User. No Personal
                  Information is collected during this process. The information
                  so collected during this process, is anonymous, and does not
                  link online actions to a User.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  Most web browsers automatically accept cookies. By changing
                  the options on the User’s web browser or using certain
                  software programs, the User can control how and whether
                  cookies will be accepted by the browser. Mydonetrip supports
                  the User’s right to block any unwanted Internet activity,
                  especially that of unscrupulous websites. However, blocking
                  Mydonetrip’s cookies may disable certain features on the
                  Website, and may hinder an otherwise seamless experience to
                  purchase or use certain services available on the Website.
                  Please note that it is possible to block cookie activity from
                  certain websites while permitting cookies from websites you
                  trust.
                </Typography>
              </Grid>

              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  Automatic Logging of Session Data
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip records session data of the Users, which includes
                    IP address, Operating System, browser software and the
                    activities of the User on his device. We collect session
                    data to evaluate the User’s behavior. Such session data
                    helps Mydonetrip in identifying the problems with its
                    servers and improving its systems. This information does not
                    identify any visitor personally and only examines the User’s
                    geographic location.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  CONTENT GENERATED BY THE USERCONTENT GENERATED BY THE USER
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip provides an option to its Users to post their
                    experiences by way of reviews, ratings and general poll
                    questions. The User also has an option of posting questions
                    with regard to a service offered by Mydonetrip or post
                    answers to questions raised by other Users. Though the
                    participation in the feedback process is purely optional, a
                    User may still receive emails, notifications (through the
                    App/SMS/Whatsapp or any other messaging service) to share
                    the User’s review(s) or respond to questions posted by other
                    Users. The reviews written or posted by a User may also be
                    visible on other travel or travel related platforms.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Each User who posts a review or ratings or photographs,
                    shall have a profile, which other Users will be able to
                    access. Other Users may be able to view the number of trips,
                    reviews written, questions asked and answered and
                    photographs posted about
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  WITH WHOM YOUR PERSONAL INFORMATION IS SHARED Service
                  Providers and Suppliers:
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    The User’s information will be shared with the end service
                    providers like airlines, hotels, bus service providers, cab
                    rental, railways or any other suppliers who are responsible
                    for fulfilling the User’s booking. The User may note that
                    while making a booking with Mydonetrip, the User authorizes
                    Mydonetrip to share the User’s information with the said
                    service providers and suppliers. It is pertinent to note
                    that Mydonetrip does not authorize the end service provider
                    to use the concerned User’s information for any other
                    purpose(s) save and except as may be requiring for
                    fulfilling their part of service. However, the use of
                    information as shared with the said service
                    providers/suppliers for any other purpose is beyond the
                    purview and control of Mydonetrip as they process Personal
                    Information as independent data controllers, and hence
                    Mydonetrip shall not be held liable for the same. The User
                    is therefore advised to review the privacy policies of the
                    respective service provider or supplier whose services, the
                    User chooses to avail.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip does not sell or rent individual customer names
                    or other Personal Information of Users to third parties
                    except sharing of such information with Mydonetrip’s
                    business / alliance partners or vendors who are engaged by
                    Mydonetrip for providing various referral services and for
                    sharing promotional and other benefits to Mydonetrip’s
                    customers from time-to-time basis their booking history with
                    Mydonetrip.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  COMPANIES IN THE SAME GROUP:
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    In the interests of improving personalization and service
                    efficiency, Mydonetrip may, under controlled and secure
                    circumstances, share the User’s Personal Information with
                    its affiliate or associate entities. This will enable
                    Mydonetrip to provide the User with information about
                    various products and services, both leisure and travel
                    related, which might interest the User; or help Mydonetrip
                    address the User’s questions and requests in relation to
                    their bookings.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  BUSINESS PARTNERS AND THIRD-PARTY VENDORS:
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip may also share certain filtered Personal
                    Information with its corporate affiliates or business
                    partners, including banks, who may contact the Users, either
                    directly or through Mydonetrip, to offer certain products or
                    services based upon independent or Credit Bureau assessment
                    of eligibility of the Users basis the filtered Personal
                    Information shared, and which may include free or paid
                    products / services, which will enable the User to have
                    better travel experience or to avail certain benefits
                    specially made for Mydonetrip’s customers. Examples of such
                    partners are entities offering co-branded credit cards,
                    travel insurance, insurance cover against loss of wallet,
                    banking cards or similar sensitive information etc. If the
                    User chooses to avail any such services offered by
                    Mydonetrip’s business partners, the services so availed will
                    be governed by the privacy policy of the respective service
                    provider.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip may share the User’s Personal Information with
                    any third party whom Mydonetrip may engage to perform
                    certain tasks on its behalf, including but not limited to
                    payment processing, data hosting, and data processing
                    platforms.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip uses non-identifiable Personal Information of
                    Users in aggregate or anonymized form to build higher
                    quality, more useful online services by performing
                    statistical analysis of the collective characteristics and
                    behavior of its customers and visitors, and by measuring
                    demographics and interests regarding specific areas of the
                    Website. Mydonetrip may provide anonymous statistical
                    information based on this data to suppliers, advertisers,
                    affiliates and other current and potential business
                    partners.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip may also use such aggregate data to inform these
                    third parties as to the number of people who have seen and
                    clicked on links to their websites. Any Personal Information
                    which Mydonetrip collects and which it may use in an
                    aggregated format shall be Mydonetrip’s property. Mydonetrip
                    may use it, in its sole discretion and without any
                    compensation to the User, for any legitimate purpose
                    including without limitation the commercial sale thereof to
                    third parties.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Occasionally, Mydonetrip will hire a third party for market
                    research, surveys etc. and will provide information to these
                    third parties specifically for use in connection with these
                    projects. The information (including aggregate cookie and
                    tracking information) Mydonetrip provides to such third
                    parties, alliance partners, or vendors are protected by
                    confidentiality agreements and such information shall be
                    used solely for completing the specific project, and in
                    compliance with the applicable
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  How to Contact Us
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    In case you have any questions or concerns about these
                    privacy policies, please call us at or send us an email at{" "}
                    {AppConstant.supportEmail}.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  DISCLOSURE OF INFORMATION
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    In addition to the circumstances described above, Mydonetrip
                    may disclose User's Personal Information if required to do
                    so:
                  </span>
                </Typography>
                <ul className={classes.listText}>
                  <li>
                    by law, required by any enforcement authority for
                    investigation, by court order or in reference to any legal
                    process
                  </li>
                  <li>to conduct its business</li>
                  <li>
                    for regulatory, internal compliance and audit exercise(s)
                  </li>
                  <li>to secure its systems</li>
                  <li>
                    to enforce or protect rights or properties of Mydonetrip or
                    any or all of its affiliates, associates, employees,
                    directors or officers
                  </li>
                  <li>
                    When there is reason to believe that disclosing Personal
                    Information of User(s) is necessary to identify, contact or
                    bring legal action against someone who may be causing
                    interference with its rights or properties, whether
                    intentionally or otherwise, or when anyone else could be
                    harmed by such activities.
                  </li>
                </ul>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Such disclosure and storage may take place without the
                    User’s knowledge. In such an event, Mydonetrip shall not be
                    liable to the User or any third party for any damages
                    howsoever arising from such disclosure and storage.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  PERMISSIONS ASKED AND REQUIRED DURING MYDONETRIP MOBILE APP
                  INSTALLATION: - Android App permissions:
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    When Mydonetrip App (“App”) is installed on the User’s
                    phone, a list of permissions appears. Permissions that
                    Mydonetrip requires are:
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>
                      Device & App History:
                    </span>{" "}
                    Mydonetrip needs the User’s device permission to collect
                    details like Operating System name & version, mobile
                    network, preferred language, and few others. On the basis of
                    these inputs, Mydonetrip optimizes the User’s travel booking
                    experience.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Identity:</span> Through
                    this permission, the User allows Mydonetrip to get the info
                    of one’s account(s) on the User’s mobile device. This
                    information is used to fill the User’s email IDs
                    automatically. This action allows Mydonetrip to map e-mail
                    ID’s of its Users to give them the benefits of exclusive
                    travel discounts and cashback etc.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Location:</span> This
                    permission allows Mydonetrip to let the User know the
                    benefits of ongoing specific offers at the User’s location.
                    When the User launches the App for travel booking,
                    Mydonetrip detects the User’s location automatically and the
                    User’s nearest airport or city is auto-filled. For
                    international journeys, this action allows Mydonetrip to
                    ascertain the User’s time zone and provide information
                    accordingly.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Photo/Media/Files</span>{" "}
                    The libraries in the App use these permissions to allow map
                    data to be saved to User’s phone's external storage, like SD
                    cards. By saving map data locally, User’s smart phone is not
                    required to re-download the same map data every time the
                    User uses the App. This provides User a seamless Map based
                    Hotel selection experience, even on low bandwidth
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>
                      WIFI Connection Information:
                    </span>{" "}
                    When User allows Mydonetrip the permission to detect User’s
                    Wi-Fi connection, Mydonetrip optimizes User’s experience
                    such as more detailing on maps, better image loading, more
                    hotel/ flights/ package options to choose from, etc.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>SMS:</span> If the User
                    allows Mydonetrip to access its SMS, Mydonetrip reads SMS
                    and fills the One Time Password (“OTP”) automatically while
                    making transactions. This allows the User to continue to
                    remain on the App and not requiring the OTP to be filled in
                    manually thereby offering the User, a hassle-free booking
                    experience.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Contacts:</span> If the
                    User allows Mydonetrip to access its contacts, Mydonetrip
                    can invite User’s friends to try its App and also give them
                    recommendations for various travel related services. These
                    details will be stored on Mydonetrip’s servers and
                    synchronized from the User’s phone.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Device:</span> This
                    permission helps Mydonetrip in identifying the Android ID
                    through which Mydonetrip can exclusively recognize its
                    Users. It also enables Mydonetrip to know the contact
                    details through which Mydonetrip auto-fills specific
                    details, resultantly guarantying a seamless booking
                    experience.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Calendar:</span> This
                    permission allows Mydonetrip in putting a travel plan on the
                    User’s calendar.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip takes all reasonable measures to protect the
                    information that a User shares. Mydonetrip has undertaken
                    advanced technology and security measures along with strict
                    policy guidelines to secure the privacy of its customers and
                    save their information from any unwanted access. Mydonetrip
                    is constantly enhancing its security measures by using more
                    advanced
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip’s Privacy Policy may change due to any unforeseen
                    circumstances and enhancement of technologies. We will duly
                    notify the Users as and when necessary.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  IOS App Permissions
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Notifications:</span> If
                    a User allows notifications for Mydonetrip App, it enables
                    Mydonetrip to send across exclusive deals, promotional
                    offers, travel related updates, etc. on the User’s device.
                    If the User permits this, updates for your travel like PNR
                    status, booking confirmation, refund (in case of
                    cancellation), etc. will be sent through SMS. However,
                    Mydonetrip will not be liable for non-receipt of such
                    updates by the User for any reason whatsoever.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Contacts:</span>If the
                    User grants permission to access the User’s contact list, it
                    enables Mydonetrip to provide a lot of social features to
                    the User such as sharing your hotel/ flight/ holidays with
                    your friends, inviting your friends to try Mydonetrip’ App,
                    send across referral links to User’s friends, etc.
                    Mydonetrip will also use this information to make
                    recommendations for hotels where User’s friends have stayed.
                    This information will be stored on Mydonetrip’s servers and
                    synced from the User’s phone.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    <span className={classes.boldText}>Location:</span> This
                    permission enables Mydonetrip to give the User the benefit
                    of location specific deals and provide the User, a
                    personalized in-funnel experience. When the User launch the
                    App to make a travel booking, Mydonetrip auto-detects the
                    User’s location so that the User’s nearest Airport or City
                    is auto-filled. Mydonetrip require this permission to
                    recommend the User nearby hotels in case the User is running
                    late and wants to make a quick last minute booking for the
                    nearest hotel. The User’s options are personalized basis the
                    User’s locations. For international travel, this enables
                    Mydonetrip to determine the User’s time zone and provide
                    information accordingly.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  PROTECTION OF PERSONAL INFORMATION
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    All payments on the Website are secured. The Website has
                    stringent security measures in place to protect the loss,
                    misuse, and alteration of the information under Mydonetrip’s
                    control. Once the User’s information is in Mydonetrip’s
                    possession, we adhere to strict security guidelines,
                    protecting it against unauthorized access.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  WITHDRAWAL OF CONSENT AND PERMISSION
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    The User may withdraw its consent to submit any or all
                    Personal Information or decline to provide any permission on
                    the Website as covered above at any time. In such a
                    circumstance, the User may face limited access to the
                    Product(s) and Services (s) being offered by Mydonetrip.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  USER'S RIGHTS QUA PERSONAL INFORMATION
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    The User may access its Personal Information from its user
                    account with Mydonetrip. The User may choose to correct or
                    delete such personal information directly by accessing such
                    User Account, except for such mandatory fields.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  ELIGIBILITY TO TRANSACT WITH MYDONETRIP
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    The User must atleast be 18 years of age to transact
                    directly with Mydonetrip and also to consent to the
                    processing of their personal data.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  TRANSFER OF INFORMATION OUTSIDE INDIA
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    The data shared with Mydonetrip shall be primarily processed
                    in India and such other jurisdictions where a third party
                    engaged by Mydonetrip may process the data on Mydonetrip’s
                    behalf. By agreeing to this policy, you are providing EMT
                    with your explicit consent to process your personal
                    information for the purpose(s) defined in this policy. The
                    data protection regulations in India or such other
                    jurisdictions mentioned above may differ from those of your
                    country of residence.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    If you have any concerns in the processing your data and
                    wish to withdraw your consent, you may do so by writing to
                    the following email id: care@Mydonetrip.com. However, if
                    such processing of data is essential for us to be able to
                    provide service to you, then we may not be able to serve or
                    confirm your bookings after your withdrawal of consent. For
                    instance, if you want to book any international holiday
                    package in fixed departures (group bookings), then certain
                    personal information of yours like contact details, gender,
                    dietary preferences, choice of room with smoking facility,
                    any medical condition which may require specific attention
                    or facility etc. may have to be shared by us with our
                    vendors in each city where you will stay, and they may
                    further process this information for making suitable
                    arrangements for you during the holiday. Such sharing and
                    processing of information may extend to the hotel where you
                    will stay or the tour manager who will be your guide during
                    the travel.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    A withdrawal of consent by you for us to process your
                    information may:
                  </span>
                </Typography>
                <ul className={classes.listText}>
                  <li>
                    severely inhibit our ability to serve you properly and in
                    such case, we may have to refuse the booking altogether{" "}
                  </li>
                  <li>
                    unreasonably restrict us to service your booking (if a
                    booking is already made) which may further affect your trip
                    or may compel us to cancel your booking.
                  </li>
                </ul>
              </Grid>
              <Grid item md={12} className={classes.sectionTextGrid}>
                <Typography
                  variant="subtitle1"
                  className={classes.sectionSubhead}
                  gutterBottom
                >
                  RETENTION OF USER’S PERSONAL INFORMATION BY MYDONETRIP
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Mydonetrip will retain User’s Personal Information on its
                    servers for as long as is reasonably necessary for the
                    purposes listed in this policy. In some circumstances
                    Mydonetrip may retain User’s Personal Information for longer
                    periods of time, for instance where Mydonetrip is required
                    to do so in accordance with any legal, regulatory, tax or
                    accounting requirements.
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.sectionText}
                >
                  <span className="highlight">
                    Where User’s personal data is no longer required, Mydonetrip
                    will ensure it is either securely deleted or stored in a way
                    which means it will no longer be used by the business.
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            className={classes.sectionTextGrid}
            style={{ textAlign: "center" }}
          >
            <a onClick={goToHomepage} className={classes.breadcrumbLink}>
              <i className="fa fa-home"></i> Home
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicy;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& a": {
      cursor: "pointer",
    },
    "& .highlight": {
      color: theme.palette.primary.orange,
    },
  },

  Grid: {
    background: theme.palette.primary.lightText,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  breadcrumbLink: {
    color: `${COLORS.DEFAULT_TEXT_ACTIVE} !important`,
    "&:hover": {
      color: `${COLORS.HIGHLIGHT_COLOR_SECONDARY} !important`,
    },
  },
  sectionSubhead: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.davyGray,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  boldText: {
    fontWeight: 700,
  },
  breadcrumbLink: {
    color: `${theme.palette.primary.active} !important`,
    "&:hover": {
      color: `${theme.palette.primary.pomegranate} !important`,
    },
  },
}));

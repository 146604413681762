import React, {useState} from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {scrollToTop} from 'oautils/oaCommonUtils';
import sendIcon from "assets/triumphTravel/images/icons/paper-plane-light.svg";

const ContactUsForm = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();

    return (
        <div className={classes.contactFormRoot}>
            <OaFormAlerts 
                    isSaving={isSaving} 
                    isSuccess={isSuccess} 
                    isError={isError} 
                    infoText={infoText} 
                    setIsError={setIsError} 
                    setIsSuccess={setIsSuccess} 
                />

            <Formik
                initialValues={{
                    firstname: "",
                    lastname: "",
                    phone: "",
                    email: "",
                    message: "",
                }}
                validationSchema={Yup.object().shape({
                    firstname: Yup.string().required("First Name is Required"),
                    email: Yup.string().email('Must be a valid email').max(255).required("e-Mail address is Required"),
                    message: Yup.string().required("Message/Comment is Required"),
                })}
                onSubmit={(values,  {setSubmitting}) => {
                    // console.log("values", values);

                    

                    setIsError(false);
                    setIsSuccess(false);
                    setSubmitting(true);
                    setIsSaving(true);
                    scrollToTop(0);
                    
                    
                   
                    // console.log("The search params are ", searchParams);
                    apiCall(WebApi.addConcatus, values, (response) => {
                          setIsError(!response.success);
                          setIsSuccess(response.success);
                          setSubmitting(false);
                          setIsSaving(false);
                          setInfoText(response.message);
                        //   if(response.success === true) {
                        //       setInfoText('Agency successfully updated');
                        //       // enableReinitialize=true;
                        //       // resetForm(false);
                        //   }
                      });
                }}
            >
                {({ values, errors, touched, handleChange, setFieldValue }) =>
                <Form>
                    <div className="contact-form-container">
                        <div className="contact-form-row row-50">
                            <div className="contact-form-item-left flex-column">
                                <div className="contact-form-group">
                                    {/* <label>Comment or Message <b>*</b></label> */}
                                    <TextField
                                        name="message"
                                        value={values.message}
                                        onChange={(event) => {
                                            setFieldValue("message", event.target.value)
                                        }}
                                        multiline
                                        minRows={11}
                                        fullWidth
                                        placeholder="Enter Your Message/Comment"
                                        variant="outlined"
                                        label="Comment or Message (mandatory)"
                                    />
                                    <ErrorMessage
                                        name="message"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                            </div>
                            <div className="contact-form-item-right flex-column">
                                <div className="contact-form-group">
                                    {/* <label>First Name <b>*</b></label> */}
                                    <TextField
                                        name="firstname"
                                        value={values.firstname}
                                        onChange={(event) => {
                                            setFieldValue("firstname", event.target.value)
                                        }}
                                        fullWidth
                                        placeholder="Enter First Name"
                                        variant="outlined"
                                        label="First Name (mandatory)"
                                    />
                                    <ErrorMessage
                                        name="firstname"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                <div className="contact-form-group">
                                    {/* <label>Last Name</label> */}
                                    <TextField
                                        name="lastname"
                                        value={values.lastname}
                                        onChange={(event) => {
                                            setFieldValue("lastname", event.target.value)
                                        }}
                                        fullWidth
                                        placeholder="Enter Last Name"
                                        variant="outlined"
                                        label="Last Name"
                                    />
                                </div>
                                <div className="contact-form-group">
                                    {/* <label>Phone</label> */}
                                    <TextField
                                        name="phone"
                                        value={values.phone}
                                        onChange={(event) => {
                                            setFieldValue("phone", event.target.value)
                                        }}
                                        type="number"
                                        fullWidth
                                        placeholder="Enter Phone Number"
                                        variant="outlined"
                                        label="Phone"
                                    />
                                </div>
                                <div className="contact-form-group">
                                    {/* <label>Email <b>*</b></label> */}
                                    <TextField
                                        name="email"
                                        value={values.email}
                                        onChange={(event) => {
                                            setFieldValue("email", event.target.value)
                                        }}
                                        fullWidth
                                        placeholder="Enter e-Mail Address"
                                        variant="outlined"
                                        label="Email (mandatory)"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                                <div className="contact-form-group">
                                    <Button variant="contained" endIcon={<Send />} type="submit">
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>}
            </Formik>
            <style>{`
            .oa-navbar-sticky {
                z-index: 1001;
            }
            `}</style>
        </div>
    );
}

export default ContactUsForm;

const Send = () => { return <img src={sendIcon} /> };

const useStyles = makeStyles(theme => ({

    contactFormRoot: {
        "& .contact-form-container": {
            "& .contact-form-row": {
                display: "grid",
                // gap: "10px",
                "& b": {
                    color: theme.palette.primary.danger,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 0,
                },
                "& label": {
                    color: theme.palette.primary.darkText,
                    "& b": {
                        color: theme.palette.primary.danger,
                    }
                },
                "& .MuiInput-underline": {
                    borderBottom: `solid 1px ${theme.palette.secondary.main}`
                },
                "& .MuiInput-underline:before": {
                    borderBottom: `solid 1px ${theme.palette.secondary.main}`
                },
                "& .MuiInput-underline:after": {
                    borderBottom: `solid 1px ${theme.palette.secondary.active}`
                },
            },
            "& .row-100": {
                gridTemplateColumns: "100%",
            },
            "& .row-50": {
                gridTemplateColumns: "65% 35%",
                [theme.breakpoints.down(600)]: {
                    gridTemplateColumns: "100%",
                },
            },
            "& .flex-column": {
                display: "flex",
                flexDirection: "column",
            },
            "& .contact-form-item": {
                gridColumn: "1 / span 2",
                padding: 10,
                "&.action-item": {
                    [theme.breakpoints.down(960)]: {
                        textAlign: "center",
                    },
                }
            },
            "& .contact-form-item-left": {
                gridColumn: "1 / span 1",
                padding: "0 0 0 0",
                [theme.breakpoints.down(600)]: {
                    gridColumn: "1 / span 2",
                },
            },
            "& .contact-form-item-right": {
                gridColumn: "2 / span 1",
                padding: "0 0 0 0",
                [theme.breakpoints.down(600)]: {
                    gridColumn: "1 / span 2",
                },
                "& .contact-form-group": {
                    padding: "0 0 10px 10px",
                    "& button": {
                        width: "100%",
                        color: theme.palette.buttons.secondaryContrastText,
                        background: theme.palette.buttons.secondary,
                        padding: 10,
                        borderRadius: 0,
                        "&.MuiButton-contained": {
                            boxShadow: "none",
                            background: theme.palette.buttons.secondary,
                            color: theme.palette.primary.secondaryContrastText,
                        },
                        "& .MuiButton-label": {
                            color: theme.palette.buttons.secondaryContrastText,
                        },
                    },
                },
            },
            "& .action-item button": {
                width: 140,
                color: theme.palette.buttons.secondaryContrastText,
                background: theme.palette.buttons.secondary,
            },
            "& .contact-form-group": {
                textAlign: "left",
            },
        },
    },
}));

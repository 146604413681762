import React from "react";
import WebApi from "api/ApiConstants";
import * as Yup from "yup";
import differenceInDays from "date-fns/differenceInDays";
import dateFnsFormat from "date-fns/format";
import RoomModal from "components/LandingPage/for-mobile/RoomModal";
import OaFormAlerts from "pages/components/OaFormAlerts";
import ReactDatePicker from "react-datepicker";
import { makeStyles, TextField, withWidth } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setHotelCountry,
  setHotelCity,
  setHotelSearch,
  setHotelBookingInitialTime,
} from "redux/action";
import { AppStateContext } from "layouts/AppStateProvider";

import OaOutlinedAutoComplete from "oahoc/OaOutlinedAutoComplete";
import HotelLocationCountryInput from "components/CommonSearchComponents/Hotel/HotelLocationCountryInput";
import HotelLocationCityInput from "components/CommonSearchComponents/Hotel/HotelLocationCityInput";

const ModifyIslandSearch = (props) => {
  const classes = useStyles();
  const formikRef = React.useRef();
  const locationAutocompleteRef = React.useRef(null);
  const history = useHistory();

  const [hotelCity, setHotelCity] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [callingApi, setCallingApi] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [roomModalOpen, setRoomModalOpen] = React.useState(false);
  const { setSecLogo } = React.useContext(AppStateContext);
  const [modifyHotelSubmit, setModifyHotelSubmit] = React.useState(false);
  const [childError, setChildError] = React.useState(false);

  const initialValues = {
    ...props.selectedData,
    checkInDateDisplay: new Date(props.selectedData.checkInDateDisplay),
    checkOutDateDisplay: new Date(props.selectedData.checkOutDateDisplay),
  };

  const validateChildAge = (rooms) => {
    return rooms.every((room) => {
      if (room.noOfChild > 0) {
        return room.childAge.every((age) => age > 0);
      } else return true;
    });
  };


  const handleHotelSearchSubmit = (values) => {
    // values.countryCode = values.countryCode.code;
    // const cityObj = values.cityId;
    // values.cityId = cityObj.destinationId;
    if (validateChildAge(values.roomGuests)) {
      setChildError(false);

      values.city = values.cityObj.cityName;
      // values.guestNationality = values.guestNationality.code;
      values.noOfNights = differenceInDays(
        values.checkOutDateDisplay,
        values.checkInDateDisplay
      );
      values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;
      values.noOfRooms = values.roomGuests.length;
      values.maxRating = 5;
      values.minRating = 0;
      values.isTBOMapped = true;
      values.isIslandHopper = "true";
      props.setHotelSearch(values);
      props.setResultsFound("");
      props.setModifyHotelSubmit(false);
      props.setHotelBookingInitialTime(null);
      setSecLogo(true);

      history.push("/hotel/search");

      window.sessionStorage.removeItem("service");
    } else {
      setChildError(true);
      props.setModifyHotelSubmit(false);
    }
  };

  React.useEffect(() => {

    if (props.modifyHotelSubmit) {
      formikRef.current.handleSubmit();
    }

    console.log(popularCities);
    setHotelCity(popularCities);


  }, [props.modifyHotelSubmit]);

  React.useEffect(() => {
    if (isLoading) {
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;
      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };
        if (formikRef.current) {
          formikRef.current.setFieldValue("countryCode", countryCodeObj.code);
          formikRef.current.setFieldValue("countryCodeObj", countryCodeObj);
          formikRef.current.setFieldValue("guestNationality", "IN");
          formikRef.current.setFieldValue("guestNationalityObj", countryCodeObj);
        }

        setIsLoading(false);
      } else {
        // console.log(1);
        // WebApi.getHotelCountry(
        //   {},
        //   (response) => {
        //     if (response.success === true) {
        //       setIsLoading(false);
        //       props.setHotelCountry(response.data.countryList.country);
        //     } else {
        //       setIsError(!response.success);
        //       setInfoText(response.message);
        //     }
        //   },
        //   (error) => console.log("getHotelCountry error", error)
        // );
      }
    }
  }, [isLoading]);
  console.log("hotelCity ", props.hotelCity, hotelCity)
  return (
    <div className={classes.root}>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleHotelSearchSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="htm-form-container">
              <div className="formContainer1">

                <div className="htm-form-group">
                  {/* <OaOutlinedAutoComplete
                    className="autocomplete"
                    labeltxt="LOCATION"
                    secondarytxt={values.cityObj?.countryName || ""}
                    name={`cityObj`}
                    options={hotelCity || []}
                    getOptionLabel={(a) => (a && a.cityName) || ""}
                    value={values.cityObj}
                    // loading={callingApi}
                    getOptionSelected={(option, value) =>
                      option.value == value.destinationId
                    }

                    onChange={(_, newValue) => {
                      console.log("newValue ", newValue);
                      setFieldValue("cityObj", newValue);
                      setFieldValue("cityId", newValue.destinationId);
                      setFieldValue("countryCode", newValue.countryCode);

                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        // label="Location"
                        inputRef={locationAutocompleteRef}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        placeholder="Enter City"
                      />
                    )}
                  /> */}
                       <div className="htm-form-group">
                  <label>Country</label>
                  {/* <Autocomplete
                    name={`countryCodeObj`}
                    options={props.hotelCountry || []}
                    getOptionLabel={(a) => `${a.name || ""}`}
                    value={values.countryCodeObj}
                    getOptionSelected={(option, value) =>
                      option.value == value.code
                    }
                    onChange={(_, newValue) => {
                      //console.log(newValue);
                      setFieldValue("countryCodeObj", newValue);
                      setFieldValue("countryCode", newValue.code);
                      setHotelCity([]);
                      setFieldValue("cityId", "");
                      setFieldValue("guestNationalityObj", newValue);
                      //  setFieldValue("guestNationality", newValue.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        variant="outlined"
                      />
                    )}
                    disablePortal={true}
                  /> */}
                    <HotelLocationCountryInput setFieldValue={setFieldValue}  props={{props, values}} formikRef={formikRef} />
                </div>
                <div className="htm-form-group">
                  <label>Location</label>
                  {/* <Autocomplete
                    name={`cityObj`}
                    options={hotelCity || []}
                    getOptionLabel={(a) => `${a.cityName || ""}`}
                    value={values.cityObj}
                    loading={callingApi}
                    getOptionSelected={(option, value) =>
                      option.value == value.destinationId
                    }
                    onKeyUp={(event) => {
                      //  console.log(values);
                      fetchMatchingCities(
                        1,
                        event.target.value,
                        values.countryCode
                      );
                    }}
                    onChange={(_, newValue) => {
                      setFieldValue("cityObj", newValue);
                      setFieldValue("cityId", newValue.destinationId);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        inputRef={locationAutocompleteRef}
                        inputProps={{
                          ...params.inputProps,
                          autocomplete: "new-password",
                        }}
                        placeholder="Enter City"
                      />
                    )}
                    disablePortal
                  /> */}
                        <HotelLocationCityInput setFieldValue={setFieldValue}  props={{props, values}} formikRef={formikRef} />
                </div>
                </div>
              </div>

              <div className="formContainer2">
                <div className="htm-form-group">
                  <label>Check in</label>
                  <ReactDatePicker
                    showDisabledMonthNavigation
                    name="checkInDateDisplay"
                    minDate={new Date()}
                    customInput={
                      <TextField
                        id="standard-basic"
                        variant="outlined"
                        fullWidth
                      />
                    }
                    onChange={(e, v) => {
                      setFieldValue("checkInDateDisplay", e);
                      setFieldValue(
                        "checkInDate",
                        dateFnsFormat(e, "dd/MM/yyyy")
                      );
                      setFieldValue(
                        "checkOutDateDisplay",
                        new Date(e.getTime() + 86400000)
                      );
                      setFieldValue(
                        "checkOutDate",
                        dateFnsFormat(
                          new Date(e.getTime() + 86400000),
                          "dd/MM/yyyy"
                        )
                      );
                    }}
                    monthsShown={2}
                    dateFormat="dd MMM yyyy"
                    selected={values.checkInDateDisplay}
                  />
                </div>
                <div className="htm-form-group">
                  <label>Check out</label>
                  <ReactDatePicker
                    showDisabledMonthNavigation
                    name="checkOutDate"
                    minDate={values.checkInDateDisplay}
                    customInput={
                      <TextField
                        id="standard-basic"
                        variant="outlined"
                        fullWidth
                      />
                    }
                    onChange={(e, v) => {
                      setFieldValue("checkOutDateDisplay", e);
                      setFieldValue(
                        "checkOutDate",
                        dateFnsFormat(e, "dd/MM/yyyy")
                      );
                    }}
                    monthsShown={2}
                    dateFormat="dd MMM yyyy"
                    selected={values.checkOutDateDisplay}
                  />
                </div>
                <div className="htm-form-group">
                  <label>Rooms</label>
                  <TextField
                    variant="outlined"
                    color="secondary"
                    value={`${values.roomGuests.length} ${values.roomGuests.length == 1 ? "Room" : "Rooms"
                      },  ${values.roomGuests.reduce(function (a, b) {
                        return (
                          Number(a) + Number(b.noOfAdults) + Number(b.noOfChild)
                        );
                      }, 0)} Guests`}
                    fullWidth
                    onClick={() => setRoomModalOpen(true)}
                  />

                  {roomModalOpen && (
                    <div className={classes.RoomModalOpenDev}>
                      <div className={classes.paper}>
                        <RoomModal
                          values={values}
                          setFieldValue={setFieldValue}
                          rooms={values.roomGuests}
                          closeModal={() => {
                            if (validateChildAge(values.roomGuests)) {
                              setChildError(false);
                              setRoomModalOpen(false);
                            } else {
                              setChildError(true);
                            }
                          }}
                          childError={childError}
                          setChildError={setChildError}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div style={{marginTop : 25}}>
                <Button
                      className="modify-search-btn modify-search-submit"
                      variant="contained"
                      color="primary"
                      onClick={() => setModifyHotelSubmit(true)}
                    >
                      Modify
                    </Button>    

              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelCountry: state.hotel.hotelCountry,
    hotelCity: state.hotel.hotelCity,
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelCountry: (hotelCountry) => dispatch(setHotelCountry(hotelCountry)),
    setHotelCity: (hotelCity) => dispatch(setHotelCity(hotelCity)),
    setHotelSearch: (hotelSearch) => dispatch(setHotelSearch(hotelSearch)),
    setHotelBookingInitialTime: (hotelBookingInitialTime) =>
      dispatch(setHotelBookingInitialTime(hotelBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(ModifyIslandSearch));

const validationSchema = Yup.object().shape({
  countryCode: Yup.string().required("country Field is Required"),
  cityId: Yup.string().required("Location Field is Required"),
  checkInDate: Yup.string().required("Check In Date Field is Required"),
  checkOutDate: Yup.string().required("Check Out Date Field is Required"),
  guestNationality: Yup.string().required(
    "Guest Nationality Field is Required"
  ),
});

const popularCities = [
  {
    cityName: "Athens",
    countryCode: "GR",
    countryName: "Greece",
    destinationId: "110000",
    hotelName: null,
    stateProvince: "GR",
    type: null,
  },

  {
    cityName: "Bali",
    countryCode: "ID",
    countryName: "Indonesia",
    destinationId: "110670",
    hotelName: null,
    stateProvince: "ID",
    type: null,
  },

  {
    cityName: "Crete",
    countryCode: "GR",
    countryName: "Greece",
    destinationId: "123328",
    hotelName: null,
    stateProvince: "GR",
    type: null,
  },

  {
    cityName: "La Digue Island",
    countryCode: "SC",
    countryName: "Seychelles",
    destinationId: "123382",
    hotelName: null,
    stateProvince: "SC",
    type: null,
  },

  {
    cityName: "Maldives",
    countryCode: "MV",
    countryName: "Maldives",
    destinationId: "101365",
    hotelName: null,
    stateProvince: "MV",
    type: null,
  },
  {
    cityName: "Mauritius",
    countryCode: "MU",
    countryName: "Mauritius",
    destinationId: "150281",
    hotelName: null,
    stateProvince: "MU",
    type: null,
  },

  {
    cityName: "Mykonos",
    countryCode: "GR",
    countryName: "Greece",
    destinationId: "146793",
    hotelName: null,
    stateProvince: "GR",
    type: null,
  },

  {
    cityName: "Naxos",
    countryCode: "GR",
    countryName: "Greece",
    destinationId: "151156",
    hotelName: null,
    stateProvince: "GR",
    type: null,
  },

  {
    cityName: "Paros",
    countryCode: "GR",
    countryName: "Greece",
    destinationId: "131411",
    hotelName: null,
    stateProvince: "GR",
    type: null,
  },

  {
    cityName: "Praslin Island",
    countryCode: "SC",
    countryName: "Seychelles",
    destinationId: "134315",
    hotelName: null,
    stateProvince: "SC",
    type: null,
  },

  {
    cityName: "Santorini",
    countryCode: "GR",
    countryName: "Greece",
    destinationId: "137186",
    hotelName: null,
    stateProvince: "GR",
    type: null,
  },




];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .htm-form-container": {
      display: "flex",
      gap: 20,
      [theme.breakpoints.down(780)]: {
        flexDirection: "column",
      },
    },
    "& .autocomplete": {
      padding: "0 !important"
    },
    "& .formContainer1": {
      display: "flex",
      width: "40%",
      //gap: 20,
      [theme.breakpoints.down(780)]: {
        width: "100%",
      },
      justifyContent: "space-between",
      "& label": {
        display: "block"
      },
      "& .htm-form-group": {
        width: "100%",
        '& label': {
          display: "block"
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          padding: 0,
          height: 43,
          [theme.breakpoints.down(960)]: {
            padding: 7
          }
        },
      },
    },
    "& .formContainer2": {
      display: "flex",
      gap: 20,
      width: "60%",
      [theme.breakpoints.down(780)]: {
        width: "100%",
      },
      justifyContent: "space-between",
      '& label': {
        display: "block"
      },
      "& .htm-form-group": {
        width: "32%",
      },
    },

    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      [theme.breakpoints.down(600)]: {
        borderBottom: `solid 1px ${theme.palette.primary.darkText} !important`,
      },
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      // borderBottom: `solid 2px ${theme.palette.secondary.main}`
      borderBottom: "none",
    },
    "& .react-datepicker": {
      display: "flex",
      [theme.breakpoints.down(992)]: {
        display: "inline-block"
      }
    },
    // "& .MuiOutlinedInput-root": {
    //     border: `solid 1px ${theme.palette.primary.lightGray}`,
    //     "&:hover,:focus": {
    //         border: `solid 1px ${theme.palette.secondary.main}`
    //     }
    // }
  },
  modifybutton: {
    marginTop: 25,
  },
  RoomModalOpenDev: {
    position: "absolute",
    top: "165px",
    right: 200,
    width: "303px",
    height: "100px",
    zIndex: 999,
  },
  paper: {
    border: "1px solid #b1b1b1",
    backgroundColor: theme.palette.background.light,
    borderRadius: "8px",
  },
}));

import React from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { useHistory } from "react-router-dom";
import { AppConstant } from "appConstant";

const TermsAndConditions = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomepage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%" }}
        justifyContent="center"
      >
        <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
          <a onClick={goToHomepage} className={classes.breadcrumbLink}>
            <i className="fa fa-home"></i> Home
          </a>
        </Grid>

        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h3"
                className={classes.sectionHeader}
                gutterBottom
              >
                TERMS & CONDITIONS:
              </Typography>
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The travel site www.mydonetrip.com, mobile site and Smartphone
                App Platforms like iOS and Android are maintained by Midas
                International compliant with the laws of the Republic of India.
                By accessing or using this site or using any of its services,
                you are agreed on terms and conditions mentioned below,
                including any supplementary guidelines and future modifications.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Mydonetrip may change, alter or remove any part of these below
                written Terms and Conditions without any prior notice. Any
                changes done in this Terms and Conditions of Use will be
                applicable the moment they are posted on the site. You need to
                re-check the “Terms & Conditions” link to be fully aware of the
                entire scenario.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Agreement between the Clients & Mydonetrip
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                While accessing, using, browsing or make booking through
                mydonetrip.com, users have to accept that they are agreed to the
                terms and conditions of our portal. In case of any violation,
                Midas International and its subsidiaries (including Mydonetrip)
                reserve all the rights for taking any legal actions against
                them.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Prices on the Website:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Service Charge, Cancellation Charge, Reschedule charge are
                inclusive of GST The price, which we offer on Mydonetrip, is
                generally includes accommodation charges, taxes (unless
                specified otherwise) and in special cases some meals (Breakfast
                /Lunch / Dinner). It never comprises any personal expense or
                other additional charges like telephone calls, personal-man
                services, entrance fees of any monuments and bar charges, etc.
                While Mydonetrip offers the lowest fares, the same is subject to
                the availability of seats, selection of specific sector, timing
                of booking and terms and conditions of the airline/third-party
                service provider/operator etc.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The price offered on {AppConstant.domainAlias} includes charges,
                taxes (unless mentioned otherwise) and in some cases meals
                (Breakfast/Lunch/Dinner). It doesn't comprise any personal
                expenses or additional charges like seat selection fees or
                addition of meals, etc.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Mode of Payments & the Policies:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Our payment gateway is completely secure and customers can make
                the online payments through a safe transaction process that
                ensures their personal security codes will not be revealed in
                any circumstances.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Mode of Payments Available at Easy Travels for Online Bookings
                are:
              </Typography>

              <ul className={classes.listText}>
                <li>Credit/ Debit Cards</li>
                <p>Visa, Master, Amex, Maestro & RuPay </p>
                <li>Net Banking</li>
                <p>All Major Banks suported</p>
                <li>Wallet</li>
                <p>MobKwik, PhonePe, AmazonPay & Others </p>
                <li>UPI</li>
                <li>EMI</li>
                <p>HSBC, RBL, ICICI and Other bank for EMI & Others </p>
                <li>Paypal</li>
                <p>Pay with PayPal </p>
                <li>ePayLater</li>
                <p>Travel Now, Pay Later </p>
              </ul>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Use of the Website:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                While your use of this website, you agree that:
              </Typography>

              <ul className={classes.listText}>
                <li>
                  You have the legal authority to create an obligatory legal
                  requirement and enter into these Terms of Use.
                </li>
                <li>You will use this site as per its Terms of Use</li>
                <li>
                  You will use the website only to make lawful bookings for you
                  or other person for whom you are officially approved to do
                  these kinds of bookings. You will also inform other persons
                  about the Terms and Condition of the website
                </li>
                <li>
                  You will provide accurate information about every official
                  document and other important details like name and DOB to the
                  site in case of planning trip with it
                </li>
                <li>
                  In case of any error or mistake in the process of information,
                  you will be solely responsible for the same.
                </li>
                <li>
                  All information provided by you to this site should be
                  accurate, updated and complete
                </li>
                <li>
                  If you have an account on this site, you are advised to secure
                  it with your login details, including login ID and password.
                  You will be entirely responsible for any use or misuse of your
                  account
                </li>
                <li>
                  {AppConstant.ccName} reserves the right to deny access to its
                  Website to anyone, at any time without any notice and for any
                  reason, including, but not restricted to, for the violation of
                  its Terms of Use
                </li>
              </ul>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Communication Policy of the Site:
              </Typography>

              <ul className={classes.listText}>
                <li>
                  On transacting with this site, you will receive an e-mail from
                  Mydonetrip for the status of your transaction. The e-mail will
                  be sent to the e-mail address provided by you and Mydonetrip
                  is not responsible for the receipt of the supposed e-mail in
                  your inbox. Be careful in providing right e-mail I’d.
                </li>
                <li>
                  The customer acknowledges that the SMS sent by Mydonetrip is
                  an added facility for the convenience of the customers. It is
                  not compulsory as per the law to provide SMS service alerts to
                  the customers. If you don’t receive any SMS for any reason,
                  Mydonetrip is not liable for the same.{" "}
                </li>
                <li>
                  Mydonetrip is not responsible to provide information about any
                  change in flight schedules, cancellation and status of airline
                  or hotel etc.
                </li>
              </ul>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Booking Policy:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The total price displayed on the site includes all applicable
                government taxes.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                In case of increase in the fare in the issuance of the Air
                ticket, due to airlines, miscalculation in amount due to server
                error and from the banks payment gateway issue then{" "}
                {AppConstant.ccName} reserves the right to cancel the ticket and
                forfeit the amount received as Cancellation Charges. The
                difference of amount should be paid to Easy Travels Ltd. after
                the communication from {AppConstant.ccName} to avoid any
                discomfort.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                You are required to pay the entire amount prior to the
                confirmation of your booking.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                To get any infant’s ticket issued, the age of infant must be
                below 2 years and make sure that they have valid proof-of-age
                documents when they are checking in, and remember that the
                infant must be accompanied by an adult at least 18 years old.
                You can book no more than one infant per adult. To avail infant
                fares; the infant must be under 24 months of age throughout the
                entire itinerary that you’re booking. If the age of infant is 24
                months or above then you need to book a separate booking as a
                child.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                To get any child ticket issued, the age of child must in between
                2-12 years and make sure that they have valid proof-of-age
                documents when they are checking in, and remember that the child
                must be accompanied by an adult at least 18 years old.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                All the tickets/bookings issued to the customer shall
                additionally be governed under the terms and conditions as laid
                out by the respective Airlines/Supplier.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                If ticket is cancelled directly from the airlines web site ,
                office or call centre , customer needs to inform Mydonetrip for
                the refund to be processed. Please note that Mydonetrip do not
                get any alert from airlines directly.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The amenities, services, routes, schedule, aircraft type, seat
                availability and any other details pertaining to the flight
                service are provided by the respective airlines and Mydonetrip
                has no control over such information provided by the airlines.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Flight Amendment & Cancellation Policy:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Every booking made on {AppConstant.ccName} is subject to
                cancellation charges levied by the airline, which may vary with
                respect to flight and booking class.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                {AppConstant.domainAlias} levies negligible amount of{" "}
                <span className="highlight">Rs.</span> per passenger/per sector
                for domestic and <span className="highlight">Rs.</span> per
                passenger/per sector for international air tickets as
                cancellation service charges.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                <span className="highlight">
                  Zero Cancellation-Fee product premium charged at the time of
                  booking is non-refundable.
                </span>
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Convenience fees are non-refundable in all cases.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Some bookings may be non-refundable as per the specific
                airline’s policy.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Some bookings cannot be cancelled partially as per the specific
                airline’s policy.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Cancellations can be made online or offline through My Booking
                section or by sending an email to {AppConstant.ccName}.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                We assist you in making various amendments for bookings. In few
                cases, you need to contact the airline directly.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Amendment charges on {AppConstant.domainName} are subject to
                change as per the policy of the airline, which varies due to
                flight timings and booking class.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                To cancel the tickets within 24 Hrs of the journey,{" "}
                {AppConstant.ccName} recommends customer to contact directly to
                the Airlines and after that, send the cancellation request on
                our website for refunds
              </Typography>

              <Gap10 />
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Company is not responsible for any delay or cancellation or
                amendment of flights from airline’s end.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                <span className="highlight">
                  In case of no-show or unutilized bookings, the customers are
                  required to make requests for any valid refunds, as per the
                  defined policies, within 90 days from the travel date in case
                  of air/bus tickets. No refund would be payable for requests
                  raised after the expiry of 90 days of travel date/ check-in as
                  aforementioned and all unclaimed amounts for such no-show or
                  unutilized bookings shall accordingly be deemed to have been
                  forfeited.
                </span>
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                For cancellation requests received, the refund shall be
                processed at the earliest once request is received. In case of
                direct cancellation through airlines, refunds shall be processed
                post deduction of airline charges along with our service charge.
                All refunds shall be processed subject to processing of refunds
                by the respective airline/service provider. Please note
                convenience fee charged at the time of booking is not
                refundable.
              </Typography>
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                In case of international bookings if the customer is marked as
                No show for onward sector then the customer will be considered
                No show for the return sector as well.(Issued under Single PNR).
                In such scenarios customers need to connect the respective
                Airline directly before No show.
              </Typography>
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The tickets issued by Mydonetrip shall be valid for a period of
                1 year from the date of issuance of the ticket or such other
                period as may be prescribed by the concerned airline as per its
                policy.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Holidays Amendment & Cancellation Policy
              </Typography>

              <ul className={classes.listText}>
                <li>
                  For cancellations before 60 Days or more of the date of
                  departure, 25% of the tour cost (if full paid) will be charged
                  as cancellation charges.
                </li>
                <li>
                  For cancellations before 30 Days of departure, 50% of the tour
                  cost will be charged as cancellation charges.
                </li>
                <li>
                  For cancellations before 15 Days of departure, 100% of the
                  tour cost will be charged as cancellation charges.
                </li>
              </ul>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Communication Policy of the Site:
              </Typography>

              <ul className={classes.listText}>
                <li>
                  On transacting with {AppConstant.domainName}, you will receive
                  an e-mail from {AppConstant.ccName} for the status of your
                  transaction. The e-mail will be sent to the e-mail address
                  provided by you and {AppConstant.ccName} is not responsible
                  for the receipt of the supposed e-mail in your inbox. Be
                  careful in providing right e-mail ID
                </li>
                <li>
                  The customer acknowledges that the SMS sent by{" "}
                  {AppConstant.ccName} is an added facility for the convenience
                  of the customers. It is not compulsory as per the law to
                  provide SMS service alerts to the customers. If you don’t
                  receive any SMS for any reason, {AppConstant.ccName} is not
                  liable for the same
                </li>
                <li>
                  EaseMyTrip is not responsible to provide information about any
                  change in flight schedules, cancellation and status of airline
                  or hotel etc
                </li>
              </ul>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Refunds in cases where Airlines is declared as Insolvent or
                Bankrupt:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                {AppConstant.ccName} is not be liable to pay any refunds
                whatsoever in cases where the Airlines stops its operations or
                declares itself as insolvent.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The customers or clients or agents shall not hold the{" "}
                {AppConstant.ccName} liable to pay the refunds as assured at the
                time of booking of ticket in cases where the Airlines stops its
                operation or declares itself as insolvent
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                On the basis of assurity given by airlines/hotels/suppliers,
                Mydonetrip may sometimes refund amount to the customer but
                Mydonetrip reserves the right to recover the refunded amount on
                event of Airlines/hotels getting shut
                down/non-operational/bankrupt.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Refund Policy:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                {AppConstant.ccName} will process the refund only after getting
                cancellation request/claim from the customer/travel
                agent/corporate company through mybooking section or email.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                <span className="highlight">
                  Refund against the cancellation of air tickets will be
                  credited directly in customer’s mode of payment after
                  receiving the refund amount from the Airlines
                </span>
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                {AppConstant.ccName} will process refund within{" "}
                <span clasName="highlight">72 hours</span> only after receiving
                it from the concerned Airlines/bus service.
              </Typography>

              <Gap10 />
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                In case of the booking being more than Six months old, the
                refund, if any, received from the airlines may be transferred to
                the customer's wallet created on www.mydonetrip.com. The
                customer can transfer the said refund amount to the Passenger's
                bank account from the wallet by logging in to the website of
                Mydonetrip at www.mydonetrip.com.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Due to Banking Procedures, Refund in credit cards may take
                duration of <span clasName="highlight">72 hours</span> while it
                may take{" "}
                <span clasName="highlight">
                  7 working days in case of net banking transactions
                </span>
                .
              </Typography>
              <Gap10 />
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Wallet amount once used for any transaction the remaining amount
                in the wallet cannot be transferred to your bank account.
                However, can be used for future booking with us.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Copyright & Trademark:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Products, services and contents displaying on Mydonetrip are the
                properties of this website. Any unauthorized copy & use of the
                trademark product & services without prior concern will be taken
                as larceny and strict actions may be taken against the concern
                person/organization/company.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                <span className="highlight">
                  Mydonetrip gives you a limited right to enter, explore, use
                  and transact on its site. You agree not to misuse its
                  materials and interrupt the operation of this Site in any way.
                  You understand that except for information, products or
                  services supplied by Mydonetrip do not endorse any other
                  information in anyway. You also understand that Mydonetrip
                  does not assure that files available for downloading through
                  the site will be free from any type of viruses.
                </span>
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Availability, Rules of Service:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Many products and services displayed on the Site may not be
                available for booking in your location or country. So, the
                reference to any such products and services on the site does not
                imply in your particular geographical location. Products and
                services also are subject to availability and the company is not
                responsible if any product is unavailable or sold out.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                <span className="highlight">
                  Each product and service offered by the website is governed by
                  the rules and regulations of the respective service provider.
                  You are requested to refer to their rules and regulations as
                  well, before booking with us.
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Visa Guidelines:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Please carry applicable visa for the respective country you are
                visiting or transiting through. Please check-out the concerned
                airline and embassy for concerned Visa requirements.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                International bookings made through {AppConstant.ccName} are
                subject to the requirements of visa including but not limited to
                transit visa which is to be obtained by the User as per the
                requirement of their travel bookings and the requirements of the
                countries the traveller intends to visit or transit through
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                {AppConstant.ccName} is not responsible for any issues,
                including inability to travel, arising out of such visa
                requirements and is also not liable to refund any amount to the
                User for being unable to utilize the booking due to absence or
                denial of visa. Refund, if any, will be as per the applicable
                terms of booking and cancellation policy.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Transmitted Material
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Internet communications can’t be completely private or secure.
                You understand that any message or information that you have
                sent to this site can be intercepted by others unless there is a
                special notice (for example, credit card information) that is
                encrypted. Sending a message to Mydonetrip does not cause the
                site to have any special responsibility.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                The copyright in the contents of this website belongs to
                Mydonetrip. Copying any part or all the contents of this website
                without permission of Mydonetrip is not allowed except to the
                extent that such copying or printing is essential for the
                purpose of availing the paid services offered.
              </Typography>

              <Gap10 />
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Offers, Contests and Interactions:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                This site may run contests that may need you to send the
                materials or information about yourself or to offer prizes. All
                the offers and contests running on this website have its own
                rules, which you need to read before getting agreed for
                participation.
              </Typography>

              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Disclaimer and No Warranties
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                You acknowledge that Mydonetrip is a conciliator, which is not
                accountable for any 3rd party obligations due to rates, quality,
                and all other instances. You particularly agree that use of the
                services and the site is at your solitary risk. It is your duty
                to assess the exactness, comprehensiveness and expediency of all
                advice, opinions, services, merchandise and other information
                provided on the site. We don’t assure that the service will be
                uninterrupted or error-free.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Mydonetrip can make improvements and/or changes on its website
                at any point of time. Mydonetrip and its third party suppliers,
                licensors, and partners do not guarantee or make any
                representations about the use or the results of the use of the
                services, the site or any reference sites in terms of
                correctness, accuracy, reliability, or otherwise. Mydonetrip can
                make improvements and/or changes on its website at any point of
                time. Mydonetrip and its third party suppliers, licensors, and
                partners do not guarantee or make any representations about the
                use or the results of the use of the services, the site or any
                reference sites in terms of correctness, accuracy, reliability,
                or otherwise.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                You acknowledge that uninterrupted access or use of this website
                can be prevented by certain factors that are not in control
                including, unavailability of products & services, inoperability
                or interruption of the Internet or other telecommunications
                services or certain technical work carried out on this Website.
                Mydonetrip (Midas International) will not be answerable for any
                loss or damage whatsoever arising out of or in connection with
                any ability/inability while using the site.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Hikes in Tariffs & Taxes :
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                We inform you on prior basis that after the finalization of the
                tour package rates, for any hike in entrance fees, fuel cost,
                guide charges, and other services, you can be charged as extra.
              </Typography>
              <Gap10 />
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                If government revises some taxes on travel services, clients are
                liable to pay that extra amount.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                General Terms:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Mydonetrip, as being a reputed and responsible travel agency,
                provides you the best possible information about the hotels,
                airlines, transportation, and railways. However, it is advised
                to all the clients to make a personal inquiry to avoid any
                confusion & mess.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Mydonetrip is not responsible for any loss or breakage of
                luggage during the travel.
              </Typography>

              <Gap10 />

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                We are not responsible for any delay & alteration in your trip
                due to unavoidable circumstances, which can be anything.
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                We are not responsible for any damage, loss or injury made
                during enjoying the holiday packages provided by us. You are
                accountable for your own safety measures.
              </Typography>
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                We are also not accountable for any human error that gone
                unnoticed by the clients and our staffs.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Credit Policy for Travel Agents:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                If any travel agent avails services from the company using
                credit from the company and fails to clear the credit in certain
                days, Mydonetrip can take legal action against him. In such
                cases, they are charged some interest rates that can be decided
                by the company itself. Firm lawful steps can be taken, if any
                cheating is found by the travel agent.
              </Typography>

              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Unaccompanied Child
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Children below the age of 12 will not be accepted for carriage
                unless they are accompanied by a person of at least 18 years of
                age. Such child/children must be seated next to the accompanying
                adult. The accompanying adult is solely responsible for the
                well-being of the child/children traveling together with
                him/her. This also includes ensuring that seats are booked to
                ensure child/children and an accompanying adult are seated
                together.
              </Typography>

              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Airline Pregnancy Rules:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                All airlines recommend the pregnant ladies to consult a doctor
                before traveling at any point during their pregnancy. We request
                all pregnant ladies to read pregnancy rules of the respective
                airlines before planning their trip. Some important points that
                need to be followed are:
              </Typography>
              <ul className={classes.listText}>
                <li>
                  Take a medical clearance from your healthcare provider or
                  doctor not more than 7 days before your journey stating that
                  you are fit to travel.{" "}
                </li>
                <li>
                  You should avoid taking flights if your pregnancy is
                  complicated and risky in nature.{" "}
                </li>
                <li>
                  You should take your due date into consideration for return
                  trip
                </li>
                <li>
                  If you are travelling on long-haul flights, identify if your
                  body allows you to take a hectic flight journey as the
                  seat-width may be uncomfortable in case of heavy pregnancy.{" "}
                </li>
              </ul>
              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                For detailed information about the same, please contact the
                airline you are travelling with.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Flight Cancellation Coverage:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Customers under this policy can claim the refund in case of
                flight cancellation due to medical reasons/carrier delay.
              </Typography>
              <ul className={classes.listText}>
                <li>
                  Read complete details on free flight cancellation coverage on
                  airline website{" "}
                </li>
                <li>
                  One can check out the details for this policy on airline
                  website{" "}
                </li>
              </ul>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Flight Cancellation Coverage:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Customers under this policy can claim the refund in case of
                flight cancellation due to medical reasons/carrier delay.
              </Typography>
              <ul className={classes.listText}>
                <li>
                  Read complete details on free flight cancellation coverage on
                  airline website{" "}
                </li>
                <li>
                  One can check out the details for this policy on airline
                  website{" "}
                </li>
              </ul>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                No Convenience Fee:
              </Typography>

              <Typography
                variant="span"
                gutterBottom
                className={classes.sectionText}
              >
                Customers under this policy can claim the refund in case of
                flight cancellation due to medical reasons/carrier delay.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={classes.sectionTextGrid}
          style={{ textAlign: "center" }}
        >
          <a onClick={goToHomepage} className={classes.breadcrumbLink}>
            <i className="fa fa-home"></i> Home
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& a": {
      cursor: "pointer",
    },
    "& .highlight": {
      color: COLORS.HIGHLIGHT_COLOR_OR,
    },
  },

  Grid: {
    background: COLORS.PRIMARY_BG_LIGHT,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  // firstCard: {
  //     "& .MuiTypography-root": {
  //         color: COLORS.PRIMARY_BG_DARK,
  //     },
  // },
  sectionHeader: {
    color: `${COLORS.DEFAULT_TEXT_BG} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${COLORS.DEFAULT_TEXT_DARK} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${COLORS.LIGHT_GRAY_TEXT_QUA} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: COLORS.LIGHT_GRAY_TEXT_QUA,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${COLORS.DEFAULT_TEXT_ACTIVE} !important`,
    "&:hover": {
      color: `${COLORS.HIGHLIGHT_COLOR_SECONDARY} !important`,
    },
  },
}));

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

import React from "react";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.DoneTrip";
import PageFooter from "components/Footer/PageFooter.DoneTrip";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import ScrollToTopComp from "components/Footer/ScrollToTopComp";
import WaFloatingButton from "components/Footer/WaFloatingButton";
import LandingPage from "pages/corporate/LandingPage";
import PostLoginLandingPage from "pages/corporate/PostLoginLandingPage";

const useStyles = makeStyles(styles);

const CorporatePages = (props) => {

    const { ...rest } = props;
    const theme = useTheme();
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <PostLoginNavBar
                {...rest}
                isSearchPage={true}
                showServices={false}
                showBrand={true}
            />

            <Switch>
                <Route path="/corporate/signup" render={
                        prop => <LandingPage {...props} />
                    }
                />
                <Route path="/corporate/landingpage" render={
                        prop => <PostLoginLandingPage {...props} />
                    }
                />
                <Route render={
                        prop => <LandingPage {...props} />
                    }
                />
            </Switch>

            <PageFooter {...props} />

            <WaFloatingButton />
            <ScrollToTopComp />
        </div>
    );
}

export default CorporatePages;
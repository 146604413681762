import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
} from "@material-ui/core";
import FlightSearchHeader from "./FlightSearchHeader";
import FlightSearchHeaderMultiCity from "./FlightSearchHeaderMultiCity";
import FlightSearchFooter from "./FlightSearchFooter";
import FilterDrawer from "./FilterDrawer";
import FlightSearchFilter from "./flightSearchFilter";
import MultiCitySection from "./MultiCitySection";
import NonMUIFlightCard from "./NonMUIFlightCard";
import OaLoading from "pages/components/OaLoading";
import dateFnsFormat from "date-fns/format";
import PlaceholderView from "./view/PlaceholderView";
import LazyLoad from "react-lazyload";
// import NonMUIFlightCard from "./NonMUIFlightCard";
import modify from "assets/icons/modify.png";
import RoundTripFlightCard from "./RoundTripFlightCard";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import FlightSearchSort from "./FlightSearchSort";
import clsx from "clsx";
import { ToggleButton } from "@material-ui/lab";
import { ErrorOutlineRounded } from "@material-ui/icons";

const width = window.innerWidth;
const breakpoint = 980;

const FlightSearch = (props) => {
  const history = useHistory();
  const useStyles = getStyles(history.location.state?.request?.mode);
  const classes = useStyles();
  const theme = useTheme();
  const isMdDevice = useMediaQuery(theme.breakpoints.between(991, 1500));
  const isSmDevice = useMediaQuery(theme.breakpoints.down(992));
  let intitvals = history.location.state.requestObj;

  const [isModifying, setIsModifying] = React.useState(false);
  const [responseDepart, setResponseDepart] = React.useState(null);
  const [responseReturn, setResponseReturn] = React.useState(null);
  const [isOnwardSelected, setIsOnwardSelected] = React.useState(false);
  const [oneFlightSelectedData, setOneFlightSelectedData] = React.useState(
    null
  );
  const [oneSelectedFareId, setOneSelectedFareId] = React.useState(null);
  const [retFlightSelectedData, setRetFlightSelectedData] = React.useState(
    null
  );
  const [retSelectedFareId, setRetSelectedFareId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFlightBookLoading, setIsFlightBookLoading] = React.useState(false);
  const [showNetFare, setShowNetFare] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("tab1");
  const [modifiedFilter, setModifiedFilter] = React.useState(false);

  const propState = history.location.state;
  const tripMode = propState?.request?.mode;
  const requestObj = propState?.requestObj;

  const depAirportCode =
    requestObj?.sectors && requestObj?.sectors[0]?.originObj.airportCode;
  const depDateRaw =
    requestObj?.sectors && new Date(requestObj?.sectors[0].departDate);
  const departDate =
    requestObj?.sectors && dateFnsFormat(depDateRaw, "dd MMM yyyy");

  let arrAirportCode =
    requestObj?.sectors && requestObj?.sectors[0]?.destinationObj.airportCode;
  let arrDateRaw =
    requestObj && tripMode == "ROUND" && new Date(requestObj?.returnDate);
  let arrDate =
    requestObj &&
    tripMode == "ROUND" &&
    dateFnsFormat(arrDateRaw, "dd MMM yyyy");

  useEffect(() => {
    window.sessionStorage.removeItem("fetching");
    console.log("flight search results rendered at", 
      new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+
      new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()+":"+new Date().getMilliseconds()
    );
  }, []);

  const LoaderView = () => {
    return (
      <div className="loader-view" style={{zIndex: 200}} >
      {/* <OaLoading loadertext="please wait ..." /> */}
      <div style={{backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", padding: 10, borderRadius: 20}}>
        <img style={{ height: 140, width: 200}} src={props.availoader} />
        <span style={{color: "black", textAlign: "center", fontWeight: 500}} >Please Wait</span>
        {/* <span style={{color: "black", textAlign: "center", fontWeight: 500}} >Processing Your Request</span> */}
      </div>
      <div>
       
      </div>
    </div>
    );
  };

  const FlightBookLoaderView = () => {
    return (
       <div className="loader-view" style={{zIndex: 200}} >
      {/* <OaLoading loadertext="please wait ..." /> */}
      <div style={{backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", padding: 10, borderRadius: 20}}>
        <img style={{ height: 140, width: 200}} src={props.availoader} />
        <span style={{color: "black", textAlign: "center", fontWeight: 500}} >Please Wait</span>
        {/* <span style={{color: "black", textAlign: "center", fontWeight: 500}} >Processing Your Request</span> */}
      </div>
      <div>
       
      </div>
    </div>
    );
  };

  const NoResultView = () => {
    return (
      <Grid container direction="row" className={classes.noResultContainer}>
        <Grid item className={classes.noResults}>
          <ErrorOutlineRounded />
          <Typography>No Results found</Typography>
        </Grid>
      </Grid>
    );
  };

  const cancelModify = (isCancelModify) => {
    if (isCancelModify) {
      setIsModifying(false);
    }
  };

  const toggleFlightSelectOne = (value, data) => {
    setIsOnwardSelected(true);
    setOneFlightSelectedData(data);
    setOneSelectedFareId(value);
  };

  const toggleFlightSelectRet = (value, data) => {
    setRetFlightSelectedData(data);
    setRetSelectedFareId(value);
  };

  const handleChangeTab1 = () => {
    setCurrentTab("tab1");
  };
  const handleChangeTab2 = () => {
    setCurrentTab("tab2");
  };

  const Tab1View = () => {
    return (
      <Grid item xs={tripMode && tripMode === "ROUND" ? 12 : 12}>
        <div className={classes.searchResultColumn}>
          {responseDepart && responseDepart.length > 0 ? (
            responseDepart.map((value, index) => (
              <LazyLoad
                once={true}
                key={index}
                height={200}
                offset={[100, 0]}
                placeholder={<PlaceholderView />}
                debounce={500}
                unmountIfInvisible={true}
              >
                {propState.request?.mode === "ONE" && (
                  // <div key={index}>
                  <NonMUIFlightCard
                    fullWidth={true}
                    selected={oneSelectedFareId}
                    flightData={value}
                    getSelectedFlight={(value, data) =>
                      toggleFlightSelectOne(value, data)
                    }
                    tripMode={propState.request?.mode}
                    setIsFlightBookLoading={setIsFlightBookLoading}
                    request={propState?.requestObj}
                    onwfareid={oneSelectedFareId}
                    isdepart={true}
                    isloading={(flag) => setIsLoading(flag)}
                    // isFlightBookloading={(flag) => setIsFlightBookLoading(flag)}
                    sessionid={propState?.sessionId}
                    showNetFare={showNetFare}
                    {...props}
                  />
                  // </div>
                )}
                {propState.request?.mode === "ROUND" && (
                  <div key={index}>
                    <RoundTripFlightCard
                      index={index}
                      fullWidth={true}
                      selected={oneSelectedFareId}
                      flightData={value}
                      setIsFlightBookLoading={setIsFlightBookLoading}
                      getSelectedFlight={(value, data) =>
                        toggleFlightSelectOne(value, data)
                      }
                      tripMode={propState.request?.mode}
                      request={propState?.requestObj}
                      onwfareid={oneSelectedFareId}
                      isdepart={true}
                      isloading={(flag) => setIsLoading(flag)}
                      sessionid={propState?.sessionId}
                      showNetFare={showNetFare}
                      {...props}
                    />
                  </div>
                )}
              </LazyLoad>
            ))
          ) : (
            <NoResultView />
          )}
        </div>
      </Grid>
    );
  };
  const Tab2View = () => {
    return (
      <Grid item xs={propState.request?.mode === "ROUND" ? 12 : 12}>
        <div className={classes.searchResultColumn}>
          {responseReturn && responseReturn.length > 0 ? (
            responseReturn.map((value, index) => (
              <LazyLoad
                once={true}
                key={index}
                height={200}
                offset={[100, 0]}
                placeholder={<PlaceholderView />}
                debounce={500}
                unmountIfInvisible={true}
              >
                {propState.request?.mode === "ONE" && (
                  <div key={index}>
                    <NonMUIFlightCard
                      fullWidth={true}
                      selected={oneSelectedFareId}
                      flightData={value}
                      getSelectedFlight={(value, data) =>
                        toggleFlightSelectOne(value, data)
                      }
                      setIsFlightBookLoading={setIsFlightBookLoading}
                      request={propState?.requestObj}
                      onwfareid={oneSelectedFareId}
                      isdepart={true}
                      isloading={(flag) => setIsLoading(flag)}
                      sessionid={propState?.sessionId}
                      showNetFare={showNetFare}
                      {...props}
                    />
                  </div>
                )}
                {propState.request?.mode === "ROUND" && (
                  // <div key={index}>
                  <RoundTripFlightCard
                    index={index}
                    fullWidth={true}
                    setIsFlightBookLoading={setIsFlightBookLoading}
                    selected={retSelectedFareId}
                    flightData={value}
                    getSelectedFlight={(value, data) =>
                      toggleFlightSelectRet(value, data)
                    }
                    tripMode={propState.request?.mode}
                    request={propState?.requestObj}
                    onwfareid={oneSelectedFareId}
                    isdepart={false}
                    isloading={(flag) => setIsLoading(flag)}
                    sessionid={propState?.sessionId}
                    showNetFare={showNetFare}
                    {...props}
                  />
                  // </div>
                )}
              </LazyLoad>
            ))
          ) : (
            <NoResultView />
          )}
        </div>
      </Grid>
    );
  };

  const onChangeDepart = (modifiedResponseDep) => {
    setResponseDepart(modifiedResponseDep);
  };
  const onChangeReturn = (modifiedResponseDep) => {
    setResponseReturn(modifiedResponseDep);
  };

  const toggleShowNetFare = (flag) => {
    setShowNetFare(flag);
  };

  React.useEffect(() => {
    if (isSmDevice) {
      Tab1View();
      Tab2View();
    }
  }, [currentTab, propState]);

  React.useEffect(() => {
    setResponseDepart(propState.onwardResp);

    if (
      propState?.request?.mode === "ROUND" &&
      propState?.returnResp !== null
    ) {
      setResponseReturn(propState.returnResp);
    }
  }, []);
  // console.log("history?.location?.state?",history?.location?.state)
  // console.log("props",props)
  const toggleNetFareDisplay = () => {
    toggleShowNetFare(!showNetFare);
  };

  const isResults =
    tripMode === "Round"
      ? propState.onwardResp?.length > 0 && propState.returnResp?.length > 0
      : propState.onwardResp?.length > 0;

  return isResults ? (
    <div className={classes.root}>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/eb67f5d49a.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      {propState.request?.mode === "MULTI-CITY" ? (
        <FlightSearchHeaderMultiCity
          isModifyingVal={isModifying}
          isCancelModify={(val) => cancelModify(val)}
          showNetFare={showNetFare}
          toggleShowNetFare={toggleShowNetFare}
          tripMode={tripMode}
          {...props}
        />
      ) : (
        <FlightSearchHeader
          isModifyingVal={isModifying}
          isCancelModify={(val) => cancelModify(val)}
          showNetFare={showNetFare}
          toggleShowNetFare={toggleShowNetFare}
          tripMode={tripMode}
          isDomestic={requestObj?.isDomestic}
          {...props}
        />
      )}

      <div style={{ margin: "6px 0" }}>
        <Grid
          container
          direction="row"
          className={classes.searchResultContainer}
        >
          {/* flight result filter component for desktop starts here */}
          <Grid item className={classes.FilterGridItem}>
            {history?.location?.state?.onwardResp && (
              <FlightSearchFilter
                tripMode={propState.request?.mode}
                onwdata={history?.location?.state?.onwardResp}
                retdata={history?.location?.state?.returnResp}
                setModifiedResponseDepart={onChangeDepart}
                setModifiedResponseReturn={onChangeReturn}
                setModifiedFilter
                modifiedFilter
                filters={history?.location?.state?.filters}
                showNetFare={showNetFare}
                setIsLoading={setIsLoading}
              />
            )}
          </Grid>
          {/* flight result filter component for desktop ends here */}

          <Grid item className="FilterDrawer-grid">
            {/* flight result filter drawer and modify (responsive) toggle component for desktop starts here */}
            <div className={classes.FilterDrawer}>
              {history?.location?.state?.onwardResp && (
                <FilterDrawer
                  tripMode={propState.request?.mode}
                  onwdata={history?.location?.state?.onwardResp}
                  retdata={history?.location?.state?.returnResp}
                  setModifiedResponseDepart={onChangeDepart}
                  setModifiedResponseReturn={onChangeReturn}
                  filters={history?.location?.state?.filters}
                  showNetFare={showNetFare}
                  setIsLoading={setIsLoading}
                />
              )}

              <div
                className={classes.modifyButton}
                onClick={() => setIsModifying(!isModifying)}
              >
                <div className={classes.flightSearchCTA}>
                  <span>{isModifying ? "x" : <img src={modify} />}</span>
                </div>
              </div>
            </div>
            {/* flight result filter drawer and modify (responsive) toggle component for desktop ends here */}

            {/* multicity section starts here */}
            {propState.request?.mode === "MULTI-CITY" ? (
              <MultiCitySection />
            ) : (
              ""
            )}
            {/* multicity section ends here */}

            {/* tab buttons for mobile view starts here */}
            {(propState.request?.mode === "ROUND" ||
              propState.request?.mode === "ROUND-SP") && (
              <ul className={classes.tabNav}>
                <li
                  onClick={handleChangeTab1}
                  className={currentTab === "tab1" ? "activeTab" : ""}
                >
                  {depAirportCode}{" "}
                  <i className="fas fa-long-arrow-alt-right"></i>
                  {arrAirportCode}
                  <br />
                  <span>{departDate}</span>
                </li>
                <li
                  className={currentTab === "tab2" ? "activeTab" : ""}
                  onClick={handleChangeTab2}
                >
                  {arrAirportCode}{" "}
                  <i className="fas fa-long-arrow-alt-right"></i>
                  {depAirportCode}
                  <br />
                  <span>{arrDate}</span>
                </li>
              </ul>
            )}
            {/* tab buttons for mobile view ends here */}

            {/* show hide net fare button for b2b starts here */}
            {width > breakpoint && intitvals.partnerId === 1002 && (
              <Grid
                item
                className={clsx(classes.flightSearchElement, "horz-flex")}
                md={12}
                xs={12}
              >
                <ToggleButton
                  value={true}
                  selected={showNetFare}
                  onChange={toggleNetFareDisplay}
                  title={showNetFare ? "hide net fare" : "show net fare"}
                  className={"tnf-button " + (showNetFare ? "active" : "")}
                >
                  {showNetFare ? "HNF" : "SNF"}
                </ToggleButton>
              </Grid>
            )}
            {/* show hide net fare button for b2b ends here */}

            {/* result cards for desktop starts here */}
            {width > breakpoint && (
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={propState.request?.mode === "ROUND" ? 6 : 12}
                  className="sort-container"
                >
                  <FlightSearchSort
                    tripMode={propState.request?.mode}
                    index={1}
                    // data={history?.location?.state?.onwardResp}
                    data={responseDepart}
                    setModifiedResponse={onChangeDepart}
                    filters={history?.location?.state?.filters}
                    showNetFare={showNetFare}
                    setIsLoading={setIsLoading}
                    type="onward"
                    {...props}
                  />
                  <div className={classes.searchResultColumn}>
                    {responseDepart && responseDepart.length > 0 ? (
                      responseDepart.map((value, index) => (
                        <div key={index}>
                          {propState.request?.mode === "ONE" ? (
                            <NonMUIFlightCard
                              buttonIndex={index}
                              fullWidth={true}
                              selected={oneSelectedFareId}
                              flightData={value}
                              getSelectedFlight={(value, data) =>
                                toggleFlightSelectOne(value, data)
                              }
                              modifiedFilter
                              setIsFlightBookLoading={setIsFlightBookLoading}
                              tripMode={propState.request?.mode}
                              request={propState?.requestObj}
                              onwfareid={oneSelectedFareId}
                              isdepart={true}
                              isloading={(flag) => setIsLoading(flag)}
                              sessionid={propState?.sessionId}
                              showNetFare={showNetFare}
                              {...props}
                            />
                          ) : (
                            <LazyLoad
                              once={true}
                              key={index}
                              height={200}
                              offset={[100, 0]}
                              placeholder={<PlaceholderView />}
                              debounce={500}
                              // unmountIfInvisible={true}
                            >
                              <RoundTripFlightCard
                                index={index}
                                fullWidth={true}
                                selected={oneSelectedFareId}
                                setIsFlightBookLoading={setIsFlightBookLoading}
                                flightData={value}
                                getSelectedFlight={(value, data) =>
                                  toggleFlightSelectOne(value, data)
                                }
                                tripMode={propState.request?.mode}
                                request={propState?.requestObj}
                                onwfareid={oneSelectedFareId}
                                isdepart={true}
                                isloading={(flag) => setIsLoading(flag)}
                                sessionid={propState?.sessionId}
                                showNetFare={showNetFare}
                                type={"onward"}
                                {...props}
                              />
                            </LazyLoad>
                          )}
                          {/* <NonMUIFlightCard
                              fullWidth={true}
                              selected={oneSelectedFareId}
                              flightData={value}
                              getSelectedFlight={(value, data) =>
                                toggleFlightSelectOne(value, data)
                              }
                              tripMode={tripMode}
                              request={propState?.request}
                              onwfareid={oneSelectedFareId}
                              isdepart={true}
                              isloading={(flag) => setIsLoading(flag)}
                              sessionid={propState?.sessionId}
                              {...props}
                            /> */}
                        </div>
                      ))
                    ) : (
                      <NoResultView />
                    )}
                  </div>
                </Grid>
                {(propState.request?.mode === "ROUND" ||
                  propState.request?.mode === "ROUND-SP") && (
                  <Grid item md={6} className="sort-container">
                    <FlightSearchSort
                      index={2}
                      tripMode={propState.request?.mode}
                      // data={history?.location?.state?.returnResp}
                      data={responseReturn}
                      setModifiedResponse={onChangeReturn}
                      filters={history?.location?.state?.filters}
                      showNetFare={showNetFare}
                      setIsLoading={setIsLoading}
                      type="return"
                      {...props}
                    />
                    <div className={classes.searchResultColumn}>
                      {responseReturn && responseReturn.length > 0 ? (
                        responseReturn.map((value, index) => (
                          <LazyLoad
                            once={true}
                            key={index}
                            height={200}
                            offset={[100, 0]}
                            placeholder={<PlaceholderView />}
                            debounce={500}
                            // unmountIfInvisible={true}
                          >
                            <RoundTripFlightCard
                              index={index}
                              fullWidth={true}
                              selected={retSelectedFareId}
                              setIsFlightBookLoading={setIsFlightBookLoading}
                              flightData={value}
                              getSelectedFlight={(value, data) =>
                                toggleFlightSelectRet(value, data)
                              }
                              tripMode={propState.request?.mode}
                              request={propState?.requestObj}
                              onwfareid={oneSelectedFareId}
                              isdepart={false}
                              isloading={(flag) => setIsLoading(flag)}
                              showNetFare={showNetFare}
                              sessionid={propState?.sessionId}
                              type={"return"}
                              {...props}
                            />
                          </LazyLoad>
                        ))
                      ) : (
                        <NoResultView />
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            {/* result cards for desktop ends here */}

            {/*width > breakpoint && (
              <Grid container justifyContent="center">
                <Grid item md={tripMode && tripMode === "ROUND" ? 6 : 12}>
                  <div className={classes.searchResultColumn}>
                    {responseDepart &&
                      responseDepart.map((value, index) => (
                        <LazyLoad
                          once={true}
                          key={index}
                          height={200}
                          offset={[100, 0]}
                          placeholder={<PlaceholderView />}
                          debounce={500}
                          // unmountIfInvisible={true}
                        >
                          <div key={index}>
                            {tripMode === "ONE" ? (
                              <NonMUIFlightCard
                                buttonIndex={index}
                                fullWidth={true}
                                selected={oneSelectedFareId}
                                flightData={value}
                                getSelectedFlight={(value, data) =>
                                  toggleFlightSelectOne(value, data)
                                }
                                tripMode={tripMode}
                                request={propState?.requestObj}
                                onwfareid={oneSelectedFareId}
                                isdepart={true}
                                isloading={(flag) => setIsLoading(flag)}
                                sessionid={propState?.sessionId}
                                showNetFare={showNetFare}
                                {...props}
                              />
                            ) : (
                              <RoundTripFlightCard
                                index={index}
                                fullWidth={true}
                                selected={oneSelectedFareId}
                                flightData={value}
                                getSelectedFlight={(value, data) =>
                                  toggleFlightSelectOne(value, data)
                                }
                                tripMode={tripMode}
                                request={propState?.requestObj}
                                onwfareid={oneSelectedFareId}
                                isdepart={true}
                                isloading={(flag) => setIsLoading(flag)}
                                sessionid={propState?.sessionId}
                                showNetFare={showNetFare}
                                {...props}
                              />
                            )}
                            {/* <NonMUIFlightCard
                              fullWidth={true}
                              selected={oneSelectedFareId}
                              flightData={value}
                              getSelectedFlight={(value, data) =>
                                toggleFlightSelectOne(value, data)
                              }
                              tripMode={tripMode}
                              request={propState?.request}
                              onwfareid={oneSelectedFareId}
                              isdepart={true}
                              isloading={(flag) => setIsLoading(flag)}
                              sessionid={propState?.sessionId}
                              {...props}
                            />}
                          </div>
                        </LazyLoad>
                      ))}
                  </div>
                </Grid>
                {tripMode && (tripMode === "ROUND" || tripMode === "ROUND-SP") && (
                  <Grid item md={6}>
                    <div className={classes.searchResultColumn}>
                      {responseReturn &&
                        responseReturn.length > 0 &&
                        responseReturn.map((value, index) => (
                          <LazyLoad
                            once={true}
                            key={index}
                            height={200}
                            offset={[100, 0]}
                            placeholder={<PlaceholderView />}
                            debounce={500}
                            // unmountIfInvisible={true}
                          >
                            <RoundTripFlightCard
                              index={index}
                              fullWidth={true}
                              selected={retSelectedFareId}
                              flightData={value}
                              getSelectedFlight={(value, data) =>
                                toggleFlightSelectRet(value, data)
                              }
                              tripMode={tripMode}
                              request={propState?.requestObj}
                              onwfareid={oneSelectedFareId}
                              isdepart={false}
                              isloading={(flag) => setIsLoading(flag)}
                              showNetFare={showNetFare}
                              {...props}
                            />
                          </LazyLoad>
                        ))}
                    </div>
                  </Grid>
                )}
              </Grid>
                            )*/}
            {/* mobile view tabs contents starts here */}
            {width < breakpoint && (
              <Grid container justifyContent="center">
                {currentTab === "tab1" && (
                  <Grid
                    item
                    md={propState.request?.mode === "ROUND" ? 6 : 12}
                    className="sort-container"
                  >
                    <div className={classes.separator}></div>
                    <FlightSearchSort
                      tripMode={propState.request?.mode}
                      data={history?.location?.state?.onwardResp}
                      setModifiedResponse={onChangeDepart}
                      filters={history?.location?.state?.filters}
                      showNetFare={showNetFare}
                      setIsLoading={setIsLoading}
                      type="onward"
                      {...props}
                    />
                    <div className={classes.searchResultColumn}>
                      {responseDepart && responseDepart.length > 0 ? (
                        responseDepart.map((value, index) => (
                          <LazyLoad
                            once={true}
                            key={index}
                            height={200}
                            //offset={[50, 0]}
                            placeholder={<PlaceholderView />}
                            debounce={10}
                            unmountIfInvisible={true}
                          >
                            <div key={index}>
                              {propState.request?.mode === "ONE" ? (
                                <NonMUIFlightCard
                                  buttonIndex={index}
                                  fullWidth={true}
                                  selected={oneSelectedFareId}
                                  flightData={value}
                                  setIsFlightBookLoading={setIsFlightBookLoading}
                                  getSelectedFlight={(value, data) =>
                                    toggleFlightSelectOne(value, data)
                                  }
                                  tripMode={propState.request?.mode}
                                  request={propState?.requestObj}
                                  onwfareid={oneSelectedFareId}
                                  isdepart={true}
                                  isloading={(flag) => setIsLoading(flag)}
                                  sessionid={propState?.sessionId}
                                  showNetFare={showNetFare}
                                  {...props}
                                />
                              ) : (
                                <RoundTripFlightCard
                                  index={index}
                                  fullWidth={true}
                                  selected={oneSelectedFareId}
                                  setIsFlightBookLoading={setIsFlightBookLoading}
                                  flightData={value}
                                  getSelectedFlight={(value, data) =>
                                    toggleFlightSelectOne(value, data)
                                  }
                                  tripMode={propState.request?.mode}
                                  request={propState?.requestObj}
                                  onwfareid={oneSelectedFareId}
                                  isdepart={true}
                                  isloading={(flag) => setIsLoading(flag)}
                                  sessionid={propState?.sessionId}
                                  showNetFare={showNetFare}
                                  {...props}
                                />
                              )}
                            </div>
                          </LazyLoad>
                        ))
                      ) : (
                        <NoResultView />
                      )}
                    </div>
                  </Grid>
                )}

                {currentTab === "tab2" && (
                  <Grid item md={6} className="sort-container">
                    <div className={classes.separator}></div>
                    <FlightSearchSort
                      tripMode={propState.request?.mode}
                      data={history?.location?.state?.returnResp}
                      setModifiedResponse={onChangeReturn}
                      filters={history?.location?.state?.filters}
                      showNetFare={showNetFare}
                      setIsLoading={setIsLoading}
                      type="return"
                      {...props}
                    />
                    <div className={classes.searchResultColumn}>
                      {responseReturn && responseReturn.length > 0 ? (
                        responseReturn.map((value, index) => (
                          <LazyLoad
                            once={true}
                            key={index}
                            height={200}
                            offset={[100, 0]}
                            placeholder={<PlaceholderView />}
                            debounce={500}
                            // unmountIfInvisible={true}
                          >
                            <RoundTripFlightCard
                              index={index}
                              fullWidth={true}
                              selected={retSelectedFareId}
                              flightData={value}
                              getSelectedFlight={(value, data) =>
                                toggleFlightSelectRet(value, data)
                              }
                              tripMode={propState.request?.mode}
                              request={propState?.requestObj}
                              onwfareid={oneSelectedFareId}
                              isdepart={false}
                              isloading={(flag) => setIsLoading(flag)}
                              sessionid={propState?.sessionId}
                              showNetFare={showNetFare}
                              {...props}
                            />
                          </LazyLoad>
                        ))
                      ) : (
                        <NoResultView />
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            {/* mobile view tabs contents ends here */}
          </Grid>
        </Grid>
      </div>

      {/* flight selection dock for round trip starts here*/}
      {(propState.request?.mode == "ROUND" ||
        propState.request?.mode == "ROUND-SP") &&
      isOnwardSelected &&
      isOnwardSelected ? (
        <FlightSearchFooter
          flightDataOne={oneFlightSelectedData}
          flightDataRet={retFlightSelectedData}
          oneSelectedFareId={oneSelectedFareId}
          setIsFlightBookLoading={setIsFlightBookLoading}
          retSelectedFareId={retSelectedFareId}
          isloading={(flag) => setIsLoading(flag)}
          request={propState?.requestObj}
          sessionid={propState?.sessionId}
          {...props}
        />
      ) : (
        ""
      )}
      {/* flight selection dock for round trip ends here*/}
      {isLoading == true && <LoaderView />}
      <div style={{zindex: 999999, position: "sticky", zIndex: 999}}>
      {isFlightBookLoading == true && <FlightBookLoaderView />}
      </div>

      
    </div>
  ) : (
    // no results view start
    <div className={classes.root}>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/eb67f5d49a.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <FlightSearchHeader
        isModifyingVal={isModifying}
        isCancelModify={(val) => cancelModify(val)}
        showNetFare={showNetFare}
        toggleShowNetFare={toggleShowNetFare}
        tripMode={propState.request?.mode}
        isDomestic={requestObj?.isDomestic}
        {...props}
      />
      {width < breakpoint && (
        <div className={classes.FilterDrawer}>
          {history?.location?.state?.onwardResp && (
            <FilterDrawer
              tripMode={propState.request?.mode}
              onwdata={history?.location?.state?.onwardResp}
              retdata={history?.location?.state?.returnResp}
              setModifiedResponseDepart={onChangeDepart}
              setModifiedResponseReturn={onChangeReturn}
              filters={history?.location?.state?.filters}
              showNetFare={showNetFare}
              setIsLoading={setIsLoading}
              disabled={true}
            />
          )}
          <div
            className={classes.modifyButton}
            onClick={() => setIsModifying(!isModifying)}
          >
            <div className={classes.flightSearchCTA}>
              <span>{isModifying ? "x" : <img src={modify} />}</span>
            </div>
          </div>
        </div>
      )}

      <div style={{ margin: "6px 0" }}>
        <NoResultView />
      </div>
    </div>
    // no results view end
  );
};

export default FlightSearch;

const getStyles = (tripMode) => {
  return makeStyles((theme) => ({
    root: {
      paddingBottom: "20px",
      width: 1440,
      [theme.breakpoints.down(1440)]: {
        width: "100%",
      },
      "& .loader-view": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 103,
        background: "rgba(0,0,0,0.5)",
        "& .loader-wrapper": {
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          // backgroundColor: "white", 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center", 
          padding: 10, 
          borderRadius: 20
        },
        "& img": {
          height: 140, 
          width: 200
        },
        "& .loader-text": {
          color: theme.palette.text.default,
          textAlign: "center",
          fontWeight: 500
        },
      },
      "& .sort-container": {
        "& select": {
          border: `solid 0.5px #aaaaaa`,
          borderRadius: 3,
          color: "#333",
          padding: "2px 3px",
        },
      },
      "& .FilterDrawer-grid": {
        flexGrow: 1,
        [theme.breakpoints.down(963)]: {
          width: "100%",
          // flexGrow: "0 !important",
        },
      },
    },

    FilterGridItem: {
      minWidth: 250,
      maxWidth: 250,
      marginTop: 5,
      marginLeft: 5,
      textAlign: "left",
      [theme.breakpoints.down(1250)]: {
        // display: "none",
        // display: tripMode == "ROUND" && "none",
        width: 250,
      },
      [theme.breakpoints.down(1050)]: {
        // display: "none",
        width: 250,
      },
      [theme.breakpoints.down(950)]: {
        display: "none",
        // width: 250,
      },
    },

    FilterDrawer: {
      width: "100%",
      zindex: "101",

      marginBottom: "6px",

      [theme.breakpoints.up(950)]: {
        display: "none",
      },
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      // marginTop: 30,
      [theme.breakpoints.down(260)]: {
        marginBottom: 0,
        marginTop: 30,
      },
    },
    separator: {
      [theme.breakpoints.down(400)]: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        margin: "10px 0",
        width: "100%",
      },
    },
    flightSearchCTA: {
      height: "40px",
      width: "160px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "30px 0 0 30px",
      cursor: "pointer",
      display: "grid",
      placeItems: "center",
      // padding: "17px 13px 34px",
      [theme.breakpoints.down(400)]: {
        width: "100px",
        fontSize: "16px",
      },
      "& span": {
        width: "100%",
        fontSize: "20px",
        fontWeight: "600",
        color: theme.palette.primary.contrastText,
        textAlign: "center",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
      },
    },
    modifyButton: {
      [theme.breakpoints.up(1620)]: {
        display: "none",
      },
    },
    searchResultColumn: {
      padding: "0 5px",
      display: "flex",
      flexDirection: "column !important",
      flexWrap: "nowrap !important",
    },
    searchResultContainer: {
      flexWrap: "nowrap",
      width: "100%",
      minHeight: "80vh",
    },
    noResultContainer: {
      flexWrap: "nowrap",
      width: "100%",

      minHeight: "calc(80vh - 100px)",
      justifyContent: "center",
      alignItems: "center",
    },
    noResults: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    tabNav: {
      listStyle: "none",
      padding: "0 10px",
      margin: 0,
      gap: "0.1rem",
      display: "none",
      [theme.breakpoints.down(980)]: {
        display: "flex",
      },
      "& li": {
        padding: "6px 10px",
        cursor: "pointer",
        border: `1px solid ${theme.palette.primary.shadedLightText}`,
        textAlign: "center",
        "&.activeTab": {
          color: theme.palette.primary.main,
        },
        "& i": {
          margin: "0 6px",
        },
      },
    },
    flightSearchElement: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 10px",
      marginBottom: 5,
      "&.horz-flex": {
        display: "flex",
      },
      [theme.breakpoints.down(550)]: {
        "&.horz-flex": {
          gridColumn: "1/span 3",
          width: "100% !important",
          justifyContent: "center",
          borderTop: `solid 0.1px`,
          margin: "2px 0 -5px 0",
          padding: "5px 0 0 0",
        },
      },
      "& .tnf-button": {
        fontWeight: 600,
        fontFamily: theme.palette.font.secondary,
        color: "auto",
        padding: "0 5px",
        background: theme.palette.buttons.default,
        color: theme.palette.buttons.defaultContrast,
        "&.active": {
          color: theme.palette.buttons.default,
          background: theme.palette.buttons.defaultContrast,
        },
      },
      "& .filter-toggle-rt": {
        minWidth: 40,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        display: "none",
      },
      "& .filter-rt-desktop-only": {
        display: "none",

        [theme.breakpoints.between(992, 1500)]: {
          display: "flex",
          padding: "5px",
          alignItems: "center",
          marginLeft: 10,
        },
      },
    },
  }));
};

import React from "react";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import Rive from "rive-react";
import image1 from "assets/rive/hotelanimation.riv";
import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useHistory } from "react-router-dom";
import { localforageClearTrnParam } from "oautils/oaForageUtils";
import { Grid, Divider, Button } from "@material-ui/core";

const ExtRedirHotelBookProgress = (props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [blockData, setBlockData] = React.useState({});
  const [priceChangedStop, setPriceChangedStop] = React.useState(false);
  const [totalFare, setTotalFare] = React.useState(null);
  const [hotelInfo, setHotelInfo] = React.useState(null);
  const [hotelSearch, setHotelSearch] = React.useState(null);
  const [hotelRoomsDetails, setHotelRoomsDetails] = React.useState(null);
  const [reviewedBookingId, setReviewedBookingId] = React.useState(null);
  const [instantBooking, setInstantBooking] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(
    "Blocking room, Please wait"
  );
  const history = useHistory();

  const getTrnParams = () => {
    return new Promise((resolve, reject) => {
      localforageGetItem("hotel-transact", (error, trnParams) => {
        if (trnParams) {
          resolve(trnParams);
        } else {
          console.log("error getting params", error);
          reject(error);
        }
      });
    });
  };

  const clearTrnParams = () => {
    return new Promise((resolve, reject) => {
      localforageClearTrnParam("hotel-transact")
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  };

  React.useEffect(() => {
    getTrnParams().then((trnParams) => {
      trnParams.orderId = props?.match?.params?.transactionId;
      setHotelInfo(trnParams.hotelInfo);
      setTotalFare(trnParams.totalFare);
      setHotelRoomsDetails(trnParams.hotelRoomsDetails);
      setHotelSearch(trnParams.hotelSearch);
      setReviewedBookingId(trnParams.reviewedBookingId);
      setInstantBooking(trnParams.instantBooking);
      submitData(trnParams);
    });
  }, []);

  const submitData = (param) => {
    console.log(param, "ONSUBMIT DATA PARAM");
    apiCall(
      WebApi.getHotelBlock,
      param,
      (response) => {
        if (response.success === true) {
          // console.log(response.data);
          if (response.data.blockRoomResult.availabilityType === "Available") {
            setIsError(true);
            setInfoText("Cannot book online. Please contact operations team");
          } else {
            // response.data.blockRoomResult.isCancellationPolicyChanged = true;

            param.isPackageFare = response.data.blockRoomResult.isPackageFare;
            param.isDepartureDetailsMandatory = response.data.blockRoomResult.isDepartureDetailsMandatory;

            if (
              response.data.blockRoomResult.isCancellationPolicyChanged ===
              "true"
            ) {
              response.data.blockRoomResult.hotelRoomsDetails.map(
                (room, index) => {
                  param.hotelRoomsDetails[index].cancellationPolicies =
                    room.cancellationPolicies;
                  param.hotelRoomsDetails[index].cancellationPolicy =
                    room.cancellationPolicy;
                }
              );
            }
            if (response.data.blockRoomResult.isHotelPolicyChanged === "true") {
              param.hotelNorms = response.data.blockRoomResult.hotelNorms;
            }
            if (response.data.blockRoomResult.isPriceChanged === "true") {
              setBlockData(response.data.blockRoomResult);
              setResponseMessage(
                "Room blocked successfully, price changed. Please confirm for booking room on changed price"
              );
              response.data.blockRoomResult.hotelRoomsDetails.map(
                (room, index) => {
                  param.hotelRoomsDetails[index].price = { ...room.price };
                }
              );
              setPriceChangedStop(true);
            } else {
              bookRoom(param);
            }
          }
        } else {
          setIsError(true);
          setInfoText(response.message);

          setResponseMessage("Booking failed due to an error.");
          setTimeout(() => {
            history.push("/hotels");
          }, 5000);
        }
      },
      (error) => {
        console.log("getHotelBlock error", error);
        history.push(`/hotel/payment-status/${param.txnId}/failed`);
      }
    );
  };

  const bookRoom = (param) => {
    setPriceChangedStop(false);
    setResponseMessage(
      "Room blocked successfully, please wait booking room now"
    );
    console.log("param 2 ", param);

    //param.packageFare = true;
    WebApi.getHotelBook(
      param,
      (response) => {
        // console.log(response);
        if (response.success === true) {
          // console.log(response.data);
          // response.data.blockRoomResult.isCancellationPolicyChanged = true;
          if (response.data.bookResult.isCancellationPolicyChanged === "true") {
            response.data.bookResult.hotelRoomsDetails.map((room, index) => {
              param.hotelRoomsDetails[index].cancellationPolicies =
                room.cancellationPolicies;
              param.hotelRoomsDetails[index].cancellationPolicy =
                room.cancellationPolicy;
            });
          }
          if (response.data.bookResult.isHotelPolicyChanged === "true") {
            param.hotelNorms = response.data.bookResult.hotelNorms;
          }
          if (response.data.bookResult.isPriceChanged === "true") {
            setBlockData(response.data.bookResult);
            setResponseMessage(
              "Room blocked successfully, price changed. Please confirm for booking room on changed price"
            );
            response.data.bookResult.hotelRoomsDetails.map((room, index) => {
              param.hotelRoomsDetails[index].price = { ...room.price };
            });
            setPriceChangedStop(true);
          } else {
            apiCall(
              WebApi.getHotelBookDetails,
              {
                traceId: response.data.bookResult.traceId,
                bookingId: response.data.bookResult.bookingId,
                confirmationNo: response.data.bookResult.confirmationNo,
                firstName: param.values.room[0].adult[0].firstName,
                lastName: param.values.room[0].adult[0].lastName,
                tid: param.txnId
              },
              (response) => {
                setIsSuccess(true);
                setInfoText(response.message);
                // console.log(response.data);
                if (response.success === true) {
                  if (
                    response.data.getBookingDetailResult.isPriceChanged ===
                    "true"
                  ) {
                    //  setBlockData(response.data.bookResult);
                    setResponseMessage(
                      "Price changed. Please confirm for booking room on changed price"
                    );
                    response.data.getBookingDetailResult.hotelRoomsDetails.map(
                      (room, index) => {
                        param.hotelRoomsDetails[index].price = {
                          ...room.price,
                        };
                      }
                    );
                    setPriceChangedStop(true);
                  } else {
                    clearTrnParams()
                      .then(() => {
                        history.push({
                          pathname: `/hotel/payment-status/${param.txnId}/success`,
                          state: {
                            bookingDetails:
                              response.data.getBookingDetailResult,
                            hotelSearch: param.hotelSearch,
                            values: param.values,
                          },
                        });
                      })
                      .catch((error) => {
                        console.log("clear transact error", error);
                      });
                  }
                } else {
                  setIsError(true);
                  setInfoText(response.message);

                  setResponseMessage("Booking failed due to an error");
                  setTimeout(() => {
                    history.push(`/hotel/payment-status/${param.txnId}/failed`);
                  }, 5000);
                }
              },
              (error) => {
                console.log("getHotelBookDetails error", error);
                history.push(`/hotel/payment-status/${param.txnId}/failed`);
              }
            );
          }
        } else {
          setIsError(true);
          setInfoText(response.message);

          setResponseMessage(
            "Error occured. please wait, we are redirecting you back"
          );
          setTimeout(() => {
            history.push(`/hotel/payment-status/${param.txnId}/failed`);
          }, 5000);
        }
      },
      (error) => {
        console.log("getHotelBook error", error);
        history.push(`/hotel/payment-status/${param.txnId}/failed`);
      }
    );
  };

  return (
    <Grid container style={{ height: "60vh" }}>
      {!priceChangedStop ? (
        <>
          <Grid item md={12}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
          </Grid>
          <Grid
            item
            md={12}
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              backgroundColor: "transparent",
            }}
            className="rive-grid"
          >
            <Rive src={image1} />
          </Grid>
          <style>{`.rive-grid div { display: flex; justify-content: center; align-items: center}`}</style>
          <Grid
            item
            md={12}
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              backgroundColor: "transparent",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "500", textAlign: 'center' }}>
              {responseMessage}
            </span>
          </Grid>
        </>
      ) : (
        <>
          <Grid item md={12}>
            <div
              style={{
                fontSize: "20px",
                marginBottom: "0.8rem",
                marginTop: "6rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "500",
              }}
            >
              The fares for the Room(s) you have selected has changed. Please
              check the fares and continue.
            </div>
            <Divider style={{ marginBottom: "2rem" }} />
            <Grid container>
              <Grid item md={2}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Hotel Booking</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  {hotelInfo.hotelDetails.hotelName}
                </div>
              </Grid>
              <Grid item md={1}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <u>Departure</u>
                </div>
                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                  {hotelSearch.checkOutDate}
                </div>
              </Grid>
              <Grid item md={1}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <u>Arrival</u>
                </div>
                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                  {hotelInfo.checkInDate}
                </div>
              </Grid>
              {/* <Grid item md={2}>
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            marginBottom: "4%",
                                        }}
                                    >
                                        <u>Date</u>
                                    </div>
                                    <div style={{ fontWeight: "450" }}>
                                        {blockData.DepartureTime}
                                    </div>
                                </Grid> */}
              <Grid item md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>City</u>n
                </div>
                <div style={{ fontWeight: "450" }}>{hotelSearch.city}</div>
              </Grid>
              <Grid item md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Destination</u>
                </div>
                <div style={{ fontWeight: "450" }}>Hyderabad</div>
              </Grid>
              <Grid item md={2}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "3%",
                  }}
                >
                  <u>Fare + Tax (Rs.)</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  <Button style={{ padding: 0, background: "#ececec" }}>
                    {totalFare}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: "1rem", marginTop: "2rem" }} />
            <div
              style={{
                marginBottom: "0.2rem",
                marginTop: "1.5rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Actual Fare: Rs. {totalFare}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <Button style={{ padding: "3px 15px", background: "#ececec" }}>
                New Fare : Rs.{" "}
                {hotelRoomsDetails.reduce((total, room) => {
                  return (
                    total +
                    parseInt(room.price.commissionCharge.grossProductPrice)
                  );
                }, 0) * hotelSearch.noOfNights}
              </Button>
            </div>
            <Divider style={{ marginBottom: "0.2rem", marginTop: "1rem" }} />
            <div
              style={{
                fontSize: "20px",
                marginBottom: "0.8rem",
                marginTop: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "500",
              }}
            >
              Do you want to continue booking?
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <Button variant="contained" color="primary" onClick={bookRoom}>
                Yes
              </Button>
              <Button
                style={{ background: "#ececec", marginLeft: "1rem" }}
                onClick={() => history.push("/hotel/search")}
              >
                No
              </Button>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ExtRedirHotelBookProgress;

import React from "react";
import HomePage from "components/LandingPage/DoneTrip/HomePage";
import ContactUsPage from "components/LandingPage/DoneTrip/siteinfo/ContactUsPage";
import AboutUsPage from "components/LandingPage/DoneTrip/siteinfo/AboutUsPage";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.DoneTrip";
import PageFooter from "components/Footer/PageFooter.DoneTrip";
import ScrollToTopComp from "components/LandingPage/DoneTrip/ScrollToTopComp";
import WaFloatingButton from "components/Footer/WaFloatingButton";
//import CompanyLogo from "assets/doneTrip/images/company_logo.png";
import { refreshUserSession } from "oautils/oaAuthUtils";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

const LandingPageBase = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  React.useEffect(() => {
    refreshUserSession(dispatch, callbackRefreshToken);
  }, []);

  return (
    <>
      <PostLoginNavBar isloading={isLoading} />
      <Switch>
        <Route path="/contactus" component={ContactUsPage} />
        <Route path="/aboutus" component={AboutUsPage} />
        <Route>
          <div style={{ minHeight: "67vh" }}>
            <HomePage {...props} />
          </div>
        </Route>
      </Switch>
      {/* <PageFooter /> */}
      {/* <WaFloatingButton companylogo={CompanyLogo} /> */}
      <ScrollToTopComp />
    </>
  );
};

export default LandingPageBase;

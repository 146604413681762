import React, { useState } from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const CancellationAndRefund = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomepage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%" }}
        justifyContent="center"
      >
        <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
          <a onClick={goToHomepage} className={classes.breadcrumbLink}>
            <i className="fa fa-home"></i> Home
          </a>
        </Grid>

        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h3"
                className={classes.sectionHeader}
                gutterBottom
              >
                CANCELLATION & REFUND POLICY:
              </Typography>
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Passenger Details:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                It is passenger's responsibility to provide mydonetrip with the
                correct exact spelling of names for booking as per shown on the
                passenger's passport. It is passenger's responsibility to check
                carefully for any errors or omissions in the flight details on
                your itinerary and air tickets IMMEDIATELY upon receiving them.
                Please note that passengers may be denied flight boarding if
                errors are discovered only upon check-in at the airport and in
                such event mydonetrip is not responsible for any kind of refund.
              </Typography>

              <Gap10 />
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Entry / Transit:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Visas Requirements It is passenger's responsibility to ensure
                you have valid Entry Visa or Transit Visa to enter the country
                of destination or applicable stopovers destinations. Transit
                Visas must be duly obtained if the plane's route involves a
                Transit or Stopover in another country. For example, a flight to
                New Zealand which requires stopover in Australia or a flight to
                Canada which transit in the United States of America. Before
                your departure, it is passenger's responsibility to contact the
                embassy or consulate office of the country of your destination
                for information on passport, visa or entry requirements. It is
                passenger's responsibility to ensure all documents are in order
                and to obtain and meet all Entry/Transit Visas requirements.
                mydonetrip is not liable for any consequences arising from
                improper or incomplete travel documents or not meeting the
                Entry/Transit Visas requirements.
              </Typography>

              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Passport Validity
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                It is passenger's responsibility to ensure that your passport
                should have a minimum of 6 months validity upon completion of
                the return journey, proper and valid.
              </Typography>

              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Passenger Contacts & Flight Schedule Change:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Please provide your local and overseas Contact Tel Numbers in
                your flight booking for the airline to contact you in the event
                of any changes to the flight schedules so as to avoid the
                missing of flight or seat cancellation. Passengers who had
                issued their air tickets many days or weeks prior to their
                departure dates, are advised to recheck with the Airlines
                Reservation staff for any changes or latest updates in the
                airlines flight schedules just a day or 2 before their actual
                departure date. Airlines reserve the right to cancel, reroute
                and/or alter the timing of their flights even after tickets have
                been issued. In such events, the airline will provide
                alternative flights for your consideration. If you do not wish
                to accept the airline's alternative, the fare's cancellation and
                amendment policy will still apply.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Non-Transferable:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                All bookings make with mydonetrip is non-transferable to another
                travel agent and non-transferable to another passenger name.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Penalty & Service Charges:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                You are required to pay the entire amount prior to the
                confirmation of your booking. Once tickets are issued, there are
                airline penalty charges and our agency admin fees for any
                amendment or cancellation to the booking or tickets e.g.
                Amendment of spelling errors in passenger names, changes in the
                date of travel. All requests for amendments are subject to the
                Airline's approval. To avoid such penalties, passengers are
                advised to check all booking itinerary to ensure correctness in
                passengers names, departure dates, flight details, etc before
                issuing your air tickets.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Booking Conditions & Disclaimers:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Prices displayed are tentative only and are subject to changes
                and not guaranteed until the air tickets are issued and full
                payment has been received mydonetrip.com. Payment of partial
                deposit does not imply or constitute the confirmation of flight
                seats or hotel accommodations, or package bookings. Any
                amendments or cancellations after air tickets are issued or
                hotel/package bookings are confirmed are subject to applicable
                penalties and admin fees. mydonetrip reserve the rights to
                refuse any bookings if the fares displayed are found to be in
                error or are being withdrawn by the airlines. mydonetrip shall
                not be held liable for any consequential damages for any direct
                or indirect losses, or additional out of pocket costs or
                expenses arising out of any aborted trips or changes in trip
                arrangements due to any errors or omissions in the bookings.
                mydonetrip liability, under whatever circumstances, shall be
                limited only to the payment already made by the passenger.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Rescheduling of ticket:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                :ow Cost Carriers(LCC Carriers): Rescheduling must be done
                directly with the respective Airlines. Full Service Carriers:
                Must be done at least 6 Hours before departure by sending an
                email at mysupport@mydonetrip.com. Payment for the same can be
                made through travel utility option available
                on mydonetrip.com Rescheduling for departure less than 6 Hours
                must be done directly with the airline.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Unaccompanied Minor:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Any Passenger below 17 years old traveling alone is considered
                as 'Unaccompanied Minor'.Please inform us immediately if you are
                making a booking for an Unaccompanied Minor so that special
                arrangement is required to prevent any rejection for boarding
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Child Fares:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Child : 2 - 11 years old. A child must not reach his/her 12th
                birthday at the point of the return flight journey. If the
                birthday of the child exceeds 12, then the next higher fare
                level must be purchased separately for the return journey.
                Please note that depending on the type of ticket purchased,
                there may be no refund value on the un-utilized portion of the
                child ticket.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Infant Fares
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Infant : below 2 years old. There is no seat provided for infant
                fares. An infant must not reach his/her 2nd birthday at the
                point of the return flight journey. If the birthday of the child
                exceeds 2 years old, then the next higher fare level must be
                purchased separately for the return journey. Please note that
                depending on the type of ticket purchased, there may be no
                refund value on the un-utilized portion of the infant ticket.
                Infants must be accompanied by an adult at least 18 years of
                age.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Travel Insurance:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                For your protection against any contingency or emergency during
                your journey, passengers are strongly advised to purchase a
                travel insurance policy. mydonetrip offers a range of Travel
                Insurance which provides a wide and comprehensive range of
                insurance coverage.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Flight Reconfirmation:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Please re-confirm all onward flight journey if it is required by
                the airline that you are traveling on, so as to avoid losing
                your pre-confirmed seats on those required airlines.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Meal Request:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                If you have any special needs or meal requests, please let us
                know at the point of booking or in advance so that we can put up
                your requests with the respective airlines.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Seat Request:
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Selection of seats varies among different classes of service and
                airlines, to book the right seat for you, please call us early
                and in advance so that we can put up your request to the
                airline.
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Refund Policy
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Refund for your PNR/Ticket will be initiated as per the airline
                policy, the same will reflect as per the rules within 7 -14
                working days depending on your bank.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Refunds for your PNR/Ticket will be initiated once it is
                received from the airline, the same will be initiated and will
                reflect in the original form of payment.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                For any cancellation done by passenger or
                airline/hotel,,mydonetrip will deduct 300 as a fee apart from
                charges levied by airline or hotel.The cancellation penalties
                mentioned above may vary if the cancellation is not done as per
                the set guidelines.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                If the flight you booked got cancelled, mydonetrip.com will
                process the refund (subject to airline policy), deducting
                Rs.300/-(Domestic) and Rs.600/-(International) as service charge
                and applicable bank charges.Please send an email
                to support@mydonetrip.com, mentioning your mydonetrip online
                reference number and airline cancelled flight details. In some
                cases, you will have to mail us a stamped copy provided by the
                airline.The refund amount will be processed as chargeback in the
                same manner it was initially paid.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={classes.sectionTextGrid}
          style={{ textAlign: "center" }}
        >
          <a onClick={goToHomepage} className={classes.breadcrumbLink}>
            <i className="fa fa-home"></i> Home
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default CancellationAndRefund;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& a": {
      cursor: "pointer",
    },
    "& .highlight": {
      color: theme.palette.secondary.info,
    },
  },

  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  // firstCard: {
  //     "& .MuiTypography-root": {
  //         color: COLORS.PRIMARY_BG_DARK,
  //     },
  // },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.darkText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.secondary.main} !important`,
    "&:hover": {
      color: `${theme.palette.secondary.active} !important`,
    },
  },
}));

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

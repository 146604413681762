import WebApi from "api/ApiConstants";
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  Popper,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SHAPE_BG, HIGHLIGHT_IMG, CAROUSEL_IMG } from "assets/doneTrip/images";
import { Route, Switch } from "react-router-dom";
import bg from "assets/doneTrip/images/homepage/background.jpg";
import FlightSection from "./FlightSection";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import HotelSection from "./HotelSection";
import MobileFlightSection from "components/LandingPage/for-mobile/MobileFlightSection";
import MobileHotelSection from "components/LandingPage/for-mobile/MobileHotelSection";
import { useHistory } from "react-router-dom";

const PlanYourTrip = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const isHotels = window.location.toString().indexOf("hotels") != -1;

  // console.log();

  const handleChange = (event, newValue) => {
    if (+newValue === 1) {
      history.push("/flights");
    } else {
      history.push("/hotels");
    }

    setValue(newValue);
  };

  const width = window.innerWidth;
  const breakpoint = 980;

  useEffect(() => {
    if (isHotels) {
      setValue("2");
    } else {
      setValue("1");
    }
  }, [isHotels]);

  const FlightSearch = () => {
    return (
      <div className="section-content">
        {/* <h3>
          {" "}
          <span>
            <FlightTakeoffIcon />
          </span>{" "}
          Search Lowest Price
        </h3> */}
        <div className="search__box">
          {width < breakpoint ? <MobileFlightSection /> : <FlightSection />}
        </div>
      </div>
    );
  };

  const HotelSearch = () => {
    return (
      <div className="section-content">
        {/* <h3>
          {" "}
          <span>
            <ApartmentIcon />
          </span>{" "}
          Same hotel, Cheapest price. Guaranteed!
        </h3> */}
        <div className="search__box">
          {width < breakpoint ? <MobileHotelSection /> : <HotelSection />}
        </div>
      </div>
    );
  };

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          {/* <Grid container className="sectionContainerr"> */}
          <Grid item md={12} xs={12}>
            <Switch>
              <Route
                path="/flights"
                render={() => {
                  // return <FlightSearchSection {...props} />;
                  return (
                    <TabContext value={value}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          marginTop: "36px",
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Flight" value="1" />
                          <Tab label="Hotel" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <FlightSearch {...props} />
                      </TabPanel>
                      <TabPanel value="2">
                        <HotelSearch {...props} />
                      </TabPanel>
                    </TabContext>
                  );
                }}
              />
              <Route
                path="/hotels"
                render={() => {
                  return (
                    <TabContext value={value}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          marginTop: "36px",
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Flight" value="1" />
                          <Tab label="Hotel" value="2" />
                        </TabList>
                      </Box>

                      <TabPanel value="1">
                        <FlightSearch {...props} />
                      </TabPanel>
                      <TabPanel value="2">
                        <HotelSearch {...props} />
                      </TabPanel>
                    </TabContext>
                  );
                }}
              />

              <Route
                render={() => {
                  // return <FlightSearchSection {...props} />;
                  return (
                    <TabContext value={value}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          marginTop: "36px",
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Flight" value="1" />
                          <Tab label="Hotel" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <FlightSearch {...props} />
                      </TabPanel>
                      <TabPanel value="2">
                        <HotelSearch {...props} />
                      </TabPanel>
                    </TabContext>
                  );
                }}
              />
            </Switch>
          </Grid>
          {/* <TabContext value={value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", marginTop: 70 }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Flight" value="1" />
                <Tab label="Hotel" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <FlightSearch {...props} />
            </TabPanel>
            <TabPanel value="2">
              <HotelSearch {...props} />
            </TabPanel>
          </TabContext> */}
        </Container>
      </Box>
    </>
  );
};

export default PlanYourTrip;

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "15px 0px",
    position: "relative",
    width: "100%",
    minHeight: "60vh",
    padding: "5.3rem 0",
    zIndex: 99,
    [theme.breakpoints.down(500)]: {
      height: "100%",
    },
    // backgroundImage: `url(${bg})`,
    backdropFilter: "blur(26px) saturate(180%)",
    backgroundImage:
      "linear-gradient(45deg,hsl(17deg 67.02% 67.88%) 0%,hsl(2deg 39.58% 58.77%) 44%,hsl(238deg 24.7% 48.28%) 84%)",
    // background: "linear-gradient(to right, #f26b34 60%, #323467)",

    display: "flex",
    alignItems: "center",
    "& .MuiTabPanel-root": {
      backgroundColor: theme.palette.primary.lightText,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      minWidth: 140,
      [theme.breakpoints.down(500)]: {
        padding: 5,
      },
    },
    "& .MuiTabs-fixed": {
      height: "100%",
    },
    "& .PrivateTabIndicator-colorSecondary-172": {
      backgroundColor: "transparent",
    },
    "& .MuiTab-root": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      marginRight: 5,
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.lightText,
    },
    minHeight: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    "& .sectionContainerr": {
      // marginTop: 140,
      backgroundColor: "#3D4F78",
      borderRadius: 20,
      padding: 25,
      boxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
    },
    "& h1": {
      textShadow: "-1px -1px 25px rgb(0 0 0 / 10%)",
      color: "#000000",
      fontWeight: 700,
      marginBottom: "1.5rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "4.5rem",
        marginTop: 0,
      },
      [theme.breakpoints.down(1500)]: {
        fontSize: "3.3rem",
        marginTop: 0,
        marginBottom: "0.5rem",
      },
    },
    "& .react-datepicker__month-container": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .MuiAutocomplete-listbox": {
      overflowY: "auto !important",
      width: "320px !important",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#b1b1b1",
        borderRadius: 8,
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: "#b1b1b1",
        },
      },
      "& .ac-options-list": {
        overflow: "hidden",
        width: "100%",
        "& span": {
          marginBottom: 0,
          color: theme.palette.primary.darkFadedText,
          whiteSpace: "nowrap",
        },
        "& div": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          flexWrap: "nowrap",
          "& .ac-option-left": {
            textAlign: "left",
            color: theme.palette.primary.darkText,
            fontWeight: 600,
            flexGrow: 1,
          },
          "& .ac-option-right": {
            textAlign: "right",
            color: theme.palette.primary.darkFadedText,
          },
        },
      },
    },
    "& .react-datepicker-popper": {
      zIndex: 9,
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .travels-class-box": {
      marginBottom: 10,
      gap: 8,
      flexWrap: "nowrap",
      [theme.breakpoints.down(630)]: {
        flexWrap: "wrap",
      },
    },
    "& .preAirline-search-box": {
      marginBottom: 10,
      gap: 8,
      flexWrap: "nowrap",
      [theme.breakpoints.up(630)]: {
        alignItems: "center",
      },
    },
    "& .oa-form-element-primary": {
      "& > div": {
        [theme.breakpoints.down(630)]: {
          width: "100% !imported",
          padding: "0 3px  !imported",
        },
      },
    },
    "& .MuiInputBase-input": {
      [theme.breakpoints.down(630)]: {
        fontSize: "16px !important",
      },
    },
    "& .break-line-multicity": {
      borderTop: `3px solid ${theme.palette.primary.disabled}`,
      "&.array-0": {
        border: "none",
        paddingTop: 0,
      },
      paddingTop: "10px",
    },
    "& .oa-form-element-card": {
      border: "none",
    },
  },

  highText: {
    position: "relative",
    "&:before": {
      content: '""',
      display: "flex",
      width: 74,
      height: 16,
      position: "absolute",
      bottom: "-10px",
      left: "30%",
      background: `url(${HIGHLIGHT_IMG}) no-repeat`,
    },
  },
  // radioGroup: {
  //   "& .MuiFormControlLabel-label": {
  //     color: theme.palette.primary.defaultText,
  //     fontWeight: 600,
  //   },
  //   "& .MuiRadio-colorSecondary.Mui-checked": {
  //     color: theme.palette.primary.main,
  //   },
  // },
  card: {
    boxShadow: "-1px -1px 25px rgb(0 0 0 / 10%)",
  },
  travelersLabel: {
    fontSize: 15,
    color: "rgb(116 103 103 / 87%)",
  },
  travelersSubLabels: {
    fontWeight: 500,
    margin: "2px 5px",
    textTransform: "uppercase",
  },
  searchButton: {
    color: theme.palette.primary.lightText,
    minWidth: 75,
    maxWidth: 150,
    width: "100%",
    minHeight: 50,
    borderRadius: "0.5rem",
    border: "none",
    transition: "all 0.2s",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    background: theme.palette.background.darkDefault,
    marginTop: 2,

    [theme.breakpoints.down("sm")]: {
      width: 200,
      marginTop: 20,
    },
    "&:before": {
      width: "100%",
      height: "100%",
      content: "",
      margin: "auto",
      position: "absolute",
      top: 0,
      left: "-100%",
      transition: "all 0.2s",
      zIndex: -1,
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      // width: "100%",
      // height: "100%",
      // content: '""',
      // margin: "auto",
      // position: "absolute",
      // top: 0,
      // left: "-100%",
      transition: "ease 0.2s",
      // zIndex: -1,
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:hover:before": {
      top: 0,
      left: 0,
    },
  },
  Carousel: {
    "& .carousel": {
      borderRadius: 10,
      [theme.breakpoints.up("md")]: {
        marginLeft: "2%",
      },
    },
  },
  inputBorder: {
    padding: "10px",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.primary.disabled}`,
  },
}));

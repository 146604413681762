import React from "react";
import useStyles from "components/Footer/PageFooter.DoneTrip/FooterStyles";
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Link, useHistory, withRouter } from "react-router-dom";
import travlogy_logo from "assets/travlogy/images/travlogy_logo_white.png";
import logoWhite from "assets/doneTrip/images/company_logo.png";
import payments from "assets/doneTrip/images/homepage/payment.svg";
import godaddy_secure from "assets/doneTrip/images/icons/godaddy-verify-secure.png";



const PageFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const loadPageFromPath = (path) => {
    history.push(path);
  };
  return (
    <>
      <footer className={classes.root}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={3} lg={3} xs={12}>
              <ul>
                <li>
                  <img className="logoWhite" src={logoWhite} />
                </li>


                {/* <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document
                </p> */}
                {/* <li>
                  <a
                    onClick={() => loadPageFromPath("/contactus")}
                    className={classes.effects}
                  >
                    Contact Us
                  </a>
                </li> */}
              </ul>

            </Grid>
            <Grid item sm={3} lg={3} xs={12}>
              <p className={classes.headText}>Explore</p>
              <ul>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/aboutus")}
                    className={classes.effects}
                  >
                    About Us
                  </a>
                </li>
                {/* <li>
                  <a
                    onClick={() => loadPageFromPath("/contactus")}
                    className={classes.effects}
                  >
                    Support
                  </a>
                </li> */}
                <li>
                  <a
                    onClick={() => loadPageFromPath("/b2c/printeticket")}
                    className={classes.effects}
                  >
                    E-ticket Print
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item sm={3} lg={3} xs={12}>
              <p className={classes.headText}>Support &amp; Help</p>
              <ul>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/contactus")}
                    className={classes.effects}
                  >
                    Contact Us
                  </a>
                </li>
                {/* <li>
                  <a to="/" className={classes.effects}>
                    InSite
                  </a>
                </li> */}
              </ul>
            </Grid>
            <Grid item sm={3} lg={3} xs={12}>
              <p className={classes.headText}>PRIVACY</p>
              <ul>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/privacypolicy")}
                    className={classes.effects}
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/terms_and_conditions")}
                    className={classes.effects}
                  >
                    Terms & condition
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/cancellation_and_refund")}
                    className={classes.effects}
                  >
                    Cancellation & Refund
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/pricing_policy")}
                    className={classes.effects}
                  >
                    Pricing Policy
                  </a>
                </li>
              </ul>
            </Grid>
            {/* <Grid item sm={3} lg={3} xs={12}>
              <p className={classes.headText}>Contact with us</p>
              <ul>
                <li>
                  <a
                    href={`mailto:${AppConstant.infoEmail}`}
                    className={classes.effects}
                  >
                    {AppConstant.infoEmail}
                  </a>
                </li>
                <li>
                  <a
                    href={`tel:${AppConstant.countryDialCode}${AppConstant.infoContact}`}
                    className={classes.effects}
                  >
                    {AppConstant.countryDialCode} {AppConstant.infoContactView}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => loadPageFromPath("/")}
                    className={classes.effects}
                  >
                    Products &amp; Services
                  </a>
                </li>
              </ul>
            </Grid> */}
            {/* <Grid item sm={3} lg={3} xs={12}>
              <p className={classes.headText}>Keep in Touch</p>
              <ul>
                <li className={classes.socialMedia}>
                  <Link to="/">
                    <img
                      src={FACEBOOK_ICON}
                      alt="Facebook"
                      className={classes.socialMediaIcon}
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={INSTAGRAM_ICON}
                      alt="Instagram"
                      className={classes.socialMediaIcon}
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={TWITTER_ICON}
                      alt="Twitter"
                      className={classes.socialMediaIcon}
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={YOUTUBE_ICON}
                      alt="YouTube"
                      className={classes.socialMediaIcon}
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={WHATSAPP_ICON}
                      alt="WhatsApp"
                      className={classes.socialMediaIcon}
                    />
                  </Link>
                </li>
                <li>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    <img
                      src={CHAT_ICON}
                      alt="Chat"
                      className={classes.socialMediaIcon}
                    />
                    <div>
                      <span>24×7 Customer Support</span>
                      <br />
                      <span className={classes.contact}>1800-313-5699</span>
                    </div>
                  </div>
                </li>
              </ul>
            </Grid> */}
          </Grid>
          {/* <Grid container>
            <Grid item xs={12} lg={4} sm={6}>
              <p className={classes.headText}>Payment Options</p>
              <div style={{ display: "flex", marginTop: 10 }}>
                <img
                  src={MASTER_CARD_IMG}
                  alt="Master Card"
                  className={classes.paymentImg}
                />
                <img
                  src={AMERICAN_EXP_IMG}
                  alt="American Express"
                  className={classes.paymentImg}
                />
                <img
                  src={PAYPAL_IMG}
                  alt="PayOal"
                  className={classes.paymentImg}
                />
                <img src={VISA_IMG} alt="Visa" className={classes.paymentImg} />
              </div>
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
              <p className={classes.headTextCenter}>Download App</p>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: isMediumScreen ? "start" : "center",
                }}
              >
                <img
                  src={APP_STORE_IMG}
                  alt="App-Store"
                  className={classes.storeImg}
                />
                <img
                  src={PLAY_STORE_IMG}
                  alt="Google-Play-Store"
                  className={classes.storeImg}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
              <p className={classes.headTextCenter}>Member of</p>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: isMediumScreen ? "start" : "center",
                }}
              >
                <img src={IATA_IMG} alt="IATA" className={classes.memberImg} />
                <img
                  src={NETTA_IMG}
                  alt="NETTA"
                  className={classes.memberImg}
                />
              </div>
            </Grid>
          </Grid> */}
          <Grid
            container
            style={{
              // marginTop: "2rem",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 40,
              // backgroundColor: "#13161d ",
            }}
          >
            <Grid item>
              {/* <img src={LOGO_WHITE_IMG} alt="Logo" width={20} />
               */}
              {/* Mydonetrip © 2022 | */}
              <span className="copyright-text">
                Hand Crafted by
                <a href="http://travlogy.co" target="_blank">
                  <img src={travlogy_logo} />
                </a>
              </span>
            </Grid>
            <Grid item className="copyright-grid">
              <img src={payments} />
            </Grid>
            <Grid item >
              <img src={godaddy_secure} style={{ height: 40, border: "1px solid" }} />
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

export default withRouter(PageFooter);

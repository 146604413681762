import React from "react";
import {
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  useTheme,
  useMediaQuery,
  Grid,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";

import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import FlightItinSection from "./FlightItinSection";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { allAirlines } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .form-wrapper": {
      paddingBottom: "16px",
    },
    "& .flight-section-primary": {
      width: "100%",
    },

    padding: 0,
    margin: 0,
    "& .form-group-row": {
      diplay: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .form-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
    },
    "& .flight-section-flightway": {
      margin: "0 0 10px",
      "& .MuiFormGroup-root": {
        flexDirection: "row",
        justifyContent: "space-between",
        "& .MuiFormControlLabel-label": {
          color: theme.palette.primary.darkText,
          fontWeight: "400",
          fontSize: 12,
        },
      },
    },
    "& .fs-radio-group": {
      display: "flex",
      justifyContent: "flex-start !important",
      flexWrap: "nowrap",
      flexDirection: "row",
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: theme.palette.secondary.main,
      },
      "& .MuiFormGroup-root": {
        marginBottom: "10px",
        width: "100%",
      },
      "& .MuiGrid-item": {
        width: "100%",
      },
      "& .MuiRadio-root": {
        display: "none",
      },
      "& .MuiFormControlLabel-root": {
        width: 80,
        justifyContent: "center",
        margin: 3,
        height: 27,
        padding: 10,
        // width:60,
        color: "#000",
      },
      "& .MuiTypography-body1": {
        fontSize: 14,
      },

      "& .MuiFormControlLabel-root:has(.Mui-checked)": {
        width: 80,
        // backgroundColor: 'theme.palette.background.light',
        backgroundColor: "#323467",
        borderRadius: 20,
        "& .MuiFormControlLabel-label": {
          // color:  theme.palette.primary.main,
          //   color: theme.palette.primary.darkText,
          color: "#fff",
          fontWeight: "400",
          // width:60,
        },
      },
    },
    "& .ac-options-list": {
      overflow: "hidden",
      width: "100%",
      "& p": {
        marginBottom: 0,
        color: theme.palette.primary.darkFadedText,
        whiteSpace: "nowrap",
      },
      "& span": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        "& .ac-option-left": {
          textAlign: "left",
          color: theme.palette.primary.darkText,
          fontWeight: 600,
          flexGrow: 1,
        },
        "& .ac-option-right": {
          textAlign: "right",
          color: theme.palette.primary.darkFadedText,
        },
      },
    },
    "& .flight-section-secondary": {
      "& .quick-links-container": {
        marginTop: 20,
        justifyContent: "flex-start",
        [theme.breakpoints.down(600)]: {
          flexWrap: "wrap",
          justifyContent: "center",
        },
        "& .quick-link-item": {
          margin: 5,
          padding: "3px 5px",
          color: theme.palette.primary.darkText,
          border: `solid 1px ${theme.palette.secondary.lightText}`,
          boxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
          borderRadius: 4,
          cursor: "pointer",
          textAlign: "center",
          display: "flex",
          columnGap: "0.3rem",
          justifyContent: "center",
          background: "rgba(255,255,255,0.23)",
          // width: 122,
          "&:hover": {
            backgroundColor: theme.palette.secondary.darkOrange,
            color: theme.palette.primary.lightText,
          },
          "& .qlink-icon-wrapper": {
            textAlign: "center",
          },
          "& .qlink-label-wrapper": {
            fontSize: 10,
          },
        },
      },
    },

    "& .flight-section-stops": {
      marginTop: 20,
    },
  },

  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.primary.defaultText,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.lightText,
      backgroundColor: theme.palette.primary.main,

      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.light,
    },
    "& .MuiToggleButton-sizeSmall": {
      height: 24,
      minWidth: 75,
      [theme.breakpoints.down(840)]: {
        minWidth: 0,
      },
      [theme.breakpoints.down(614)]: {
        minWidth: 75,
      },
      [theme.breakpoints.down(358)]: {
        marginBottom: 20,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      [theme.breakpoints.down(358)]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  fareTypeBox: {
    // height: 60,
    width: 150,
    padding: 10,
    paddingRight: 0,
    paddingTop: 2,
    paddingBottom: 1,
    border: "1px solid #eee",
    borderRadius: 8,
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 700,
    backgroundColor: "rgba(255,255,255,0.5)",
    cursor: "pointer",

    "& .fas": {
      marginLeft: 10,
    },
  },
  fareTypeContent: {
    backgroundColor: "#fff",
    padding: 10,
    paddingTop: 0,
    paddingBottom: 0,
    width: 150,
    marginLeft: 10,
    borderRadius: 5,
    boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
    "& .MuiIconButton-label": {
      display: "none",
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      marginLeft: 10,
      fontWeight: 500,
      marginBottom: 5,
    },
    position: "absolute",
  },
}));

const FlightSection = (props) => {
  const formikRef = React.useRef();
  const isMounted = React.useRef(false);

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [userType, setUserType] = React.useState(null);
  const [corporateType, setCorporateType] = React.useState(null);
  const [partnerId, setPartnerId] = React.useState(null);
  const [clientType, setClientType] = React.useState(null);
  const [showFareType, setShowFareType] = React.useState(false);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();
  const today = new Date();
  let totalTravelers = 0;

  const initialParams = {
    adultPax: 1,
    childPax: 0,
    infantPax: 0,
    mode: "ONE",
    preferredAirline: [allAirlines],
    preferredclass: "E",
    returnDate: dateFnsFormat(endDate, "yyyy-MM-dd"),
    returnDateDisplay: endDate,
    isNonStop: false,
    fareType: "Regular",

    sectors: [
      {
        departDate: dateFnsFormat(startDate, "yyyy-MM-dd"),
        departDateDisplay: startDate,
        destination: popularCities[1].airportCode,
        destinationObj: popularCities[1],
        origin: popularCities[0].airportCode,
        originObj: popularCities[0],
      },
    ],
  };

  const valSchema = Yup.object().shape({
    preferredclass: Yup.string().required("Mandatory"),
    adultPax: Yup.number().required(),
    childPax: Yup.number().required(),
    infantPax: Yup.number().required(),
    sectors: Yup.array().of(
      Yup.object().shape({
        destination: Yup.string().required("Mandatory"),
        origin: Yup.string().required("Mandatory"),
      })
    ),
  });

  const handleSubmitTravelData = (values) => {
    values.clientType = clientType;
    values.partnerId = partnerId;
    //console.log("values ", values);

    window.sessionStorage.removeItem("service");
    window.sessionStorage.setItem("fetching", true);
    history.push({
      pathname: "/flight/search-progress",
      state: values,
    });
  };

  const openQuicklink = (quicklinktype) => {
    switch (quicklinktype) {
      case "flights-report": {
        history.push("/admin/booking-report/flight");
        break;
      }

      case "travel-calendar": {
        window.openPage("travel-calendar");
        break;
      }

      case "cancellation": {
        history.push("/admin/booking-report/flight");
        break;
      }

      case "group-booking": {
        history.push("/admin/groupbooking");
        break;
      }

      case "airlines-contact": {
        // window.openPage("airlines-contact");
        history.push("/flight/airlinecontacts");
        break;
      }

      case "web-checkin": {
        // window.openPage("web-checkin");
        history.push("/flight/webcheckin");
        break;
      }
    }
  };

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (isMounted.current && details) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
        setPartnerId(details.partnerId);

        switch (details.userTypeAbv) {
          case "S": {
            setClientType("Corporate");
            break;
          }
          case "G": {
            setClientType("Normal");
            break;
          }
          default: {
            setClientType("Normal");
            break;
          }
        }
      }
    });
  }, [isMounted.current]);

  return (
    <div className={classes.root}>
      <div className="notification-container">
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
      </div>

      <div className="form-wrapper">
        <Formik
          innerRef={formikRef}
          initialValues={initialParams}
          validationSchema={valSchema}
          onSubmit={(values) => handleSubmitTravelData(values)}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div className="flight-section-flightway">
                <RadioGroup
                  className="fs-radio-group"
                  value={values.mode}
                  name={`mode`}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setFieldValue(`mode`, e.target.value);
                  }}
                >
                  {flightWayList.map((a) => (
                    <FormControlLabel
                      key={a.val}
                      value={a.val}
                      control={<Radio color="secondary" />}
                      label={a.text}
                    />
                  ))}
                </RadioGroup>
                <ErrorMessage name="mode" component="div" className="error" />
              </div>

              <div className="flight-section-primary">
                <FieldArray
                  name="sectors"
                  render={(arrayHelpers) => (
                    <FlightItinSection
                      arrayhelpers={arrayHelpers}
                      values={values}
                      setfieldvalue={setFieldValue}
                      formikref={formikRef}
                      setiserror={(val) => setIsError(val)}
                      popularcities={popularCities}
                    />
                  )}
                />
              </div>

              <div className="flight-section-stops" style={{ display: "flex", flexDirection: "row" }}>
                <div className={classes.toggleBox}>
                  <ToggleButtonGroup
                    name="isNonStop"
                    size="small"
                    // value={true}
                    value={values.isNonStop}
                    exclusive
                    // onChange={(e, v) => v && setData({ ...data, nonStop: v })}
                    onChange={(e, v) => setFieldValue("isNonStop", v)}
                  >
                    <ToggleButton value={false}>Stop</ToggleButton>
                    <ToggleButton value={true}>Non-Stop</ToggleButton>,
                  </ToggleButtonGroup>
                  <ErrorMessage
                    name="isNonStop"
                    component="div"
                    className="error"
                  />
                </div>

                <div >
                  <div
                    className={classes.fareTypeBox}
                    onClick={() => setShowFareType(!showFareType)}
                  >
                    <span>{values.fareType} Fare Type</span>
                    {"   "}
                    <i className="fas fa-chevron-down"></i>
                  </div>
                  {showFareType && (
                    <div className={classes.fareTypeContent}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="method"
                          name="method"
                          value={values.fareType}
                          onChange={
                            (event) => {
                              setFieldValue("fareType", event.target.value);
                              setShowFareType(!showFareType);
                            }

                            // props.setselectedpaymentmethod(event.target.value)

                            // console.log("first")
                          }
                        >
                          <FormControlLabel
                            value="Regular"
                            control={<Radio />}
                            label={`Regular Fare`}
                          />
                          <FormControlLabel
                            value="Student"
                            control={<Radio />}
                            label={`Student Fare`}
                          />
                          <FormControlLabel
                            value="SeniorCitizen"
                            control={<Radio />}
                            label={`SeniorCitizen Fare`}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>

              {/* 
              <div className="flight-section-secondary">
                <Grid container className="quick-links-container">
                  {userType && userType == "agent" && (
                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("flights-report")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-plane"></i>
                      </div>
                      <div className="qlink-label-wrapper">FLIGHTS REPORT</div>
                    </Grid>
                  )}

                  {userType && userType == "agent" && (
                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("travel-calendar")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-calendar"></i>
                      </div>
                      <div className="qlink-label-wrapper">TRAVEL CALENDER</div>
                    </Grid>
                  )}

                  {userType && userType == "agent" && (
                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("cancellation")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-ban"></i>
                      </div>
                      <div className="qlink-label-wrapper">CANCELLATION</div>
                    </Grid>
                  )}

                  <Grid
                    item
                    className="quick-link-item"
                    onClick={() => openQuicklink("web-checkin")}
                  >
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-globe"></i>
                    </div>
                    <div className="qlink-label-wrapper">WEB CHECK IN</div>
                  </Grid>

                  <Grid
                    item
                    className="quick-link-item"
                    onClick={() => openQuicklink("airlines-contact")}
                  >
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="qlink-label-wrapper">AIRLINES CONTACT</div>
                  </Grid>

                  <Grid
                    item
                    className="quick-link-item"
                    onClick={() => openQuicklink("group-booking")}
                  >
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-users"></i>
                    </div>
                    <div className="qlink-label-wrapper">GROUP BOOKING</div>
                  </Grid>
                </Grid>
              </div> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FlightSection;

const flightWayList = [
  { val: "ONE", text: "One Way" },
  { val: "ROUND", text: "Round Trip" },
  // { val: "ROUNDSP", text: "Round Trip Special" },
  { val: "MULTICITY", text: "Multi City" },
];

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

import React from "react";
import OaPopperAutocomplete from "oahoc/OaPopperAutocomplete";
import OaPopperDatepicker from "oahoc/OaPopperDatepicker";
import WebApi from "api/ApiConstants";
import ClearIcon from "@material-ui/icons/Clear";
import dateFnsFormat from "date-fns/format";
import {
  Box,
  Grid,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  FormControl,
  Button,
  Popper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";

const FLightMultiCitySection = (props) => {
  const [airportList, setAirportList] = React.useState(props.popularcities);
  const [fromCountry, setFromCountry] = React.useState("");
  const [toCountry, setToCountry] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const index = props.indx + 1;
  const indx = props.indx;
  const [changeQueryOrin, SetChangeQueryOrin] = React.useState(false);
  const [changeQueryDist, SetChangeQueryDist] = React.useState(false);

  const fetchAirports = (searchParams) => {
    setIsLoading(true);
    if (searchParams.length >= 3) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams,
        },
        (response) => {
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        },
        (error) => console.log("fetchAirportList error", error)
      );
    }
  };

  const customPopperFrom = function(props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };

  const customPopperTo = function(props) {
    return <Popper {...props} placement="bottom" style={{}} />;
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const renderOptionsComp = (optionsData, propes) => {
    return (
      <div selected={propes.selected} className="ac-options-list">
        <span>
          <p className="ac-option-left">
            {optionsData.cityName} ({optionsData.airportCode})
          </p>
          <p className="ac-option-right">{optionsData.countryCode}</p>
        </span>
        <p>{optionsData.airportDesc}</p>
      </div>
    );
  };

  // const pushBtn = (array, index) => {
  //   const initObj = {
  //     //   departDateDisplay: new Date(),
  //     //   departDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
  //     departDate: dateFnsFormat(
  //       props.values.sectors[props.values.sectors.length * 1 - 1]
  //         .departDateDisplay,
  //       "yyyy-MM-dd"
  //     ),
  //     departDateDisplay:
  //       props.values.sectors[props.values.sectors.length * 1 - 1]
  //         .departDateDisplay,
  //     destination: props.popularcities[2].airportCode,
  //     destinationObj: props.popularcities[2],
  //     origin: props.popularcities[5].airportCode,
  //     originObj: props.popularcities[5],
  //   };
  //   array.push(initObj);
  // };

  const pushBtn = (array, values, index) => {
    const initObj = {
      departDate: dateFnsFormat(
        props.values.sectors[props.values.sectors.length * 1 - 1]
          .departDateDisplay,
        "yyyy-MM-dd"
      ),
      departDateDisplay:
        props.values.sectors[props.values.sectors.length * 1 - 1]
          .departDateDisplay,
      destination: index > 0 ? values[index].origin:props.popularcities[2].airportCode,
      destinationObj: index > 0 ? values[index].originObj:props.popularcities[2],
      origin: index > 0 ? values[index].destination : props.popularcities[4].airportCode,
      originObj: index  > 0 ? values[index].destinationObj : props.popularcities[4],
      destination: props.values.sectors[props.values.sectors.length - 1].origin,
      destinationObj: props.values.sectors[props.values.sectors.length - 1].originObj,
      origin: props.values.sectors[props.values.sectors.length - 1].destination,
      originObj: props.values.sectors[props.values.sectors.length - 1].destinationObj,
    };
    array.push(initObj);
  };



  React.useEffect(() => {
    if (airportList && airportList > 0) {
      setIsLoading(false);
    }
  }, [airportList]);

  return (
    <div className="itin-row">
      <div className="itin-col-50">
        <div className="inner-row">
          <div className="itin-col-50 hover location-wrapper border-right border-top">
            <OaPopperAutocomplete
              loading={isLoading}
              className="autocomplete"
              name={`sectors[${index}].originObj`}
              PopperComponent={customPopperFrom}
              options={airportList || []}
              getOptionLabel={(o) =>
                o ? `${o.cityName} ${o.airportCode}` : ""
              }
              // value={props.values.sectors[index].originObj || ""}
              openOnFocus
              getOptionSelected={(option, value) =>
                option.airportCode == value.airportCode
              }
              onKeyUp={(event) => fetchAirports(event.target.value.trim())}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setAirportList(props.popularcities);
                } else {
                  changeHandler("from", newValue?.countryCode);
                  props.setfieldvalue(`sectors[${index}].originObj`, newValue);
                  props.setfieldvalue(
                    `sectors[${index}].origin`,
                    newValue.airportCode
                  );
                  SetChangeQueryOrin(true)  
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  color="secondary"
                  inputProps={{
                    ...params.inputProps,
                    autocomplete: "new-password",
                  }}
                />
              )}
              renderOption={(optionsData, propes) =>
                renderOptionsComp(optionsData, propes)
              }
              disablePortal={true}
              // secondarytxt={
              //   (props.values.sectors[index].originObj &&
              //     `[${props.values.sectors[index].originObj.airportCode}] ${props.values.sectors[index].originObj.airportDesc} ${props.values.sectors[index].originObj.countryCode}`) ||
              //   ""
              // }
              secondarytxt={
                changeQueryOrin?(props.values.sectors[index].originObj &&
                `[${props.values.sectors[index].originObj.airportCode}] ${props.values.sectors[index].originObj.airportDesc} ${props.values.sectors[index].originObj.countryCode}`):(props.values.sectors[indx].destinationObj &&
                  `[${props.values.sectors[indx].destinationObj.airportCode}] ${props.values.sectors[indx].destinationObj.airportDesc} ${props.values.sectors[indx].destinationObj.countryCode}`) || ""
                  
              }
              labeltxt="FROM"
              inputtext={
                //  props.values.sectors[index].originObj?.cityName || ""
                  changeQueryOrin?props.values.sectors[index].originObj?.cityName : props.values.sectors[indx].destinationObj?.cityName || ""
                }
              type="depart"
              clearonfocus={"true"}
            />
          </div>
          <div className="itin-col-50 hover location-wrapper border-right border-top pdl-15">
            <OaPopperAutocomplete
              loading={isLoading}
              className="autocomplete"
              name={`sectors[${index}].destinationObj`}
              PopperComponent={customPopperTo}
              options={airportList || []}
              getOptionLabel={(o) =>
                o ? `${o.cityName} ${o.airportCode}` : ""
              }
              // value={props.values.sectors[index].destinationObj || ""}
              openOnFocus
              getOptionSelected={(option, value) =>
                option.airportCode == value.airportCode
              }
              onKeyUp={(event) => {
                fetchAirports(event.target.value.trim());
              }}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setAirportList(props.popularcities);
                } else {
                  changeHandler("from", newValue?.countryCode);
                  props.setfieldvalue(
                    `sectors[${index}].destinationObj`,
                    newValue
                  );
                  props.setfieldvalue(
                    `sectors[${index}].destination`,
                    newValue.airportCode
                  );
                  SetChangeQueryDist(true)    
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  color="secondary"
                  inputProps={{
                    ...params.inputProps,
                    autocomplete: "new-password",
                  }}
                />
              )}
              renderOption={(optionsData, propes) =>
                renderOptionsComp(optionsData, propes)
              }
              disablePortal={true}
              secondarytxt={
                changeQueryDist?(props.values.sectors[index].destinationObj &&
                  `[${props.values.sectors[index].destinationObj.airportCode}] ${props.values.sectors[index].destinationObj.airportDesc} ${props.values.sectors[index].destinationObj.countryCode}`):""
              }
              labeltxt="TO"
              inputtext={
                changeQueryDist?props.values.sectors[index].destinationObj?.cityName : ""
              }
              type="arrival"
              clearonfocus={"true"}
            />
          </div>
        </div>
      </div>
      <div className="itin-col-50">
        <div className="inner-row">
          <div className="itin-col-25 hover date-wrapper border-right border-top">
            <OaOutlinedDatePicker
              showDisabledMonthNavigation
              className="date-picker"
              name={`sectors[${index}].departDateDisplay`}
              minDate={
                index == 0
                  ? new Date()
                  : props.values.sectors[index - 1].departDateDisplay
              }
              //   minDate={props.values.sectors[0].departDateDisplay}
              customInput={
                <TextField
                  id="standard-basic"
                  variant="standard"
                  fullWidth={props.ismobilescreen}
                />
              }
              selected={
                props.values.sectors[index].departDateDisplay
                  ? props.values.sectors[index].departDateDisplay
                  : props.values.sectors[0].departDateDisplay
              }
              onChange={(e, v) => {
                props.setfieldvalue(`sectors[${index}].departDateDisplay`, e);
                // props.setfieldvalue(
                //     `sectors[${index}].departDate`,
                //     dateFnsFormat(e, "yyyy-MM-dd")
                // );
              }}
              monthsShown={2}
              dateFormat="dd MMM yyyy"
              labeltxt="DEPARTURE DATE"
              secondarytxt={
                props.days[
                  props.values.sectors[index].departDateDisplay.getDay()
                ]
              }
              showinput={false}
            />
          </div>
          <div
            className={`itin-col-25 hover border-right ${index == 1 &&
              "border-top"}`}
          >
            {props.values.sectors.length == index + 1 && (
              <div className="muct-action-wrapper">
                <Button type="submit" className="multicity-search-btn">
                  SEARCH
                </Button>

                {props.values.sectors.length < 4 && (
                  <a
                    className="multicity-sector-add"
                    onClick={() => pushBtn(props.arrayhelpers, index)}
                  >
                    + ADD CITY
                  </a>
                )}

                {index > 1 && (
                  <a
                    className="multicity-sector-remove"
                    onClick={() => props.arrayhelpers.remove(index)}
                  >
                    <ClearIcon />
                  </a>
                )}
              </div>
            )}
          </div>

          <div className="itin-col-50"></div>
        </div>
      </div>
    </div>
  );
};

export default FLightMultiCitySection;

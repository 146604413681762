import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
    getQueryVar,
    getCurrentTab,
    getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.DoneTrip";
import PageFooter from "components/Footer/PageFooter.DoneTrip";
import Container from "@material-ui/core/Container";

import SpecialOffers from "pages/module/flight/fixedDeparture/SpecialOffers";
import SpecialOfferDetailTable from "pages/module/flight/fixedDeparture/SpecialOfferTable";
import FlightDetails from "pages/module/flight/fixedDeparture/FlightDetails";

const useStyles = makeStyles(styles);

const SpecialOffersLayout = (props) => {
    const { ...rest } = props;
    const classes = useStyles();
    React.useEffect(() => {
        document.body.style.overflow = "unset";
        let qAgencyId = getAgencyId(props);
        setAgencyId(qAgencyId);
        return function cleanup() { };
    }, [agencyId, props]);

    const [agencyId, setAgencyId] = useState(undefined);

    let defaultTab = "hotel";
    let queryVar = getQueryVar({ ...props, q: "priTab" });
    let currentTab = getCurrentTab(queryVar, defaultTab);

    return (
        <div
            className={classes.wrapper}
            style={{ backgroundColor: "#fff", width: `100%`, height: `100%` }}
        >
            <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />

            <div style={{ marginBottom: 20 }}>
                <Switch>
                    <Route exact path="/fd/hotdeals" component={SpecialOffers} />
                    <Route
                        exact
                        path="/fd/hotdeals/details"
                        component={SpecialOfferDetailTable}
                    />
                    <Route
                        exact
                        path="/fd/hotdeals/flightdetails"
                        component={FlightDetails}
                    />
                    <Route component={SpecialOffers} />
                </Switch>
            </div>

            <PageFooter white />
        </div>
    );
}

export default SpecialOffersLayout;
import React, { useState, useEffect } from "react";
import "layouts/DoneTrip/style.css";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles } from "@material-ui/core";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.DoneTrip";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter.DoneTrip";
import FlightSearch from "pages/module/flight/compact/FlightSearch";
import FlightSearchProgress from "pages/module/flight/compact/FlightSearchProgress";

import WebCheckIn from "pages/module/flight/webCheckIn";
import AirlineContact from "pages/module/flight/airlineContact";
import FlightBookingProcess from "pages/module/flight/compact/FlightBookingProcess";
import FlightSearchComRes from "pages/module/flight/compact/FlightSearchComRes";
import FlightPaymentStatus from "pages/module/flight/compact/FlightPaymentStatus";
// import FlAvLoader from "assets/doneTrip/images/loaders/flight_avail_loader.gif";
import FlAvLoader from "assets/doneTrip/images/loaders/Airplane_GIF.gif";
import ComLoader from "assets/doneTrip/images/loaders/clock_loader.gif";
import TermsAndConditions from "components/LandingPage/DoneTrip/siteinfo/TermsAndConditions";
import PricingPolicy from "components/LandingPage/DoneTrip/siteinfo/PricingPolicy";

// const useStyles = makeStyles(styles);

const FlightLayout = (props) => {
  const { ...rest } = props;
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);

  return (
    <>
      <PostLoginNavBar />
      {/* classes.container */}
      <div className={classes.root}>
        <Switch>
          <Route
            path="/flight/search-progress"
            render={(prop) => (
              <FlightSearchProgress {...prop} availoader={FlAvLoader} />
            )}
          />
          <Route
            path="/flight/search-result"
            render={(prop) => <FlightSearch {...prop} availoader={ComLoader} comloader={ComLoader} />}
          />
          <Route path="/flight/webcheckin" component={WebCheckIn} />
          <Route path="/flight/booking">
            <FlightBookingProcess
              {...props}
              comloader={ComLoader}
              tnc={TermsAndConditions}
              ppc={PricingPolicy}
            />
          </Route>
          <Route path="/flight/airlinecontacts" component={AirlineContact} />
          <Route
            path="/flight/international/search-result"
            render={(prop) => (
              <FlightSearchComRes {...prop} availoader={ComLoader} comloader={ComLoader}/>
            )}
          />
          <Route
            path="/flight/payment-status/:tid"
            component={FlightPaymentStatus}
          />
        </Switch>
      </div>
      <PageFooter />
    </>
  );
};

export default FlightLayout;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.lightGrayDefault,
    color: theme.palette.background.lightGrayDefaultContrast,
    minHeight: "75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

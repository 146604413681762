import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  Popper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import adultIcon from "assets/icons/person_black.svg";
import phoneIcon from "assets/icons/phone_icon.svg";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import countries from "json/country_dial_codes.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OaAlert from "oahoc/OaAlert";

// import Passenger from "./Passenger-dob-dropdown";
import Passenger from "./Passenger";
import * as Yup from "yup";
import GstDetails from "./GstDetails";
import { useHistory } from "react-router-dom";
import OaTextFieldAdorned from "oahoc/OaTextFieldAdorned";

const PassengerInformation = (props) => {
  // console.log("passengerInformatin props", props)
  const history = useHistory();

  const classes = useStyles();
  const formikRef = React.useRef();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [isInternationalTrip, setIsInternationalTrip] = useState(false);
  const [editDialCode, setEditDialCode] = useState(false);

  const propState = props.history.location.state;
  const [isGSTMandate, setIsGSTMandate] = React.useState(false);
  const onwardObj = propState?.onwardResp;
  const returnObj = propState?.returnResp;

  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  // console.log(propState?.requestObj?.fareType);

  const customPopper = function(props) {
    return <Popper {...props} placement="bottom" style={{}} />;
  };

  const schema = Yup.object().shape({
    isDomestic: Yup.boolean(),
    childCount: Yup.number(),
    infantCount: Yup.number(),
    adultPax: Yup.array().of(
      Yup.object().shape({
        initial: Yup.string()
          .typeError("Mandatory")
          .required("Mandatory")
          .nullable(),
        firstname: Yup.string()
          .required("Mandatory")
          .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid First Name"),
        surname: Yup.string()
          .required("Mandatory")
          .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid Last Name"),
        dobDisplay:
          props.paxinfo.isDomestic == false
            ? Yup.date()
                .typeError("invalid date")
                .required("Mandatory")
            : Yup.date().nullable(true),
        passportno:
          props.paxinfo.isDomestic == false
            ? Yup.string().required("Mandatory")
            : Yup.string(),
        passportidate:
          props.paxinfo.isDomestic == false
            ? Yup.date()
                .typeError("invalid date")
                .required("Mandatory")
            : Yup.date().nullable(true),
        passportexpire:
          props.paxinfo.isDomestic == false
            ? Yup.date()
                .typeError("invalid date")
                .required("Mandatory")
            : Yup.date().nullable(true),
        passporticountry:
          props.paxinfo.isDomestic == false
            ? Yup.string().required("Mandatory")
            : Yup.string(),
      })
    ),
    childPax: Yup.array().when("childCount", {
      is: (v) => {
        return v > 0;
      },
      then: Yup.array().of(
        Yup.object({
          initial: Yup.string()
            .typeError("Mandatory")
            .required("Mandatory")
            .nullable(),
          firstname: Yup.string()
            .required("Mandatory")
            .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid First Name"),
          surname: Yup.string()
            .required("Mandatory")
            .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid Last Name"),
          dobDisplay: Yup.date()
            .typeError("invalid date")
            .required("mandatory"),
          passportno:
            props.paxinfo.isDomestic == false
              ? Yup.string().required("Mandatory")
              : Yup.string(),
          passportidate:
            props.paxinfo.isDomestic == false
              ? Yup.date()
                  .typeError("invalid date")
                  .required("Mandatory")
              : Yup.date().nullable(true),
          passportexpire:
            props.paxinfo.isDomestic == false
              ? Yup.date()
                  .typeError("invalid date")
                  .required("Mandatory")
              : Yup.date().nullable(true),
          passporticountry:
            props.paxinfo.isDomestic == false
              ? Yup.string().required("Mandatory")
              : Yup.string(),
        })
      ),
    }),
    infantPax: Yup.array().when("infantCount", {
      is: (v) => {
        return v > 0;
      },
      then: Yup.array().of(
        Yup.object({
          initial: Yup.string()
            .typeError("Mandatory")
            .required("Mandatory")
            .nullable(),
          firstname: Yup.string()
            .required("Mandatory")
            .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid First Name"),
          surname: Yup.string()
            .required("Mandatory")
            .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid Last Name"),
          dobDisplay: Yup.date()
            .typeError("invalid date")
            .required("mandatory"),
          passportno:
            props.paxinfo.isDomestic == false
              ? Yup.string().required("Mandatory")
              : Yup.string(),
          passportidate:
            props.paxinfo.isDomestic == false
              ? Yup.date()
                  .typeError("invalid date")
                  .required("Mandatory")
              : Yup.date().nullable(true),
          passportexpire:
            props.paxinfo.isDomestic == false
              ? Yup.date()
                  .typeError("invalid date")
                  .required("Mandatory")
              : Yup.date().nullable(true),
          passporticountry:
            props.paxinfo.isDomestic == false
              ? Yup.string().required("Mandatory")
              : Yup.string(),
        })
      ),
    }),
    phoneno: Yup.string()
      .typeError("Enter a valid mobile number")
      .required("Mandatory")
      .matches(phoneRegex, "Invalid phone number"),
    emailUser: Yup.string()
      .required("Mandatory")
      .typeError("Enter a valid email")
      .email("Enter a valid email"),
    hasGst: Yup.boolean(),
    gstNumber: Yup.string().when("hasGst", {
      is: true,
      then: Yup.string()
        .typeError("You must specify a GST No")
        .required("GST No is required"),
    }),
    gstCompany: Yup.string().when("hasGst", {
      is: true,
      then: Yup.string()
        .typeError("You must specify a Company")
        .required("name is required"),
    }),
    gstCompanyNumber: Yup.string().when("hasGst", {
      is: true,
      then: Yup.string()
        .typeError("You must specify a CompanyNumber")
        .required("company phone number is required"),
    }),
    gstCompanyAddress: Yup.string().when("hasGst", {
      is: true,
      then: Yup.string()
        .typeError("You must specify a address")
        .required("company address is required"),
    }),
    gstEmail: Yup.string().when("hasGst", {
      is: true,
      then: Yup.string()
        .typeError("You must specify an Email")
        .required("Company email is required"),
    }),
  });

  const checkSME = () => {
    if (returnObj && propState.requestObj.isDomestic) {
      if (
        returnObj.fares[0]?.fareTypeOriginal !== null &&
        returnObj.fares[0]?.fareTypeOriginal != ""
      ) {
        // if (returnObj.fares[0]?.fareTypeOriginal.startsWith("SME")) {
        //   setIsGSTMandate(true);
        // } else {
        //   setIsGSTMandate(false);
        // }
        if (returnObj.fares[0]?.isGSTMandatory) {
          setIsGSTMandate(true);
        } else {
          setIsGSTMandate(false);
        }
      }
    }
    if (onwardObj) {
      if (
        onwardObj.fares[0]?.fareTypeOriginal !== null &&
        onwardObj.fares[0]?.fareTypeOriginal != ""
      ) {
        // if (onwardObj.fares[0]?.fareTypeOriginal.startsWith("SME")) {
        //   setIsGSTMandate(true);
        // } else {
        //   setIsGSTMandate(false);
        // }
        if (onwardObj.fares[0]?.isGSTMandatory) {
          setIsGSTMandate(true);
        } else {
          setIsGSTMandate(false);
        }
      }
    }
  };

  const validateIndividualPaxDetails = (values, totalPax, paxType) => {
    let allFieldNotOk = true;
    let paxInfo = values.adultPax;
    if (paxType === "Adult") {
      paxInfo = values.adultPax;
    } else if (paxType === "Child") {
      paxInfo = values.childPax;
    } else if (paxType === "Infant") {
      paxInfo = values.infantPax;
    }
    for (let k = 0; k < totalPax; k++) {
      // console.log("values.isDomestic" , paxInfo[k]);

      let paxTitle = paxInfo[k].initial;
      let paxFname = paxInfo[k].firstname;
      let paxLname = paxInfo[k].surname;

      // console.log("paxTitle" ,paxInfo[k]);
      if (paxTitle === "-1") {
        setAlertMsg(
          "Please select the title of the " + paxType + " -" + (k + 1)
        );

        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (paxFname === "") {
        setAlertMsg(
          "Please Enter the firstname of the " + paxType + " -" + (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (!/^[A-Za-z ]*$/.test(paxFname)) {
        setAlertMsg(
          "Firstname should contains Alphabates and space only of the " +
            paxType +
            " -" +
            (k + 1)
        );
        allFieldNotOk = false;
        break;
      } else if (!/^[^\s][A-Za-z ]*$/.test(paxFname)) {
        setAlertMsg(
          "Firstname cant be start with space of the " +
            paxType +
            " -" +
            (k + 1)
        );
        allFieldNotOk = false;
        break;
      }

      if (paxFname.length < 2) {
        setAlertMsg(
          "Firstname should not be less than 2 char of the " +
            paxType +
            " -" +
            (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (paxLname === "") {
        setAlertMsg(
          "Please select the lastname of the " + paxType + " -" + (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (!/^[A-Za-z ]*$/.test(paxLname)) {
        setAlertMsg(
          "Lastname should contains Alphabates and space only of the " +
            paxType +
            " -" +
            (k + 1)
        );
        allFieldNotOk = false;
        break;
      }
      if (!/^[^\s][A-Za-z ]*$/.test(paxLname)) {
        setAlertMsg(
          "Lastname cant be start with space of the " + paxType + " -" + (k + 1)
        );

        allFieldNotOk = false;
        break;
      }
      if (paxType === "Child" || paxType === "Infant" || !values.isDomestic) {
        if (paxInfo[k].dob === "") {
          setAlertMsg(
            "Please select Date of birth of the " + paxType + " -" + (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
      }

      if (!values.isDomestic) {
        let passportno = paxInfo[k].passportno;
        let passportidate = paxInfo[k].passportidate;
        let passportexpire = paxInfo[k].passportexpire;
        let passporticountry = paxInfo[k].passporticountry;
        //console.log("passportno" + passportno +","+passportidate + ","+passportexpire + "," +passporticountry);
        if (passportidate === null) passportidate = "";
        if (passportexpire === null) passportexpire = "";
        //  console.log("passportno" + passportno +","+passportidate + ","+passportexpire + "," +passporticountry);

        if (passportno === "") {
          setAlertMsg(
            "Please Enter Passport Number of the " + paxType + " -" + (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
        if (passportidate === "") {
          setAlertMsg(
            "Please Enter Passport Issue Date of the " +
              paxType +
              " -" +
              (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
        if (passportexpire === "") {
          setAlertMsg(
            "Please Enter Passport Expire Date of the " +
              paxType +
              " -" +
              (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
        if (passporticountry === "") {
          setAlertMsg(
            "Please Enter Passport Issue country Date of the " +
              paxType +
              " -" +
              (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
      }
      //console.log("paxTitle" + paxTitle +"," +paxFname  +","+ paxLname);
    }
    return allFieldNotOk;
  };

  const validatePaxDetails = (values) => {
    let noErrorFound = false;

    // console.log("values", values);
    // return;
    let adults = values.adultCount;
    let child = values.childCount;
    let infants = values.infantCount;

    noErrorFound = validateIndividualPaxDetails(values, adults, "Adult");

    if (noErrorFound) {
      if (child > 0) {
        noErrorFound = validateIndividualPaxDetails(values, child, "Child");
      }
    }
    if (noErrorFound) {
      if (infants > 0) {
        noErrorFound = validateIndividualPaxDetails(values, infants, "Infant");
      }
    }
    if (noErrorFound) {
      if (values.phoneno === "") {
        setAlertMsg("Mobile Number should not be empty ");
        noErrorFound = false;
      }
      if (noErrorFound) {
        if (!/^[0-9]*$/.test(values.phoneno)) {
          setAlertMsg("Mobile Number should contains Numbers only ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (
          values.country_dial_code === "+91" &&
          values.phoneno.length !== 10
        ) {
          setAlertMsg("Mobile Number should be 10 digits Numbers only ");
          noErrorFound = false;
        }
      }

      if (noErrorFound) {
        if (values.emailUser === "") {
          setAlertMsg("Email Id should not be empty ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailUser)
        ) {
          setAlertMsg("Invalid Email Id ");
          noErrorFound = false;
        }
      }

      if (noErrorFound) {
        // console.log("isGSTMandate ",isGSTMandate);
        let hasGst = values.hasGst;

        // if(isGSTMandate)
        // {
        //   if(!hasGst)
        //   {
        //     alert("GST Details should not be blank ");
        //     noErrorFound = false;
        //   }
        // }

        if (hasGst) {
          // && noErrorFound
          let gstNumber = values.gstNumber;
          let gstCompany = values.gstCompany;
          let gstCompanyNumber = values.gstCompanyNumber;
          let gstCompanyAddress = values.gstCompanyAddress;
          let gstEmail = values.gstEmail;

          gstNumber = gstNumber.toUpperCase();
          let reg = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/;

          if (gstNumber === "") {
            setAlertMsg("GST Should not be blank ");
            noErrorFound = false;
          } else if (!reg.test(gstNumber)) {
            setAlertMsg("Invalid GST number ");
            noErrorFound = false;
          }

          if (noErrorFound) {
            if (gstCompany === "") {
              setAlertMsg("GST Company Should not be blank ");
              noErrorFound = false;
            }
          }
          if (noErrorFound) {
            if (gstCompanyNumber === "") {
              setAlertMsg("GST Company Contact Number Should not be blank ");
              noErrorFound = false;
            } else if (!/^[0-9]*$/.test(gstCompanyNumber)) {
              setAlertMsg(
                "Company Contact Number should contain Numbers only "
              );
              noErrorFound = false;
            }
          }
          if (noErrorFound) {
            if (gstCompanyAddress === "") {
              setAlertMsg("GST Company Address Should not be blank ");
              noErrorFound = false;
            }
          }
          if (noErrorFound) {
            if (gstEmail === "") {
              setAlertMsg("GST Company GST Email Should not be blank ");
              noErrorFound = false;
            }
            if (noErrorFound) {
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(gstEmail)) {
                setAlertMsg("Invalid Company GST Email Id ");
                noErrorFound = false;
              }
            }
          }

          //console.log(hasGst +"," + gstNumber);
        }
      }

      /*

      
      
        
      gstEmail: Yup.string().when("hasGst", {
        is: true,
        then: Yup.string()
          .typeError("You must specify an Email")
          .required("Company email is required"),
      }),
  */
    }

    return noErrorFound;
  };

  const passengerDetailsSubmit = (values) => {
    // console.log("values submit", values);
    // props.submitpax(values);

    if (validatePaxDetails(values)) {
      props.submitpax(values);
    } else {
      setAlertTitle("Fields Mandatory");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
    }
  };

  React.useEffect(() => {
    if (props.updatepaxinfo && formikRef.current) {
      props.setUpdatePaxInfo(false);
      formikRef.current.handleSubmit();
    }
  }, [props.updatepaxinfo]);

  React.useEffect(() => {
    if (props.paxinfo) {
      setIsInternationalTrip(!props.paxinfo.isDomestic);
    }
  }, [props.paxinfo]);

  React.useEffect(() => {
    if (isGSTMandate && formikRef.current) {
      formikRef.current.setFieldValue("hasGst", isGSTMandate);
    }
  }, [isGSTMandate]);

  React.useEffect(() => {
    checkSME();
  }, []);

  return (
    <Box className={classes.root}>
      {/* <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      /> */}
      {isError && (
        <Grid item md={12} xs={12}>
          <OaAlert
            isPrompt={isAlertPrompt}
            msg={alertMsg}
            title={alertTitle}
            isError={isError}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            togglePrompt={() => {
              setIsAlertPrompt(!isAlertPrompt);
            }}
          ></OaAlert>
        </Grid>
      )}

      <Formik
        innerRef={formikRef}
        initialValues={props?.paxinfo}
        //  validationSchema={schema}
        enableReinitialize
        onSubmit={passengerDetailsSubmit}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            {/* contact info entry section start */}

            <Grid container>
              <Grid item md={12}>
                <div className="pax-info-title">
                  <img src={phoneIcon} />
                  <Typography>Contact Details</Typography>
                </div>
              </Grid>
            </Grid>

            <Grid container className="input-section input-section-wrapper">
              <Grid item md={12}>
                <span className="info-text">
                  Your ticket and flight info will be sent here
                </span>
              </Grid>
              <Grid item md={12}>
                <Box className="pax-input-section">
                  <Grid container>
                    <Grid item md={3} sm={3} xs={12}>
                      {/* editDialCode == true ? 3: 1 */}
                      <div className="form-group">
                        <label className="inner-label">Country code</label>
                        <Autocomplete
                          className="outlined-ac"
                          fullWidth
                          PopperComponent={customPopper}
                          options={countries || []}
                          getOptionLabel={(o) => (o ? `${o.name}` : "")}
                          defaultValue={countries.find(
                            (ctry) =>
                              ctry.dial_code === values?.country_dial_code ??
                              "+91"
                          )}
                          getOptionSelected={(option, value) =>
                            option.dial_code == value.country_dial_code
                          }
                          onChange={(event, newValue) => {
                            console.log("newValuee ", newValue.code);
                            if (newValue != null) {
                              setFieldValue(
                                `country_dial_code`,
                                newValue.dial_code
                              );
                              setFieldValue(`countryCode`, newValue.code);
                              setEditDialCode(false);
                            }
                          }}
                          onBlur={() => setEditDialCode(false)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              variant="outlined"
                            />
                          )}
                          disablePortal={true}
                        />
                      </div>
                    </Grid>

                    <Grid item md={4} sm={4} xs={12}>
                      <div className="form-group">
                        <label className="inner-label">Mobile</label>
                        <OaTextFieldAdorned
                          fullWidth
                          name="phoneno"
                          variant="outlined"
                          value={values.phoneno}
                          onChange={(e) => {
                            setFieldValue(`phoneno`, e.target.value);
                          }}
                          adornpos="start"
                          adornico={<i className="fa fa-mobile" />}
                        />
                        <ErrorMessage
                          name={`phoneno`}
                          component="div"
                          className="error"
                        />
                      </div>
                    </Grid>

                    <Grid item md={4} sm={4} xs={12}>
                      <div className="form-group">
                        <label className="inner-label">E Mail</label>
                        <OaTextFieldAdorned
                          name="emailUser"
                          // placeholder="E Mail"
                          fullWidth
                          variant="outlined"
                          value={values.emailUser}
                          onChange={(e) => {
                            setFieldValue(`emailUser`, e.target.value);
                          }}
                          adornpos="start"
                          adornico={<i className="fa fa-envelope" />}
                        />
                        <ErrorMessage
                          name={`emailUser`}
                          component="div"
                          className="error"
                        />
                      </div>
                    </Grid>
                    <Grid item md={1}></Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* contact info entry section end */}

            {/* adult pax information entry section start */}
            {values.adultPax && values.adultPax.length > 0 && (
              <div className="input-section input-section-wrapper">
                <Grid container className="input-section-title">
                  <Grid item md={12}>
                    <div className="pax-info-title">
                      <img src={adultIcon} />
                      <Typography>Adults {values.adultPax.length}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <FieldArray
                  name="adultPax"
                  render={(arrayHelpers) =>
                    values.adultPax.map((pax, index) => (
                      <div key={index}>
                        <Passenger
                          values={values}
                          indx={index}
                          isInternationalTrip={isInternationalTrip}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          type="adult"
                          {...props}
                        />
                      </div>
                    ))
                  }
                />
              </div>
            )}

            {values.childPax && values.childPax.length > 0 && (
              <div className="input-section input-section-wrapper">
                <Grid container className="input-section-title">
                  <Grid item md={12}>
                    <div className="pax-info-title">
                      <img src={adultIcon} />
                      <Typography>Child {values.childPax.length}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <FieldArray
                  name="childPax"
                  render={(arrayHelpers) =>
                    values.childPax.map((pax, index) => (
                      <div key={index}>
                        <Passenger
                          values={values}
                          indx={index}
                          isInternationalTrip={isInternationalTrip}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          type="child"
                          {...props}
                        />
                      </div>
                    ))
                  }
                />
              </div>
            )}

            {values.infantPax && values.infantPax.length > 0 && (
              <div className="input-section input-section-wrapper">
                <Grid container className="input-section-title">
                  <Grid item md={12}>
                    <div className="pax-info-title">
                      <img src={adultIcon} />
                      <Typography>Infant {values.infantPax.length}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <FieldArray
                  name="infantPax"
                  render={(arrayHelpers) =>
                    values.infantPax.map((pax, index) => (
                      <div key={index}>
                        <Passenger
                          values={values}
                          indx={index}
                          isInternationalTrip={isInternationalTrip}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          type="infant"
                          {...props}
                        />
                      </div>
                    ))
                  }
                />
              </div>
            )}

            {/* adult pax information entry section end */}

            <div
              className="section-container no-border gst-container gst-input-section-wrapper"
              style={{ marginBottom: 15, marginTop: 15 }}
            >
              <div className="section-title">
                <Typography className="heading">GST Details</Typography>
              </div>
              <div className="accordion__section">
                <GstDetails
                  {...props}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}
                  isGSTMandate={isGSTMandate}
                  // ErrorMessage={ErrorMessage}
                />
              </div>
            </div>

            {/* <div style={{ width: "100%", wordBreak: "break-all"}}>
              <p style={{ width: "100%", wordBreak: "break-all"}}>Errors: {errors && JSON.stringify(errors)}</p>
              <p style={{ width: "100%", wordBreak: "break-all"}}>Touched: {touched && JSON.stringify(touched)}</p>
              <p style={{ width: "100%", wordBreak: "break-all"}}>Values: {values && JSON.stringify(values)}</p>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PassengerInformation;

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .outlined-ac": {
      "& .MuiOutlinedInput-root": {
        height: 44,
      },
      "& .MuiAutocomplete-input": {
        minWidth: 50,
      },
    },
    "& .pax-info-title": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      "& img": {
        height: 20,
        marginRight: 15,
      },
      "& p": {
        fontSize: 16,
        fontWeight: 500,
      },
      "& span": {
        fontSize: 14,
      },
    },
    "& .form-group": {
      padding: 10,
      [theme.breakpoints.down(450)]: {
        padding: "8px 0px !important",
      },
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
    },
    "& .action-button": {
      background: theme.palette.secondary.main,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.secondary.contrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: 22,
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
    "& .input-section-title": {
      marginTop: 20,
    },
    "& .input-section-wrapper": {
      borderRadius: "0.25rem",
      background: theme.palette.background.lightGrayDefault,
      "& .pax-info-title": {
        padding: "5px 0 0 10px",
      },
      "& .info-text": {
        color: theme.palette.background.defaultContrast,
        fontWeight: 500,
        fontSize: 12,
        padding: 10,
      },
      "& .inner-label": {
        color: theme.palette.background.defaultContrast,
        fontWeight: 500,
        [theme.breakpoints.down(768)]: {
          padding: "5px 0 0 10px",
        },
      },
      "& .pax-info-title p": {
        color: theme.palette.background.defaultContrast,
        fontWeight: 600,
      },
      "& .MuiFormControl-root, .MuiInputBase-root": {
        background: theme.palette.background.default,
        color: theme.palette.background.defaultContrast,
        borderRadius: "0.25rem",
      },
      "& .MuiInputBase-input": {
        color: theme.palette.background.defaultContrast,
      },
      "& .MuiInputAdornment-root": {
        "& i": {
          color: theme.palette.secondary.main,
        },
      },
    },
    "& .input-section": {
      marginTop: 10,
      marginBottom: 10,
      // paddingBottom: 10,
    },
    "& .select-text-field": {
      marginTop: "-13px !important",
    },
    "& .pax-info-date-picker": {
      "& .MuiIconButton-label": {
        color: "#666",
      },
    },
    "& .inner-label": {
      fontSize: "14px",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.54)",
      [theme.breakpoints.down(1100)]: {
        fontSize: 12
      },
      [theme.breakpoints.down(960)]: {
        fontSize: 14
      },
    },
    "& .MuiInputBase-root": {
      color: theme.palette.text.default,
    },
    "& .gst-container": {
      marginBottom: "2rem",
    },
  },
}));

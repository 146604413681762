import React, { useState, useEffect } from "react";
import PlanYourTrip from "components/LandingPage/DoneTrip/PlanYourTrip/PlanYourTrip";
import PlanYourTripMobile from "./PlanYourTrip/PlanYourTripMobile";

import {
  localforageGetItem,
} from "oautils/oaForageUtils";
import { AppStateContext } from "layouts/AppStateProvider";

const LandingPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [usertype, setUsertype] = useState(null);
  const [showServices, setShowServices] = useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [corporateType, setCorporateType] = useState(null);

  const getValuesFromLocalStorage = () => {
    setIsLoading(true);
    localforageGetItem("user-details", function (err, value) {
      if (value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }
        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
              ? showServices
              : true
        );
        setServiceOptions(value.serviceOption);
        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);

  const width = window.innerWidth;
  const breakpoint = 980;

  return (
    // !isLoading && (
      <>
        {width < breakpoint ? <PlanYourTripMobile /> : <PlanYourTrip />}
        {/* <TopRoutes /> */}
        {/* <BestDeal /> */}
        {/* <BudgetHotels /> */}
        {/* <ExclusiveOffers /> */}
        {/* <Explore />
        <SpecialOffers />
        <WhyDoneTrip />
        <TravelExperience />
        <Partners /> */}
      </>
    )
  // );
};

export default LandingPage;

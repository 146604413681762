import React from "react";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaLoading from "pages/components/OaLoading";
import PaymentOptions from "./PaymentOptions";
import WebApi, { POST_PAYGOAL_API_CALLBACK } from "api/ApiConstants";
import { Grid, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { localforageGetItem, localforageSetItem } from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import { submitDataToPaygoal } from "pages/module/paymentgateway/Paygoal";
import { submitDataToRazorpay } from "pages/module/paymentgateway/Razorpay";
import { submitDataToCcAvenue } from "pages/module/paymentgateway/CcAvenue";
import { EXT_REDIRECT_SERVER } from "api/ApiConstants";
import { submitDataToPayU } from "pages/module/paymentgateway/PayU";
import { submitDataToPhonepe } from "pages/module/paymentgateway/Phonepe";
import { POST_PHONEPE_API_CALLBACK } from "api/ApiConstants";
import { AppConstant } from "appConstant";

const PaymentDetails = (props) => {
  const intiatingMsg = "Initiating Payment ...";
  const payProcessMsg =
    "Hold on for a minute!<br/>We’ll get everything done for you";

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    null
  );
  const [responseMessage, setResponseMessage] = React.useState(payProcessMsg);
  const [paymentModes, setPaymentModes] = React.useState(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState(null);

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [grandTotal, setGrandTotal] = React.useState(
    props?.history?.location?.state?.totalPayable
  );
  const [trnParam, setTrnParam] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState(null);
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [paymentTab, setPaymentTab] = React.useState(0);
  const [retryPayment, setRetryPayment] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const propState = props?.history?.location?.state;
  const tripMode = propState?.request?.mode;
  const requestObj = propState?.requestObj;

  // console.log(
  //   propState.onwardResp.segment[0].arriveDetails.airportCode,
  //   "DEPARTDETAILS"
  // );

  const params = { tid: 0, encTransId: null };

  const getAge = (depDate, dob) => {
    var age = (depDate.getTime() - dob.getTime()) / 1000;
    age /= 60 * 60 * 24;
    return Math.abs(Math.round(age / 365.25));
  };

  const saveTrnParamsToLocal = (paramsToSave) => {
    return new Promise((resolve, reject) => {
      localforageSetItem("flight-transact", paramsToSave, () => {
        resolve();
      });
    });
  };

  async function showRazorPayWindow(trnResponse) {
    // setIsSaving(true);
    setRetryPayment(false);

    let filteredValue =
      selectedPaymentMode &&
      selectedPaymentMode.gateway &&
      selectedPaymentMode.gateway.filter((value) => {
        return value.method === selectedPaymentMethod;
      });

    let paymentOptions = filteredValue[0];

    let request = {
      paymentOptions: paymentOptions,
      trnResponse: trnResponse,
      selectedPaymentMethod: selectedPaymentMethod,
      theme: theme,
      trnType: "flight-booking",
      checkoutUrl: trnResponse.paymentUrl,
    };
    var response = await submitDataToRazorpay(request);
    setIsSaving(false);
    setIsError(response.isError);
    setIsSuccess(response.isSucess);
    setInfoText(response.infoText);
    setRetryPayment(response.retryPayment);
    setResponseMessage(response.responseMessage);

    if (response.isSucess) {
      submitData(response.transactionId);
      setIsSaving(false);
    } else {
      props.history.push("/flight/payment-status/" +
        trnResponse.transactionId,
        { tid: trnResponse.transactionId }
      );
    }
  }

  async function showPaygoalWindow(trnResponse) {
    const request = {
      amount: grandTotal,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: EXT_REDIRECT_SERVER + "flight/payment-status/", //http://oari.co:8080/emt/#/flight/payment-status/http://localhost:3000/psa_web/flight/payment-status/
      siteUrl: POST_PAYGOAL_API_CALLBACK,
      txnId: trnResponse.transactionId,
      paymentMode: "PayGoal",
      failUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      checkoutUrl: trnResponse.paymentUrl,
    };
    setIsSaving(false);
    submitDataToPaygoal(request);
  }

  async function showCcAvenueWindow(trnResponse) {
    const request = {
      amount: grandTotal,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=ccavenue",
      siteUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      txnId: trnResponse.transactionId,
      paymentMode: "CCAvenue",
      failUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      checkoutUrl: trnResponse.paymentUrl,
    };
    setIsSaving(false);
    submitDataToCcAvenue(request);
  }

  async function showPayUWindow(trnResponse) {
    const request = {
      amount: grandTotal,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=payu",
      siteUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      txnId: trnResponse.transactionId,
      paymentMode: "PayU",
      failUrl: `${EXT_REDIRECT_SERVER}flight/payment-status/`,
      trnType: "flight-booking",
      checkoutUrl: trnResponse.paymentUrl,
    };
    submitDataToPayU(request);
  }

  async function showPhonepeWindow(trnResponse) {
    // console.log(trnResponse);
    // return;
    saveTrnParamsToLocal(params);
    const request = {
      amount: grandTotal,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=phonepe",
      // redirectUrl:
      //   "http://oari.co:8080/travlogy_ws/api/v1/agency/payment-gateway/pg-response?paymentMode=phonepe",
      // redirectUrl: POST_PHONEPE_API_CALLBACK,
      siteUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      // siteUrl: "https://www.google.com/",
      failUrl: POST_PHONEPE_API_CALLBACK,
      // failUrl: "https://www.google.com/",
      txnId: trnResponse.transactionId,
      paymentMode: "phonepe",
      trnType: "flight-booking",
      checkoutUrl: trnResponse.paymentUrl,
      mobileNumber:
        userDetails?.userTypeAbv != "G"
          ? userDetails.mobileNumber
          : propState?.paxInfo?.phoneno,
    };
    submitDataToPhonepe(request).catch((error) => alert(error));
  }

  const displayPaymentWindow = async (trnResponse) => {
    setResponseMessage(intiatingMsg);

    if (selectedPaymentMode.method === "razorpay") {
      showRazorPayWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "paygoal") {
      showPaygoalWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "ccavenue") {
      showCcAvenueWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "payu") {
      showPayUWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "phonepe") {
      showPhonepeWindow(trnResponse);
    }
  };

  const processPayment = () => {
    if (userDetails) {
      setIsSaving(true);
      new Promise((resolve, reject) => {
        if (trnParam) {
          trnParam.paymentMode = selectedPaymentMethod; //(selectedPaymentMode.method === "paygoal") ? "paygoal_upi" : selectedPaymentMethod;
          trnParam.amountDue = grandTotal;
          WebApi.getFlightCreateTransaction(
            trnParam,
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("trn create error", error);
              reject(error);
            }
          );
        }
      }).then((response) => {
        if (
          response.status === "Success" &&
          response.transactionId != "" &&
          response.transactionId != null
        ) {
          params.tid = response.transactionId;
          params.encTransId = response.uniqueTransactionId;
          if (selectedPaymentMethod == "cashPayment") {
            submitData(response.transactionId);
          } else {
            // console.log("responseghyy", response);
            displayPaymentWindow(response);
          }
        } else {
          setInfoText("Failed to create transaction. Please retry");
          setIsError(true);
          console.log("error creating transaction", response);
          setIsSaving(false);
        }
      });
    }
  };

  const submitData = (tid) => {
    props.history.push({
      pathname: "/flight/booking/payment-success",
      state: {
        ...propState,
        Tid: tid,
      },
    });
    props.setInitFlag(true, "/flight/booking/payment-success");
  };

  const switchPaymentTabs = (event, index) => {
    // console.log("index", index)
    // console.log(paymentModes[index])
    setPaymentTab(index);
    setSelectedPaymentMode(paymentModes[index]);

    if (paymentModes[index].method == "cash") {
      setSelectedPaymentMethod("cashPayment");
    } else {
      for (let gate of paymentModes[index]?.gateway) {
        if (gate.selected) {
          setSelectedPaymentMethod(gate.method);
        }
      }
    }
  };

  const calculateConvenienceFees = (bookingRequest) => {
    // console.log("bookingRequest",bookingRequest)
    // console.log("props", props.history.location.state.convenienceFees);

    let convenienceFees =
      props.history.location.state.convenienceFees?.conveyanceFeesActual;
    if (tripMode === "ROUND") {
      convenienceFees =
        props.history.location.state.convenienceFees
          ?.conveyanceFeesActualReturn;
    }
    let convenienceFeesRet = 0;
    // let convenienceFeesRet = props.history.location.state.convenienceFees?.conveyanceFeesActualReturn;
    let onwConvenienceFees = parseFloat(
      AppConstant.flightConvenienceFeeSectorwise
        ? bookingRequest.travelers?.length *
        props.history.location.state.onwardResp?.segment.length *
        convenienceFees
        : bookingRequest.travelers?.length * convenienceFees
    );
    let retConvenienceFees = 0;
    // if (tripMode === "ROUND" && props.history.location.state.requestObj.isDomestic) {
    //   convenienceFeesRet = convenienceFees;
    //   retConvenienceFees = onwConvenienceFees;
    // }
    // else {
    //   if (tripMode === "ROUND") {
    //     convenienceFees += convenienceFees;
    //     onwConvenienceFees += onwConvenienceFees;
    //   }
    // }

    // console.log("tripMode ", tripMode);
    // console.log("props.isdomestic ", props.history.location.state.requestObj.isDomestic);

    // console.log("retConvenienceFees ", retConvenienceFees);

    // if(tripMode === "ROUND") {
    //   retConvenienceFees = parseFloat(
    //     AppConstant.flightConvenienceFeeSectorwise ?
    //     bookingRequest.travelers?.length * props.history.location.state.returnResp?.segment.length *
    //     convenienceFeesRet
    //     : bookingRequest.travelers?.length * convenienceFeesRet
    //   );
    // }

    props.setCalcFlightConv({
      flag: "docalc",
      onwConvenienceFees: onwConvenienceFees,
      retConvenienceFees: retConvenienceFees,
    });

    bookingRequest.convenienceFees = parseFloat(
      onwConvenienceFees + retConvenienceFees
    ).toFixed(2);
  };

  React.useEffect(() => {
    if (userDetails != null) {
      const bookingRequest = {
        convenienceFees: 0,
        deliveryAddress: {
          address: userDetails.address,
          cityName: userDetails.city,
          countryName:
            userDetails.userTypeAbv != "G" ? userDetails.countryName : "",
          email:
            userDetails.userTypeAbv != "G"
              ? userDetails.email
              : propState?.paxInfo?.emailUser,
          firstName:
            userDetails.userTypeAbv != "G"
              ? userDetails.firstName
              : propState?.paxInfo?.adultPax[0]?.firstname,
          landLineNumber: userDetails.landLineNumber,
          lastName:
            userDetails.userTypeAbv != "G"
              ? userDetails.lastName
              : propState?.paxInfo?.adultPax[0]?.surname,
          mobileNumber:
            userDetails.userTypeAbv != "G"
              ? userDetails.mobileNumber
              : propState?.paxInfo?.phoneno,
          postalCode: userDetails.pincode,
          stateName: userDetails.stateName,
          travelerEmail: propState?.paxInfo?.emailUser,
          travelerMobileNumber: propState?.paxInfo?.phoneno,
          travelerCountryCode: propState?.paxInfo?.countryCode,
        },
        discontCoupon: propState?.couponObj?.title,
        discontCouponAmount: propState?.couponObj?.price,
        error: "",
        gstDetails: {
          gstCompany: propState?.paxInfo?.gstCompany,
          gstCompanyAddress: propState?.paxInfo?.gstCompanyAddress,
          gstCompanyNumber: propState?.paxInfo?.gstCompanyNumber,
          gstEmail: propState?.paxInfo?.gstEmail,
          gstNumber: propState?.paxInfo?.gstNumber,
        },
        onwardFareId: propState?.oneSelectedFareId,
        paymentMode: selectedPaymentMethod,
        requestedIpAddress: "",
        returnFareId: propState?.retSelectedFareId
          ? propState?.retSelectedFareId
          : "",
        sessionId: propState?.sessionId,
        status: "",
        transactionId: "",
        uniqueTransactionId: "",
        travelers: [],
        userId: userDetails.userId,
        userType: userDetails.userTypeAbv,
      };
      // console.log(propState.paxInfo.adultPax, "ADULT PAX IN PAYMENT DETAILS");
      // console.log(
      //   propState.requestObj.sectors,
      //   "PROPS DATA IN PAYMENT DETAILS ------- "
      // );
      // console.log(
      //   propState.onwardResp.segment.departDetails,
      //   "PROPS DATA IN PAYMENT DETAILS ------- "
      // );
      propState.paxInfo.adultPax &&
        propState.paxInfo.adultPax.map((adultPax) => {
          const baggageRequest = [];
          const baggageRequestReturn = tripMode == "ROUND" ? [] : null;
          const mealRequest = [];
          const mealRequestReturn = tripMode == "ROUND" ? [] : null;
          const seatRequest = [];
          const seatRequestReturn = tripMode == "ROUND" ? [] : null;
          if(propState.isDomestic) {
            adultPax.dob = "";
          }

          adultPax.additionalOnward &&
            adultPax.additionalOnward.length > 0 &&
            adultPax.additionalOnward.map((addn, addIdx) => {
              if (addIdx == 0 && addn.baggage != null) {
                baggageRequest.push({
                  bagCode: addn.baggage.code,
                  bagDescription: addn.baggage.description,
                  bagPrice: addn.baggage.price,
                  bagSector:
                    addn.baggage.origin + "-" + addn.baggage.destination,
                  wayType: addn.baggage.wayType,
                });
              } else {
                baggageRequest.push({
                  bagCode: "No Baggage",
                  bagDescription: "No Baggage",
                  bagPrice: "0",
                  bagSector:
                    propState.onwardResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.onwardResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.meal != null) {
                mealRequest.push({
                  mealCode: addn.meal.code,
                  mealDescription: addn.meal.description,
                  mealPrice: addn.meal.price,
                  mealSector: addn.meal.origin + "-" + addn.meal.destination,
                  wayType: addn.meal.wayType,
                });
              } else {
                mealRequest.push({
                  mealCode: "No Meal",
                  mealDescription: "No Meal",
                  mealPrice: "0",
                  mealSector:
                    propState.onwardResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.onwardResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.seat != null) {
                seatRequest.push({
                  seatCode: addn.seat.code,
                  seatDescription: addn.seat.description,
                  seatPrice: addn.seat.price,
                  seatSector: addn.seat.origin + "-" + addn.seat.destination,
                  wayType: addn.seat.seatWayType,
                });
              } else {
                seatRequest.push({
                  seatCode: "No Seat",
                  seatDescription: "No Seat",
                  seatPrice: "0",
                  seatSector:
                    propState.onwardResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.onwardResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "0",
                });
              }
            });

          tripMode == "ROUND" &&
            adultPax.additionalReturn &&
            adultPax.additionalReturn.length > 0 &&
            adultPax.additionalReturn.map((addn, addIdx) => {
              if (addIdx == 0 && addn.baggage != null) {
                baggageRequestReturn.push({
                  bagCode: addn.baggage.code,
                  bagDescription: addn.baggage.description,
                  bagPrice: addn.baggage.price,
                  bagSector:
                    addn.baggage.origin + "-" + addn.baggage.destination,
                  wayType: addn.baggage.wayType,
                });
              } else {
                baggageRequestReturn.push({
                  bagCode: "No Baggage",
                  bagDescription: "No Baggage",
                  bagPrice: "0",
                  bagSector:
                    propState.returnResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.returnResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.meal != null) {
                mealRequestReturn.push({
                  mealCode: addn.meal.code,
                  mealDescription: addn.meal.description,
                  mealPrice: addn.meal.price,
                  mealSector: addn.meal.origin + "-" + addn.meal.destination,
                  wayType: addn.meal.wayType,
                });
              } else {
                mealRequestReturn.push({
                  mealCode: "No Meal",
                  mealDescription: "No Meal",
                  mealPrice: "0",
                  mealSector:
                    propState.returnResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.returnResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.seat != null) {
                seatRequestReturn.push({
                  seatCode: addn.seat.code,
                  seatDescription: addn.seat.description,
                  seatPrice: addn.seat.price,
                  seatSector: addn.seat.origin + "-" + addn.seat.destination,
                  wayType: addn.seat.seatWayType,
                });
              } else {
                seatRequestReturn.push({
                  seatCode: "No Seat",
                  seatDescription: "No Seat",
                  seatPrice: "0",
                  seatSector:
                    propState.returnResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.returnResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "0",
                });
              }
            });

          bookingRequest.travelers.push({
            age:
              getAge(
                new Date(propState?.request?.sectors[0]?.departDate),
                new Date(adultPax.dob)
              ) !== null
                ? getAge(
                  new Date(propState?.request?.sectors[0]?.departDate),
                  new Date(adultPax.dob)
                )
                : 0,
            dob: adultPax.dob,
            studentDocNumber: adultPax?.studentId,
            frequentAirlineOne: adultPax.additionalOnward[0].frequentAirline,
            frequentAirlineRound:
              tripMode == "ROUND"
                ? adultPax.additionalReturn[0].frequentAirline
                : "",
            frequentNumberOne: adultPax.additionalOnward[0].frequentFlyerNo,
            frequentNumberRound:
              tripMode == "ROUND"
                ? adultPax.additionalReturn[0].frequentFlyerNo
                : "",
            givenName: adultPax.firstname,
            nameReference: adultPax.initial,
            passengerType: "ADT",
            passportExpireDate: adultPax.passportexpire,
            passportIssueCountry: adultPax.passporticountry,
            passportIssueDate: adultPax.passportidate,
            passportNumber: adultPax.passportno,
            paxSave: adultPax.paxSave,
            surName: adultPax.surname,
            visaType: adultPax.visatype,
            baggageRequest: baggageRequest,
            baggageRequestReturn: baggageRequestReturn,
            mealRequest: mealRequest,
            mealRequestReturn: mealRequestReturn,
            seatRequest: seatRequest,
            seatRequestReturn: seatRequestReturn,
          });
        });

      if (propState.paxInfo.childPax && propState.paxInfo.childPax.length > 0) {
        propState.paxInfo.childPax.map((childPax) => {
          const baggageRequest = [];
          const baggageRequestReturn = tripMode == "ROUND" ? [] : null;
          const mealRequest = [];
          const mealRequestReturn = tripMode == "ROUND" ? [] : null;
          const seatRequest = [];
          const seatRequestReturn = tripMode == "ROUND" ? [] : null;

          childPax.additionalOnward &&
            childPax.additionalOnward.length > 0 &&
            childPax.additionalOnward.map((addn, addIdx) => {
              if (addIdx == 0 && addn.baggage != null) {
                baggageRequest.push({
                  bagCode: addn.baggage.code,
                  bagDescription: addn.baggage.description,
                  bagPrice: addn.baggage.price,
                  bagSector:
                    addn.baggage.origin + "-" + addn.baggage.destination,
                  wayType: addn.baggage.wayType,
                });
              } else {
                baggageRequest.push({
                  bagCode: "No Baggage",
                  bagDescription: "No Baggage",
                  bagPrice: "0",
                  bagSector:
                    propState.onwardResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.onwardResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.meal != null) {
                mealRequest.push({
                  mealCode: addn.meal.code,
                  mealDescription: addn.meal.description,
                  mealPrice: addn.meal.price,
                  mealSector: addn.meal.origin + "-" + addn.meal.destination,
                  wayType: addn.meal.wayType,
                });
              } else {
                mealRequest.push({
                  mealCode: "No Meal",
                  mealDescription: "No Meal",
                  mealPrice: "0",
                  mealSector:
                    propState.onwardResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.onwardResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.seat != null) {
                seatRequest.push({
                  seatCode: addn.seat.code,
                  seatDescription: addn.seat.description,
                  seatPrice: addn.seat.price,
                  seatSector: addn.seat.origin + "-" + addn.seat.destination,
                  wayType: addn.seat.seatWayType,
                });
              } else {
                seatRequest.push({
                  seatCode: "No Seat",
                  seatDescription: "No Seat",
                  seatPrice: "0",
                  seatSector:
                    propState.onwardResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.onwardResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "0",
                });
              }
            });

          tripMode == "ROUND" &&
            childPax.additionalReturn &&
            childPax.additionalReturn.length > 0 &&
            childPax.additionalReturn.map((addn, addIdx) => {
              if (addIdx == 0 && addn.baggage != null) {
                baggageRequestReturn.push({
                  bagCode: addn.baggage.code,
                  bagDescription: addn.baggage.description,
                  bagPrice: addn.baggage.price,
                  bagSector:
                    addn.baggage.origin + "-" + addn.baggage.destination,
                  wayType: addn.baggage.wayType,
                });
              } else {
                baggageRequestReturn.push({
                  bagCode: "No Baggage",
                  bagDescription: "No Baggage",
                  bagPrice: "0",
                  bagSector:
                    propState.returnResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.returnResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.meal != null) {
                mealRequestReturn.push({
                  mealCode: addn.meal.code,
                  mealDescription: addn.meal.description,
                  mealPrice: addn.meal.price,
                  mealSector: addn.meal.origin + "-" + addn.meal.destination,
                  wayType: addn.meal.wayType,
                });
              } else {
                mealRequestReturn.push({
                  mealCode: "No Meal",
                  mealDescription: "No Meal",
                  mealPrice: "0",
                  mealSector:
                    propState.returnResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.returnResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "2",
                });
              }

              if (addn.seat != null) {
                seatRequestReturn.push({
                  seatCode: addn.seat.code,
                  seatDescription: addn.seat.description,
                  seatPrice: addn.seat.price,
                  seatSector: addn.seat.origin + "-" + addn.seat.destination,
                  wayType: addn.seat.seatWayType,
                });
              } else {
                seatRequestReturn.push({
                  seatCode: "No Seat",
                  seatDescription: "No Seat",
                  seatPrice: "0",
                  seatSector:
                    propState.returnResp.segment[addIdx].departDetails
                      .airportCode +
                    "-" +
                    propState.returnResp.segment[addIdx].arriveDetails
                      .airportCode,
                  wayType: "0",
                });
              }
            });

          bookingRequest.travelers.push({
            age: getAge(
              new Date(propState?.request?.sectors[0]?.departDate),
              new Date(childPax.dob)
            ),
            dob: childPax.dob,
            frequentAirlineOne: childPax.additionalOnward[0].frequentAirline,
            frequentAirlineRound:
              tripMode == "ROUND"
                ? childPax.additionalReturn[0].frequentAirline
                : "",
            frequentNumberOne: childPax.additionalOnward[0].frequentFlyerNo,
            frequentNumberRound:
              tripMode == "ROUND"
                ? childPax.additionalReturn[0].frequentFlyerNo
                : "",
            givenName: childPax.firstname,
            nameReference: childPax.initial,
            passengerType: "CHD",
            passportExpireDate: childPax.passportexpire,
            passportIssueCountry: childPax.passporticountry,
            passportIssueDate: childPax.passportidate,
            passportNumber: childPax.passportno,
            paxSave: childPax.paxSave,
            surName: childPax.surname,
            visaType: childPax.visatype,
            baggageRequest: baggageRequest,
            baggageRequestReturn: baggageRequestReturn,
            mealRequest: mealRequest,
            mealRequestReturn: mealRequestReturn,
            seatRequest: seatRequest,
            seatRequestReturn: seatRequestReturn,
          });
        });
      }

      if (
        propState.paxInfo.infantPax &&
        propState.paxInfo.infantPax.length > 0
      ) {
        propState.paxInfo.infantPax.map((infantPax) => {
          bookingRequest.travelers.push({
            age: getAge(
              new Date(propState?.request?.sectors[0]?.departDate),
              new Date(infantPax.dob)
            ),
            dob: infantPax.dob,
            frequentAirlineOne: infantPax.additionalOnward[0].frequentAirline,
            frequentAirlineRound:
              tripMode == "ROUND"
                ? infantPax.additionalReturn[0].frequentAirline
                : "",
            frequentNumberOne: infantPax.additionalOnward[0].frequentFlyerNo,
            frequentNumberRound:
              tripMode == "ROUND"
                ? infantPax.additionalReturn[0].frequentFlyerNo
                : "",
            givenName: infantPax.firstname,
            nameReference: infantPax.initial,
            passengerType: "INF",
            passportExpireDate: infantPax.passportexpire,
            passportIssueCountry: infantPax.passporticountry,
            passportIssueDate: infantPax.passportidate,
            passportNumber: infantPax.passportno,
            paxSave: infantPax.paxSave,
            surName: infantPax.surname,
            visaType: infantPax.visatype,
            baggageRequest: [
              {
                bagCode: "No Baggage",
                bagDescription: "No Baggage",
                bagPrice: "0",
                bagSector:
                  propState.onwardResp.segment[0].departDetails.airportCode +
                  "-" +
                  propState.onwardResp.segment[0].arriveDetails.airportCode,
                wayType: "2",
              },
            ],
            baggageRequestReturn:
              tripMode == "ROUND"
                ? [
                  {
                    bagCode: "No Baggage",
                    bagDescription: "No Baggage",
                    bagPrice: "0",
                    bagSector:
                      propState.returnResp.segment[0].departDetails
                        .airportCode +
                      "-" +
                      propState.returnResp.segment[0].arriveDetails
                        .airportCode,
                    wayType: "2",
                  },
                ]
                : null,
            mealRequest: [
              {
                mealCode: "No Meal",
                mealDescription: "No Meal",
                mealPrice: "0",
                mealSector:
                  propState.onwardResp.segment[0].departDetails.airportCode +
                  "-" +
                  propState.onwardResp.segment[0].arriveDetails.airportCode,
                wayType: "2",
              },
            ],
            mealRequestReturn:
              tripMode == "ROUND"
                ? [
                  {
                    mealCode: "No Meal",
                    mealDescription: "No Meal",
                    mealPrice: "0",
                    mealSector:
                      propState.returnResp.segment[0].departDetails
                        .airportCode +
                      "-" +
                      propState.returnResp.segment[0].arriveDetails
                        .airportCode,
                    wayType: "2",
                  },
                ]
                : null,
            seatRequest: [
              {
                seatCode: "No Seat",
                seatDescription: "No Seat",
                seatPrice: "0",
                seatSector:
                  propState.onwardResp.segment[0].departDetails.airportCode +
                  "-" +
                  propState.onwardResp.segment[0].arriveDetails.airportCode,
                wayType: "0",
              },
            ],
            seatRequestReturn:
              tripMode == "ROUND"
                ? [
                  {
                    seatCode: "No Seat",
                    seatDescription: "No Seat",
                    seatPrice: "0",
                    seatSector:
                      propState.returnResp.segment[0].departDetails
                        .airportCode +
                      "-" +
                      propState.returnResp.segment[0].arriveDetails
                        .airportCode,
                    wayType: "0",
                  },
                ]
                : null,
          });
        });
      }
      calculateConvenienceFees(bookingRequest);
      setTrnParam(bookingRequest);
    }
  }, [props.couponobj, userDetails]);

  React.useEffect(() => {
    props.setInitFlag(true, "/flight/booking/payment-details");

    let paymentModeList = propState?.paymentOptions;
    // console.log("paymentModeList", paymentModeList);
    setPaymentModes(paymentModeList);
    // console.log(paymentModeList);

    for (let [payModeIdx, payMode] of paymentModeList.entries()) {
      // console.log("USEFFECT OF PAYMENT DETAILS ----------");
      if (payMode.selected) {
        setSelectedPaymentMode(payMode);
        setPaymentTab(payModeIdx);

        if (payMode.method == "cash") {
          setSelectedPaymentMethod("cashPayment");
        } else {
          for (let gate of payMode?.gateway) {
            if (gate.selected) {
              setSelectedPaymentMethod(gate.method);
            }
          }
        }
      }
    }

    localforageGetItem("user-details", (err, details) => {
      setUserDetails(details);
    });
  }, []);

  React.useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-id", function (err, value) {
        apiCall(
          WebApi.getAgentExtendedDetails,
          { agentId: value },
          (response) => {
            setIsLoading(false);
            if (response.success === true) {
              setData(response.data);
            } else {
              setIsError(true);
            }
          }
        );
      });
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (trnParam) {
      setGrandTotal(
        (
          parseFloat(props?.history?.location?.state?.totalPayable) +
          parseFloat(trnParam.convenienceFees)
        ).toFixed(2)
      );
    }
  }, [trnParam]);

  // console.log(selectedPaymentMode,"paymentModes")
  // console.log(selectedPaymentMethod,"selectedPaymentMode")
  //console.log("trnParam.convenienceFees ", trnParam);

  return isSaving ? (
    <div className={classes.loadingRoot}>
      <OaLoading />
    </div>
  ) : (
    <div className={classes.root}>
      <div className="section">
        <Grid container>
          <Grid item>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <PaymentOptions
              paymenttab={paymentTab}
              switchpaymenttabs={switchPaymentTabs}
              paymentmodes={paymentModes}
              isloading={isLoading}
              data={data}
              selectedpaymentmethod={selectedPaymentMethod}
              setselectedpaymentmethod={setSelectedPaymentMethod}
              grandtotal={grandTotal}
              setisloading={setIsLoading}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container className="button-holder">
        <button className="action-button" onClick={() => processPayment()}>
          {retryPayment == true ? "Retry payment " : "Pay"} <Rs /> {grandTotal}
        </button>
      </Grid>
    </div>
  );
};

export default PaymentDetails;

const Rs = () => {
  return <span style={{ fontFamily: "Roboto" }}>₹</span>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTab-wrapper ": {
      alignItems: "baseline",
      [theme.breakpoints.down(639)]: {
        alignItems: "center",
      },
    },
    "& .tab-container": {
      "& .MuiTabs-scrollable": {
        minHeight: 300,
        borderRadius: "8px 0 0 8px",

        "& .MuiButtonBase-root.MuiTab-root": {
          margin: "0 0 10px",
          "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: theme.palette.background.rgba_primary_active,
        },
      },
    },
    "& .tab-panel-container": {
      "& .MuiBox-root": {
        padding: "0 36px",
      },
      "& .tab-content-div": {
        width: "100%",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .section": {
      marginTop: 20,
      marginBottom: 20,
      paddingLeft: "3vw",
      paddingTop: 20,
      background: theme.palette.background.default,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0px 0px 3px 0px rgba(170,170,170,1)!important",
      [theme.breakpoints.down(960)]: {
        paddingRight: 10,
        paddingLeft: 10,
      },
    },
    "& .payment-method": {
      cursor: "pointer",
      margin: "15px 0",
      padding: "5px 20px",
    },
    "& .pay-method-title": {
      fontSize: 20,
      fontWeight: 600,
    },
    "& .payment-method-info": {
      fontSize: 12,
      fontWeight: 500,
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
      gap: 20,
      alignItems: "center",
      [theme.breakpoints.down(960)]: {
        justifyContent: "center",
      }
    },
    "& .action-button": {
      background: theme.palette.buttons.tertiary,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.buttons.tertiaryContrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: "22px !important",
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
    "& .tabbed-div-container": {
      "& .MuiTab-wrapper": {
        whiteSpace: "nowrap",
      },
    },
  },
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 400,
  },
}));
